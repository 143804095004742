import React from 'react';
import { useTitle } from '../hooks/useTitle';
import TournamentControls from '../components/TournamentControls';
import { isoStringToHumanString, isToday } from '../lib/DateUtils';

import './ScheduledTournament.css';
import { Person, PlayerSummary, TournamentResponse } from '../lib/Types';
import { faTimes, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Button from '../components/Button';
import { Ribbon } from '../components/Ribbon';
import Countdown from '../components/Countdown';
import { UserCloud } from '../components/UserPicker';
import { AppState } from '../store';
import { connect } from 'react-redux';
import { isJudge } from '../lib/UserUtils';
import { Dict } from '../reducers';
import { ThunkDispatch } from 'redux-thunk';
import { toggleParticipant as toggleParticipantAction } from '../actions';
import ParticipantsContainer from '../containers/ParticipantsContainer';

const renderLeaveButton = (
  user: Person,
  toggleParticipant: (personId: number) => void,
) => (
  <>
    <h2>You're in! Much Excite!</h2>
    <Button
      icon={faTimes}
      color="warning"
      onClick={() => toggleParticipant(user.id)}
    >
      Leave tournament
    </Button>
  </>
);

const renderJoinButton = (
  user: Person,
  toggleParticipant: (personId: number) => void,
) => (
  <>
    <h2>Join the showdown!</h2>
    <Button
      icon={faUserPlus}
      color="positive"
      onClick={() => toggleParticipant(user.id)}
    >
      Yes Please!
    </Button>
  </>
);

const renderDateOrCountdown = (isoDate: string) =>
  isToday(isoDate) ? (
    <Countdown to={isoDate} />
  ) : (
    isoStringToHumanString(isoDate)
  );

type ScheduledTournamentContentsProps = ScheduledTournamentProps & {
  participants: PlayerSummary[];
};

// FIXME: What a fucking strange thing to be obsessed with -- ternaries
const ScheduledTournamentContents = ({
  user,
  tournament,
  people,
  participants,
  toggleParticipant,
}: ScheduledTournamentContentsProps) => {
  useTitle(`${tournament.tournament.name} - DrunkenFall`);

  const participatingUsers = participants
    .map(p => people[p.person_id])
    .filter(p => !!p);
  const userIsParticipating =
    user !== undefined && participatingUsers.some(p => p.id === user.id);

  const participantCount = participatingUsers.length;
  return (
    <>
      {isJudge(user) && (
        <TournamentControls tournament={tournament} user={user} />
      )}
      <div id="scheduled-tournament-page">
        <div className="title">
          <h1>{tournament.tournament.name}</h1>
          <p>{isoStringToHumanString(tournament.tournament.scheduled)}</p>
        </div>
        <div className="participation-button-container">
          {/* eslint-disable-next-line no-nested-ternary */}
          {user === undefined
            ? null
            : userIsParticipating
            ? renderLeaveButton(user, toggleParticipant)
            : renderJoinButton(user, toggleParticipant)}
        </div>
        {isJudge(user) && participantCount > 2 && (
          <div className="participant-count">
            {participantCount} players so far.
          </div>
        )}
        <UserCloud users={participatingUsers.reverse()} />
        <div className="ribbon-container">
          <Ribbon>
            {renderDateOrCountdown(tournament.tournament.scheduled)}
          </Ribbon>
        </div>
      </div>
    </>
  );
};

interface StateProps {
  user: Person | undefined;
}

interface DispatchProps {
  toggleParticipant: (personId: number) => void;
}

interface OwnProps {
  tournament: TournamentResponse;
  people: Dict<Person>;
}

type ScheduledTournamentProps = StateProps & DispatchProps & OwnProps;

const ScheduledTournament = (props: ScheduledTournamentProps) => (
  <ParticipantsContainer tournamentId={props.tournament.tournament.id}>
    {participants => (
      <ScheduledTournamentContents participants={participants} {...props} />
    )}
  </ParticipantsContainer>
);

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.session.data.user,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: OwnProps,
): DispatchProps => ({
  toggleParticipant: (personId: number) =>
    dispatch(
      toggleParticipantAction(ownProps.tournament.tournament.id, personId),
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduledTournament);
