import React from 'react';
import Countdown from '../components/Countdown';
import {
  Color,
  Person,
  Player,
  PlayerSummary,
  TournamentResponse,
} from '../lib/Types';
import {
  getFinalMatch,
  getLeaderboard,
  getNextMatch,
  getPlayoffMatches,
} from '../lib/TournamentUtils';
import { Dict } from '../reducers';
import { getHex } from '../lib/ColorUtils';

import './PlayoffsStatus.css';
import { descending, field, indexBy, padArray } from '../lib/CollectionUtils';
import { isEnded } from '../lib/DateUtils';
import { Avatar } from './avatar/Avatar';

interface MatchProps {
  title: string;
  players: PersonOfColor[];
  hasEnded: boolean;
}

const Match = ({ title, players, hasEnded }: MatchProps) => {
  const items = padArray(players, 4, null);
  return (
    <div className="match">
      <h2>{title}</h2>
      <div className="players">
        <div className="vs">VS.</div>
        {items.map((player, i) => {
          if (player === null) {
            return (
              <div className="placeholder item" key={i}>
                <div className="nick">TBD</div>
              </div>
            );
          }
          let className = 'player item';
          if (player.rank === 1) {
            className += ' winner';
          }
          return (
            <div
              className={className}
              key={player.person.id}
              style={{ color: getHex(player.color) }}
            >
              <div className="nick">{player.person.nick}</div>
              {hasEnded ? (
                <div className="rank">
                  {player.rank === 1 ? 'WINNER' : `#${player.rank}`}
                </div>
              ) : (
                <div className="avatar">
                  <Avatar person={player.person} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface UpcomingPlayersProps {
  players: PersonOfColor[];
}

interface PersonOfColor {
  score: number;
  rank?: number;
  person: Person;
  color: Color;
  qualifyingPosition?: number;
}

const UpcomingPlayers = ({ players }: UpcomingPlayersProps) => (
  <div className="players">
    {players.map(player => {
      return (
        <div className="player" key={player.person.id}>
          <Avatar person={player.person} />
          <div className="info">
            <div className="nick" style={{ color: getHex(player.color) }}>
              {player.person.nick}
            </div>
            <div className="points">{player.score} pts</div>
          </div>
          <div className="rank">{player.rank ? `#${player.rank}` : ''}</div>
          {player.qualifyingPosition && player.qualifyingPosition !== 0 && (
            <div className="qualifying-position">
              #{player.qualifyingPosition}
            </div>
          )}
        </div>
      );
    })}
  </div>
);

type PlayoffsStatusProps = {
  tournament: TournamentResponse;
  people: Dict<Person>;
};
const byScore = descending(field('score'));

const colorify = (players: Player[], people: Dict<Person>): PersonOfColor[] =>
  players.map(ps => {
    return {
      score: ps.total_score,
      person: people[ps.person_id],
      color: ps.color,
    };
  });

const rankify = (players: PersonOfColor[]): PersonOfColor[] =>
  players.sort(byScore).map((p, index) => ({ ...p, rank: index + 1 }));

const PlayoffsStatus = ({ tournament, people }: PlayoffsStatusProps) => {
  const participantsById = indexBy(field('person_id'))(
    tournament.player_summaries,
  );
  const playoffMatches = getPlayoffMatches(tournament.matches);

  const finalMatch = getFinalMatch(tournament).getOrThrow(
    () => new Error('TouchpadCat: Could not get the final match.'),
  );
  const finalPlayers = rankify(colorify(finalMatch.players, people));

  const nextMatch = getNextMatch(tournament.matches).getOrThrow(
    () => new Error('TouchpadCat: Could not get next match.'),
  );

  const leaderboard = getLeaderboard(tournament.player_summaries)
    .map(ps => {
      const person = people[ps.person_id];
      return {
        score: ps.score,
        person,
        color: person.preferred_color,
      };
    })
    .map((p, index) => ({ ...p, rank: index + 1 }));

  const nextMatchPlayers = colorify(nextMatch.players, people).map(player => ({
    ...player,
    qualifyingPosition: participantsById[player.person.id]
      ? participantsById[player.person.id].qualifying_position
      : 0,
  }));

  const qualifyingPlayers = leaderboard.slice(0, 12);
  const notQualifyingPlayers = leaderboard.slice(12, leaderboard.length);

  return (
    <div id="touchpad-cat-page">
      <div className="next-match">
        <div className="header">
          <div className="info">
            <p>Next Up!</p>
            <p>{nextMatch.name}</p>
          </div>
          <div className="countdown">
            <Countdown to={nextMatch.scheduled} />
          </div>
        </div>
        {nextMatchPlayers.length > 0 && (
          <UpcomingPlayers players={nextMatchPlayers} />
        )}
      </div>
      <div className="final">
        <Match
          title={finalMatch.name}
          players={finalPlayers}
          hasEnded={isEnded(finalMatch)}
        />
      </div>
      <div className="playoffs">
        {playoffMatches.map(match => (
          <Match
            key={match.id}
            title={match.name}
            players={rankify(colorify(match.players, people))}
            hasEnded={isEnded(match)}
          />
        ))}
      </div>
      {qualifyingPlayers.length > 0 && (
        <div className="leaderboard">
          <h2>Leaderboard</h2>
          <UpcomingPlayers players={qualifyingPlayers} />
          <div className="divider">
            <span role="img" aria-label="down arrow">
              ⏬
            </span>
            NOT GUD
            <span role="img" aria-label="down arrow">
              ⏬
            </span>
          </div>
          <div className="not-qualifying">
            <UpcomingPlayers players={notQualifyingPlayers} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayoffsStatus;
