import React from 'react';
import './UserPicker.css';
import { Person } from '../lib/Types';
import { ClassNameBuilder } from '../lib/ClassNameUtils';
import { padArray } from '../lib/CollectionUtils';
import { Avatar } from './avatar/Avatar';

type UserListProps = {
  users: Person[];
  className?: string;
};

const Placeholder = () => <div className="placeholder">&nbsp;</div>;

export const UserCloud = ({ users, className }: UserListProps) => {
  const classNameList = new ClassNameBuilder(className).add('user-cloud');
  const slots = padArray(users, 32, null);
  return (
    <div className={classNameList.build()}>
      {slots.map((p, i) =>
        p === null ? (
          <Placeholder key={`pl${i}`} />
        ) : (
          <Avatar key={p.id} person={p} />
        ),
      )}
    </div>
  );
};
