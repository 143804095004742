import React, { ImgHTMLAttributes, useState } from 'react';
import { ClassNameBuilder } from '../lib/ClassNameUtils';

interface OwnProps {
  fallback: string;
}

type ImageProps = OwnProps & ImgHTMLAttributes<HTMLImageElement>;

const ImageWithFallback = ({
  fallback,
  src,
  className,
  onError,
  alt,
  ...rest
}: ImageProps) => {
  const [error, setError] = useState(false);
  const url = error ? fallback : src;

  const classNames = new ClassNameBuilder(className);
  if (error) {
    classNames.add('error');
  }

  return (
    <img
      onError={() => setError(true)}
      className={classNames.build()}
      src={url}
      alt={alt}
      {...rest}
    />
  );
};

export default ImageWithFallback;
