import React, { useEffect, useState } from 'react';

// TODO: this is one of many ways of data loading
function useData<T>(
  loader: () => Promise<T>,
  deps: React.DependencyList | undefined,
): [T | null, boolean, boolean] {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setError(false);
    setLoading(true);
    loader()
      .then(a => setData(a))
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(true);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [data, loading, error];
}

export default useData;
