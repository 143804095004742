import React from 'react';
import logo from '../img/oem.svg';

import styles from './StreamHeader.module.css';

type StreamHeaderProps = { children: React.ReactNode };

const StreamHeader = ({ children }: StreamHeaderProps) => (
  <div className={`header ${styles.header}`}>
    <div className={`title ${styles.title}`}>{children}</div>
    <div className={styles.branding}>
      <div className={styles.logo}>
        <img src={logo} alt="Drunkenfall Logo" />
      </div>
      <h2>DrunkenFall</h2>
    </div>
  </div>
);

export default StreamHeader;
