import React, { useEffect } from 'react';
import { connect, Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import routes from './routes';
import { ScrollToTop } from './components/ScrollToTop';
import NavigationBar from './components/NavigationBar';
import protectedPage from './components/ProtectedPage';
import store from './store';
import ErrorBoundary from './components/ErrorBoundary';
import withErrorBoundary from './components/withErrorBoundary';
import { logIn as logInAction, wsConnect, wsDisconnect } from './actions';

const getWsUrl = () => {
  const proto = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
  return `${proto}${window.location.hostname}/api/auto-updater`;
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  connectWs: () => dispatch(wsConnect(getWsUrl())),
  disconnectWs: () => dispatch(wsDisconnect()),
  logIn: () => dispatch(logInAction()),
});

const App = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ connectWs, disconnectWs, logIn }) => {
  useEffect(() => {
    connectWs();
    logIn();
    return () => disconnectWs();
  }, [connectWs, disconnectWs, logIn]);
  return (
    <BrowserRouter>
      <>
        <NavigationBar />
        <ScrollToTop />
        <div className="content">
          <ErrorBoundary>
            <Routes>
              {routes.map((route, i) => {
                const Component = withErrorBoundary(
                  route.requireLogin
                    ? protectedPage(route.minUserLevel, route.component)
                    : route.component,
                );
                return <Route key={i} {...route} element={<Component />} />;
              })}
            </Routes>
          </ErrorBoundary>
        </div>
      </>
    </BrowserRouter>
  );
});

export default () => (
  <Provider store={store}>
    <App />
  </Provider>
);
