import React from 'react';

import './Participants.css';
import TournamentByIdContainer from '../containers/TournamentByIdContainer';
import PeopleContainer from '../containers/PeopleContainer';
import ParticipantsContainer from '../containers/ParticipantsContainer';
import ParticipantSelector from '../components/ParticipantSelector';
import { useParams } from 'react-router-dom';

const TournamentParticipants = () => {
  const params = useParams();
  const tournamentId = parseInt(params.id!, 10); // TODO: is there a better way to ensure type?
  return (
    <TournamentByIdContainer id={tournamentId}>
      {tournament => (
        <PeopleContainer>
          {people => (
            <ParticipantsContainer tournamentId={tournament.tournament.id}>
              {participants => (
                <ParticipantSelector
                  people={people}
                  participants={participants}
                  tournament={tournament}
                />
              )}
            </ParticipantsContainer>
          )}
        </PeopleContainer>
      )}
    </TournamentByIdContainer>
  );
};

export default TournamentParticipants;
