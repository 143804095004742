import { field, indexBy } from './lib/CollectionUtils';
import {
  LeaderboardEntry,
  Person,
  PlayerSummary,
  Tournament,
  TournamentResponse,
} from './lib/Types';
import {
  ActionTypes,
  CLEAR_TEST_TOURNAMENTS_ERROR,
  CLEAR_TEST_TOURNAMENTS_REQUEST,
  CLEAR_TEST_TOURNAMENTS_SUCCESS,
  GET_LOGGED_IN_USER_ERROR,
  GET_LOGGED_IN_USER_REQUEST,
  GET_LOGGED_IN_USER_SUCCESS,
  LOAD_CURRENT_TOURNAMENT_ERROR,
  LOAD_CURRENT_TOURNAMENT_REQUEST,
  LOAD_CURRENT_TOURNAMENT_SUCCESS,
  LOAD_LATEST_LEAGUE_TOURNAMENT_ERROR,
  LOAD_LATEST_LEAGUE_TOURNAMENT_REQUEST,
  LOAD_LATEST_LEAGUE_TOURNAMENT_SUCCESS,
  LOAD_LEADERBOARD_ERROR,
  LOAD_LEADERBOARD_REQUEST,
  LOAD_LEADERBOARD_SUCCESS,
  LOAD_PARTICIPANTS_ERROR,
  LOAD_PARTICIPANTS_REQUEST,
  LOAD_PARTICIPANTS_SUCCESS,
  LOAD_PEOPLE_ERROR,
  LOAD_PEOPLE_REQUEST,
  LOAD_PEOPLE_SUCCESS,
  LOAD_TOURNAMENT_ERROR,
  LOAD_TOURNAMENT_REQUEST,
  LOAD_TOURNAMENT_SUCCESS,
  LOAD_TOURNAMENTS_ERROR,
  LOAD_TOURNAMENTS_SUCCESS,
  LOG_OUT_SUCCESS,
  MATCH_ENDED,
  MATCH_UPDATED,
  MATCHES_UPDATED,
  PLAYER_SUMMARIES_UPDATED,
  TOURNAMENT_UPDATED,
  UPDATE_SETTINGS_SUCCESS,
  WS_CONNECTED,
  WS_CONNECTING,
  WS_DISCONNECT,
  WS_DISCONNECTED,
} from './actions';
import { Maybe } from './lib/Maybe';

export interface DataState<T> {
  isLoading: boolean;
  error: Error | null;
  initialized: boolean;
  data: T;
}

export type TournamentsState = DataState<Tournament[]>;

const tournamentsInitialState: TournamentsState = {
  isLoading: false,
  error: null,
  initialized: false,
  data: [],
};

export const tournaments = (
  state: TournamentsState = tournamentsInitialState,
  action: ActionTypes,
) => {
  switch (action.type) {
    case LOAD_TOURNAMENT_REQUEST:
      return Object.assign({}, state, { isLoading: true, error: null });
    case LOAD_TOURNAMENTS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        data: action.tournaments,
        initialized: true,
      });
    case LOAD_TOURNAMENTS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case CLEAR_TEST_TOURNAMENTS_REQUEST:
      return Object.assign({}, state, { isLoading: true, error: null });
    case CLEAR_TEST_TOURNAMENTS_SUCCESS:
      return Object.assign({}, state, { isLoading: false, error: null });
    case CLEAR_TEST_TOURNAMENTS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case TOURNAMENT_UPDATED:
      return Object.assign({}, state, {
        data: [
          ...state.data.filter(t => t.id !== action.tournament.id),
          action.tournament,
        ],
        initialized: true,
      });
    default:
      return state;
  }
};

export type Dict<V> = { [key: number]: V };
type TournamentDetailsState = Dict<DataState<TournamentResponse>>;
const tournamentDetailsInitialState: TournamentDetailsState = {};

export const tournamentDetails = (
  state = tournamentDetailsInitialState,
  action: ActionTypes,
) => {
  switch (action.type) {
    case LOAD_TOURNAMENT_REQUEST:
      return Object.assign({}, state, {
        [action.id]: Object.assign({}, state[action.id], {
          isLoading: true,
          error: null,
        }),
      });
    case LOAD_TOURNAMENT_SUCCESS:
      return Object.assign({}, state, {
        [action.tournament.tournament.id]: Object.assign(
          {},
          state[action.tournament.tournament.id],
          {
            isLoading: false,
            initialized: true,
            data: action.tournament,
          },
        ),
      });
    case LOAD_TOURNAMENT_ERROR:
      return Object.assign({}, state, {
        [action.id]: Object.assign({}, state[action.id], {
          isLoading: false,
          error: action.error,
        }),
      });
    case PLAYER_SUMMARIES_UPDATED:
      if (state[action.tournamentId]) {
        const mergedTournament = Object.assign(
          {},
          state[action.tournamentId].data,
          { player_summaries: action.playerSummaries },
        );

        return Object.assign({}, state, {
          [action.tournamentId]: Object.assign({}, state[action.tournamentId], {
            isLoading: false,
            initialized: true,
            data: mergedTournament,
          }),
        });
      }
      return state;
    case TOURNAMENT_UPDATED:
      if (state[action.tournament.id]) {
        const mergedTournament = Object.assign(
          {},
          state[action.tournament.id].data,
          { tournament: action.tournament },
        );

        return Object.assign({}, state, {
          [action.tournament.id]: Object.assign(
            {},
            state[action.tournament.id],
            {
              isLoading: false,
              initialized: true,
              data: mergedTournament,
            },
          ),
        });
      }
      return state;
    case LOAD_CURRENT_TOURNAMENT_SUCCESS:
      return Object.assign({}, state, {
        [action.tournament.tournament.id]: Object.assign(
          {},
          state[action.tournament.tournament.id],
          {
            isLoading: false,
            initialized: true,
            data: action.tournament,
          },
        ),
      });
    case MATCH_UPDATED:
      if (state[action.tournamentId]) {
        const mergedTournament = Object.assign(
          {},
          state[action.tournamentId].data,
          {
            player_states: action.playerStates,
            matches: [
              ...state[action.tournamentId].data.matches.filter(
                match => match.id !== action.match.id,
              ),
              action.match,
            ].sort((m1, m2) => m1.id - m2.id),
          },
        );
        return Object.assign({}, state, {
          [action.tournamentId]: Object.assign({}, state[action.tournamentId], {
            data: mergedTournament,
          }),
        });
      }
      return state;
    case MATCHES_UPDATED:
      if (state[action.tournamentId]) {
        const mergedTournament = Object.assign(
          {},
          state[action.tournamentId].data,
          {
            matches: action.matches,
          },
        );
        return Object.assign({}, state, {
          [action.tournamentId]: Object.assign({}, state[action.tournamentId], {
            data: mergedTournament,
          }),
        });
      }
      return state;
    case MATCH_ENDED:
      if (state[action.tournamentId]) {
        return Object.assign({}, state, {
          [action.tournamentId]: Object.assign({}, state[action.tournamentId], {
            data: action.tournamentResponse,
          }),
        });
      }
      return state;
    default:
      return state;
  }
};

type CurrentTournamentIdState = DataState<number>;

const currentTournamentIdInitialState: CurrentTournamentIdState = {
  isLoading: false,
  error: null,
  initialized: false,
  data: 0,
};

export const currentTournamentId = (
  state = currentTournamentIdInitialState,
  action: ActionTypes,
) => {
  switch (action.type) {
    case LOAD_CURRENT_TOURNAMENT_REQUEST:
      return Object.assign({}, state, { isLoading: true, error: null });
    case LOAD_CURRENT_TOURNAMENT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        initialized: true,
        data: action.tournament.tournament.id,
      });
    case LOAD_CURRENT_TOURNAMENT_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        data: 0,
      });
    default:
      return state;
  }
};

type LatestLeagueTournamentState = DataState<Maybe<Tournament>>;
const latestLeagueTournamentInitialState: LatestLeagueTournamentState = {
  isLoading: false,
  error: null,
  initialized: false,
  data: new Maybe<Tournament>(null),
};

export const latestLeagueTournament = (
  state = latestLeagueTournamentInitialState,
  action: ActionTypes,
) => {
  switch (action.type) {
    case LOAD_LATEST_LEAGUE_TOURNAMENT_REQUEST:
      return Object.assign({}, state, { isLoading: true, error: null });
    case LOAD_LATEST_LEAGUE_TOURNAMENT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        initialized: true,
        data: action.tournament,
      });
    case LOAD_LATEST_LEAGUE_TOURNAMENT_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

type PlayersState = DataState<Dict<Person>>;
const playersInitialState: PlayersState = {
  isLoading: false,
  error: null,
  initialized: false,
  data: {},
};
export const players = (state = playersInitialState, action: ActionTypes) => {
  switch (action.type) {
    case LOAD_PEOPLE_REQUEST:
      return Object.assign({}, state, { isLoading: true, error: null });
    case LOAD_PEOPLE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        initialized: true,
        data: indexBy(field('id'))(action.people),
      });
    case LOAD_PEOPLE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case GET_LOGGED_IN_USER_SUCCESS:
      if (action.user === undefined) {
        return state;
      }
      return Object.assign({}, state, {
        data: { ...state.data, [action.user.id]: action.user },
      });
    default:
      return state;
  }
};

type ParticipantsState = DataState<Dict<PlayerSummary[]>>;

const participantsInitialState: ParticipantsState = {
  isLoading: false,
  error: null,
  initialized: false,
  data: {},
};

export const participants = (
  state = participantsInitialState,
  action: ActionTypes,
) => {
  switch (action.type) {
    case LOAD_PARTICIPANTS_REQUEST:
      return Object.assign({}, state, { isLoading: true, error: null });
    case LOAD_PARTICIPANTS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        initialized: true,
        data: Object.assign({}, state.data, {
          [action.tournamentId]: action.participants,
        }),
      });
    case LOAD_PARTICIPANTS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case PLAYER_SUMMARIES_UPDATED:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        initialized: true,
        data: Object.assign({}, state.data, {
          [action.tournamentId]: action.playerSummaries,
        }),
      });
    default:
      return state;
  }
};

type WebsocketStates =
  | 'CONNECTING'
  | 'CONNECTED'
  | 'DISCONNECTING'
  | 'DISCONNECTED';

type WebsocketState = {
  state: WebsocketStates;
  host: string | null;
};

const websocketInitialState: WebsocketState = {
  state: 'DISCONNECTED',
  host: null,
};

export const websocket = (
  state = websocketInitialState,
  action: ActionTypes,
) => {
  switch (action.type) {
    case WS_CONNECTING:
      return Object.assign({}, state, {
        state: 'CONNECTING',
        host: action.host,
      });
    case WS_CONNECTED:
      return Object.assign({}, state, {
        state: 'CONNECTED',
        host: action.host,
      });
    case WS_DISCONNECTED:
      return Object.assign({}, state, { state: 'DISCONNECTED', host: null });
    case WS_DISCONNECT:
      return Object.assign({}, state, { state: 'DISCONNECTING', host: null });
    default:
      return state;
  }
};

type Session = {
  user: Person | undefined;
};
type SessionState = DataState<Session>;

const sessionInitialState: SessionState = {
  error: null,
  initialized: false,
  isLoading: false,
  data: {
    user: undefined,
  },
};

export const session = (state = sessionInitialState, action: ActionTypes) => {
  switch (action.type) {
    case GET_LOGGED_IN_USER_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isLoading: true,
      });
    case GET_LOGGED_IN_USER_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        initialized: true,
        isLoading: false,
        data: {
          user: action.user,
        },
      });
    case GET_LOGGED_IN_USER_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        initialized: true,
        isLoading: false,
      });
    case UPDATE_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        initialized: true,
        isLoading: false,
        data: {
          user: Object.assign({}, state.data.user, action.person),
        },
      });
    case LOG_OUT_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        initialized: true,
        isLoading: false,
        data: {
          user: undefined,
        },
      });
    default:
      return state;
  }
};

type LeaderboardState = DataState<Dict<LeaderboardEntry>>;

const leaderboardInitialState: LeaderboardState = {
  error: null,
  initialized: false,
  isLoading: false,
  data: {},
};

export const leaderboard = (
  state = leaderboardInitialState,
  action: ActionTypes,
) => {
  switch (action.type) {
    case LOAD_LEADERBOARD_REQUEST:
      return Object.assign({}, state, {
        error: null,
        isLoading: true,
      });
    case LOAD_LEADERBOARD_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        isLoading: false,
        initialized: true,
        data: indexBy(field('person_id'))(action.leaderboard),
      });
    case LOAD_LEADERBOARD_ERROR:
      return Object.assign({}, state, {
        error: null,
        isLoading: true,
      });
    default:
      return state;
  }
};
