import { ArrowType, KillType } from '../Types';
import { post } from '../../api/api';

type MatchStartMessage = { type: 'match_start' };
type RoundStartMessage = { type: 'round_start' };
type RoundEndMessage = { type: 'round_end' };
type MatchEndMessage = { type: 'match_end' };

type KillMessage = {
  type: 'kill';
  data: { cause: KillType; killer: number; player: number };
};

type ArrowShotMessage = {
  type: 'arrow_shot';
  data: { arrows: ArrowType[]; player: number };
};

type ArrowsCollectedMessage = {
  type: 'arrows_collected';
  data: { arrows: ArrowType[]; player: number };
};

type DarkOrbStateMessage = {
  type: 'dark_orb_state';
  data: { state: boolean };
};

type LavaOrbStateMessage = {
  type: 'lava_orb_state';
  data: { state: true; player: number } | { state: false; player: null };
};

type SlowOrbStateMessage = {
  type: 'slow_orb_state';
  data: { state: boolean };
};

type WingsStateMessage = {
  type: 'wings_state';
  data: { state: boolean; player: number };
};

type ShieldStateMessage = {
  type: 'shield_state';
  data: { state: boolean; player: number };
};

export type GameMessage =
  | MatchStartMessage
  | RoundStartMessage
  | RoundEndMessage
  | MatchEndMessage
  | KillMessage
  | ArrowShotMessage
  | ArrowsCollectedMessage
  | DarkOrbStateMessage
  | LavaOrbStateMessage
  | SlowOrbStateMessage
  | WingsStateMessage
  | ShieldStateMessage;

export function createMatchStartMessage(): MatchStartMessage {
  return { type: 'match_start' };
}

export function createRoundStartMessage(): RoundStartMessage {
  return { type: 'round_start' };
}

export function createRoundEndMessage(): RoundEndMessage {
  return { type: 'round_end' };
}

export function createMatchEndMessage(): MatchEndMessage {
  return { type: 'match_end' };
}

export function createKillMessage(
  cause: KillType,
  killer: number,
  player: number,
): KillMessage {
  return { type: 'kill', data: { cause, killer, player } };
}

export function createArrowShotMessage(
  arrows: ArrowType[],
  player: number,
): ArrowShotMessage {
  return { type: 'arrow_shot', data: { arrows, player } };
}

export function createArrowsCollectedMessage(
  arrows: ArrowType[],
  player: number,
): ArrowsCollectedMessage {
  return { type: 'arrows_collected', data: { arrows, player } };
}

export function createDarkOrbStateMessage(state: boolean): DarkOrbStateMessage {
  return { type: 'dark_orb_state', data: { state } };
}

export function createLavaOrbActiveMessage(
  initiator: number,
): LavaOrbStateMessage {
  return { type: 'lava_orb_state', data: { state: true, player: initiator } };
}

export function createLavaOrbInactiveMessage(): LavaOrbStateMessage {
  return { type: 'lava_orb_state', data: { state: false, player: null } };
}

export function createSlowOrbStateMessage(state: boolean): SlowOrbStateMessage {
  return { type: 'slow_orb_state', data: { state } };
}

export function createWingsStateMessage(
  player: number,
  state: boolean,
): WingsStateMessage {
  return { type: 'wings_state', data: { player, state } };
}

export function createShieldStateMessage(
  player: number,
  state: boolean,
): ShieldStateMessage {
  return { type: 'shield_state', data: { player, state } };
}

export function postGameMessage(message: GameMessage) {
  return post('/tournaments/:current/message/', message);
}
