import { Person } from '../../lib/Types';
import { ClassNameBuilder } from '../../lib/ClassNameUtils';
import styles from './AvatarWithRibbon.module.css';
import { Ribbon } from '../Ribbon';
import React from 'react';
import { Avatar } from './Avatar';

type AvatarWithRibbonProps = {
  person: Pick<Person, 'nick' | 'preferred_color' | 'avatar_url'>;
  className?: string;
  ribbonText: string;
  size?: number;
};

export const AvatarWithRibbon = ({
  person,
  className,
  ribbonText,
  size,
}: AvatarWithRibbonProps) => {
  const classes = new ClassNameBuilder('avatar-with-ribbon').add(className);
  return (
    <div className={classes.build()}>
      <div className={styles.avatarWithRibbon}>
        <div className="image">
          <Avatar person={person} size={size} />
        </div>
        <Ribbon color={person.preferred_color}>{ribbonText}</Ribbon>
      </div>
    </div>
  );
};
