import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import ArcherSelector from '../components/ArcherSelector';

import { registerPlayer } from '../api/api';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

import './Register.css';
import useSubmitAndRedirect from '../hooks/useSubmitAndRedirect';
import SubmitButton from '../components/SubmitButton';
import useInputState from '../hooks/useInputState';
import Error from '../components/Error';

const empty = thing => !thing || !thing.trim();

const validate = (archer, nick, name) => {
  if (!archer || !archer.name) {
    return false;
  }
  return !(empty(nick) || empty(name));
};

export const PlayerForm = ({
  name: initialName = '',
  nick: initialNick = '',
  archer: initialArcher = '',
  alternate: initialAlternate = false,
  onSubmit,
}) => {
  const [archer, setArcher] = useState({
    name: initialArcher,
    alternate: initialAlternate,
  });

  const [nick, handleNickChange] = useInputState(initialNick);
  const [name, handleNameChange] = useInputState(initialName);

  const [handleSubmit, loading, error, success, redirect] =
    useSubmitAndRedirect(
      () => onSubmit(nick, name, archer.name, archer.alternate),
      registeredPlayer => registeredPlayer.id,
    );

  const isValid = validate(archer, nick, name);
  return (
    <div id="register-page">
      {redirect && (
        <Navigate
          to={`/archers/${redirect}${
            onSubmit === registerPlayer ? '?register=true' : ''
          }`}
        />
      )}
      <h1>Register player</h1>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label htmlFor="nick">Display name</label>
          <input
            type="text"
            className="nick"
            id="nick"
            placeholder="In-game nick"
            value={nick}
            onChange={handleNickChange}
          />
          <p className="description">Displayed in the game and on the stream</p>
        </div>
        <div className="field">
          <label htmlFor="name">IRL name</label>
          <input
            type="text"
            placeholder="IRL name"
            id="name"
            value={name}
            onChange={handleNameChange}
          />
          <p className="description">
            This is so that we can call your name when it's your turn to play
          </p>
        </div>
        <div className="field">
          <ArcherSelector
            initialArcher={initialArcher}
            initialAlternate={initialAlternate}
            onChange={setArcher}
          />
        </div>
        <Error error={error}>
          Something went terribly wrong.. :( Try again in a bit.
        </Error>
        <div className="field">
          <SubmitButton
            icon={faUserPlus}
            disabled={!isValid}
            color="positive"
            {...{ loading, success }}
          >
            Let's Go!
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

const Register = () => <PlayerForm onSubmit={registerPlayer} />;

export default Register;
