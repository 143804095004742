import { Match, Tournament } from './Types';
import moment from 'moment';

export const isEnded = (x: Tournament | Match): boolean =>
  !!(x.ended && new Date().getTime() - new Date(x.ended).getTime() > 0);

export const isNotEnded = (x: Tournament | Match): boolean => !isEnded(x);

export const isStarted = (x: Tournament | Match): boolean =>
  !!(x.started && new Date().getTime() - new Date(x.started).getTime() > 0);

export const pad = (x: number): string =>
  x.toString().length === 1 ? `0${x}` : `${x}`;

export const toTimeString = (milliseconds: number): string => {
  // return moment().add(milliseconds, 'ms').toNow();
  const s = 1000 * Math.round(milliseconds / 1000); // round to nearest second
  const d = new Date(s);
  const minutesSeconds = `${pad(d.getUTCMinutes())}:${pad(d.getUTCSeconds())}`;
  return s >= 3600000
    ? `${pad(d.getUTCHours())}:${minutesSeconds}`
    : minutesSeconds;
};

export const isoStringToHumanString = (iso: string): string => {
  return moment(iso).local().format('ddd MMMM Do HH:mm');
};

export const isoStringToTimeString = (iso: string): string => {
  return moment(iso).local().format('HH:mm');
};

export const dateToTimeStringWithTimezone = (date: Date): string => {
  return moment(date).local().format('HH:mm (Z)');
};

export const isToday = (iso: string): boolean => moment().isSame(iso, 'day');

export const nowIso = (): string => moment().format('YYYY-MM-DDT19:00');

export const nowTime = (): string => moment().format('HH:mm');

export const isoToDateInput = (iso: string): string =>
  moment(iso).format('YYYY-MM-DDTHH:mm');

export const todayAtTime = (time: string) => {
  const parsedTime = moment(time, 'HH:mm');
  return moment()
    .local()
    .set({ hour: parsedTime.get('hour'), minute: parsedTime.get('minute') })
    .toISOString();
};

export const scheduledComparator = (
  x1: Tournament | Match,
  x2: Tournament | Match,
): number => {
  if (x1.scheduled === null) {
    if (x2.scheduled === null) {
      return 0;
    }
    return -1;
  }
  if (x2.scheduled === null) {
    return 1;
  }
  const date1 = moment(x1.scheduled);
  if (date1.isSame(x2.scheduled)) {
    return 0;
  }
  if (date1.isBefore(x2.scheduled)) {
    return 1;
  }
  return -1;
};
