import Home from './routes/Home';
import About from './routes/About';
import Rules from './routes/Rules';
import Tournaments from './routes/Tournaments';
import Register from './routes/Register';
import Archer from './routes/Archer';
import Admin from './routes/Admin';
import DisableUsers from './routes/DisableUsers';
import NewTournament from './routes/NewTournament';
import CreateTournament from './routes/CreateTournament';
import Login from './routes/Login';
import TournamentParticipants from './routes/TournamentParticipants';
import Control from './routes/Control';
import Settings from './routes/Settings';
import StatusDong from './routes/StatusDong';
import TouchpadCat from './routes/TouchpadCat';
import Hud from './routes/live/Hud';
import Game from './routes/live/Game';
import Next from './routes/live/Next';
import Qualifications from './routes/live/Qualifications';
import Intermission from './routes/live/Intermission';
import EndQualifying from './routes/EndQualifying';
import TournamentById from './routes/TournamentById';
import Overview from './routes/live/Overview';
import Autocaster from './routes/live/Autocaster';
import { GameUtils } from './routes/GameUtils';
import { EditTournament } from './routes/EditTournament';
import Leaderboard from './routes/live/Leaderboard';

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/about',
    component: About,
    exact: true,
  },
  {
    path: '/rules',
    component: Rules,
    exact: true,
  },
  {
    path: '/tournaments',
    component: Tournaments,
    exact: true,
  },
  {
    path: '/tournaments/new',
    component: NewTournament,
    exact: true,
    requireLogin: true,
    minUserLevel: 100,
  },
  {
    path: '/tournaments/new/:type',
    component: CreateTournament,
    exact: true,
    requireLogin: true,
    minUserLevel: 100,
  },
  {
    path: '/tournaments/:id',
    component: TournamentById,
    exact: true,
  },
  {
    path: '/tournaments/:id/edit',
    component: EditTournament,
    exact: true,
    requireLogin: true,
    minUserLevel: 100,
  },
  {
    path: '/tournaments/:id/control',
    component: Control,
    exact: true,
    requireLogin: true,
    minUserLevel: 100,
  },
  {
    path: '/tournaments/:id/participants',
    component: TournamentParticipants,
    exact: true,
    requireLogin: true,
    minUserLevel: 100,
  },
  {
    path: '/register',
    component: Register,
    exact: true,
  },
  {
    path: '/archers/:id',
    component: Archer,
    exact: true,
  },
  {
    path: '/admin',
    component: Admin,
    exact: true,
    requireLogin: true,
    minUserLevel: 100,
  },
  {
    path: '/admin/disable',
    component: DisableUsers,
    exact: true,
    requireLogin: true,
    minUserLevel: 100,
  },
  {
    path: '/admin/game-utils',
    component: GameUtils,
    exact: true,
    requireLogin: true,
    minUserLevel: 100,
  },
  {
    path: '/live/hud',
    component: Hud,
    exact: true,
  },
  {
    path: '/live/game',
    component: Game,
    exact: true,
  },
  {
    path: '/live/qualifications',
    component: Qualifications,
    exact: true,
  },
  {
    path: '/live/next',
    component: Next,
    exact: true,
  },
  {
    path: '/live/overview',
    component: Overview,
    exact: true,
  },
  {
    path: '/live/leaderboard',
    component: Leaderboard,
    exact: true,
  },
  {
    path: '/live/intermission',
    component: Intermission,
    exact: true,
  },
  {
    path: '/live/autocaster',
    component: Autocaster,
    exact: true,
  },
  {
    path: '/settings',
    component: Settings,
    exact: true,
    requireLogin: true,
  },
  {
    path: '/status-dong',
    component: StatusDong,
    exact: true,
  },
  {
    path: '/touchpad-cat',
    component: TouchpadCat,
    exact: true,
  },
  {
    path: '/tournaments/:id/endqualifying',
    component: EndQualifying,
    exact: true,
    requireLogin: true,
    minUserLevel: 100,
  },
];

export default routes;
