import React, { useEffect } from 'react';
import Error from './Error';

// FIXME: what in the everloving fuck is up with the ternaries??
const Loading = ({ isLoading, error, children }) => {
  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [error]);
  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : error ? (
        <Error error={error}>Something went terribly wrong.. :(</Error>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Loading;
