import { isEnded, isNotEnded, isStarted } from './DateUtils';
import {
  Match,
  Person,
  Player,
  PlayerSummary,
  Tournament,
  TournamentResponse,
} from './Types';
import { Maybe } from './Maybe';
import { isRunning as isMatchRunning } from './MatchUtils';
import { Dict } from '../reducers';
import { descending, field } from './CollectionUtils';

export const isRunning = (tournament: Tournament): boolean =>
  isStarted(tournament) && !isEnded(tournament);

export const getRunningTournament = (
  tournaments: Tournament[],
): Maybe<Tournament> =>
  new Maybe<Tournament>(
    tournaments
      .filter(t => t.ended === null)
      .sort((t1, t2) => t2.id - t1.id)[0],
  );

export const isTestTournament = (tournament: Tournament): boolean =>
  !tournament.name.startsWith('DrunkenFall');

export const toLocaleDate = (date: string | number | Date): string =>
  new Date(date).toLocaleDateString();

const getWinnerOfMatch = (match: Match): Player =>
  match.players.sort((p1, p2) => p2.kills - p1.kills)[0];

export const getFinalMatch = (tournament: TournamentResponse): Maybe<Match> =>
  new Maybe<Match>(tournament.matches.find(match => match.kind === 'final'));

export const getWinnerOfTournament = (
  tournament: TournamentResponse,
): Maybe<Player> => getFinalMatch(tournament).map(getWinnerOfMatch);

export const getLosersOfTournament = (
  tournament: TournamentResponse,
  winnerPersonId: number,
): PlayerSummary[] =>
  tournament.player_summaries
    .filter(ps => ps.person_id !== winnerPersonId)
    .sort((p1, p2) => p2.score - p1.score);

export const getStatsOfPlayer = (
  tournament: TournamentResponse,
  personId: number,
): Maybe<PlayerSummary> =>
  new Maybe<PlayerSummary>(
    tournament.player_summaries.find(ps => ps.person_id === personId),
  );

export const pointComparator = (
  p1: Player | PlayerSummary,
  p2: Player | PlayerSummary,
): number => p2.kills - p2.self - (p1.kills - p1.self);

export const onlyLeagueTournaments = (
  tournaments: Tournament[],
): Tournament[] => tournaments.filter(t => t.cover);

export const getTitleFromFullName = (fullName: string): string =>
  fullName.split(':').reverse()[0].trim();

export const nullifyGoZeroDate = (date: string): string | null =>
  `${date}` === '0001-01-01T00:00:00Z' ? null : date;

export const canStart = (tournament: Tournament): boolean =>
  !isStarted(tournament);

export const getNextMatch = (matches: Match[]): Maybe<Match> =>
  new Maybe<Match>(matches.find(match => !isStarted(match)));

export const getPlayoffMatches = (matches: Match[]): Match[] =>
  matches.filter(match => match.kind === 'playoff');

export const getNextScheduledMatch = (matches: Match[]): Maybe<Match> => {
  const nextMatchIndex = matches.findIndex(match => !isStarted(match));
  if (nextMatchIndex === -1) {
    return new Maybe<Match>(null);
  }
  return new Maybe<Match>(matches[nextMatchIndex + 1]);
};

export const isBetweenMatches = (tournament: TournamentResponse): boolean => {
  if (isEnded(tournament.tournament)) {
    return false;
  }

  // pre-first-match
  if (isStarted(tournament.tournament) && !isStarted(tournament.matches[0])) {
    return true;
  }

  return !tournament.matches.some(isMatchRunning);
};

export const getCurrentMatch = (
  tournament: TournamentResponse,
): Maybe<Match> => {
  return new Maybe(tournament.matches.find(isNotEnded));
};

export const currentMatchIsOngoing = (
  tournament: TournamentResponse,
): boolean => {
  return getCurrentMatch(tournament).map(isStarted).getOrElse(false);
};

export const isQualifiers = (tournament: TournamentResponse) =>
  getCurrentMatch(tournament)
    .map(match => match.kind)
    .map(kind => kind === 'qualifying')
    .getOrElse(true);

const bySkillScore = descending(field('skill_score'));

export const getLeaderboard = (
  participants: PlayerSummary[],
): PlayerSummary[] => participants.sort(bySkillScore);

export const sanitizeTournamentDetailObject = (
  tournament: any,
): Tournament => ({
  ...tournament,
  title: getTitleFromFullName(tournament.name),
  started: nullifyGoZeroDate(tournament.started),
  ended: nullifyGoZeroDate(tournament.ended),
});
