import { PlayerSelector } from './PlayerSelector';
import Button from '../Button';
import {
  faArrows,
  faPerson,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import './PlayerSelectingButton.css';

type PlayerSelectingButtonProps = {
  label: string;
  action: (player: number) => void;
  icon?: IconDefinition;
};

export function PlayerSelectingButton({
  label,
  action,
  icon,
}: PlayerSelectingButtonProps) {
  const [player, setPlayer] = useState<number>(0);
  return (
    <div className="player-selecting-button">
      <section>
        <PlayerSelector onPlayerChange={setPlayer} />
      </section>
      <Button icon={icon ?? faPerson} onClick={() => action(player)}>
        {label}
      </Button>
    </div>
  );
}
