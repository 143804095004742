import { PlayerSelector } from './PlayerSelector';
import { QuiverBuilder } from './QuiverBuilder';
import { ArrowType } from '../../lib/Types';
import Button from '../Button';
import { faArrows } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import './ArrowMessageButton.css';

type ArrowMessageButtonProps = {
  label: string;
  action: (arrows: ArrowType[], player: number) => void;
};

export function ArrowMessageButton({ label, action }: ArrowMessageButtonProps) {
  const [player, setPlayer] = useState<number>(0);
  const [arrows, setArrows] = useState<ArrowType[]>([]);
  return (
    <div className="arrow-message-button">
      <section>
        <PlayerSelector onPlayerChange={setPlayer} />
        <QuiverBuilder onQuiverChange={setArrows} />
      </section>
      <Button icon={faArrows} onClick={() => action(arrows, player)}>
        {label}
      </Button>
    </div>
  );
}
