import React from 'react';
import './Authenticated.css';
import { AppState } from '../store';
import { logIn } from '../actions';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Person } from '../lib/Types';

interface OwnProps {
  userLevel?: number;
  ifNot?: React.ReactNode;
  children?: React.ReactNode;
}
interface StateProps {
  user: Person | undefined;
}
interface DispatchProps {
  logIn: () => void;
}

function getChildToRender(
  user: Person | undefined,
  ifNot: React.ReactNode | undefined,
  userLevel: number,
  children: React.ReactNode,
) {
  if (user === undefined) {
    return ifNot ?? null;
  }
  if (user.userlevel < userLevel) {
    return ifNot ?? null;
  }
  return children;
}

type AuthenticatedProps = OwnProps & StateProps & DispatchProps;

const Authenticated = ({
  userLevel = 0,
  children,
  ifNot,
  user,
}: AuthenticatedProps) => {
  return <>{getChildToRender(user, ifNot, userLevel, children)}</>;
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.session.data.user,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  logIn: (): void => {
    dispatch(logIn());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
