import React from 'react';
import TournamentByIdContainer from '../containers/TournamentByIdContainer';
import Tournament from './Tournament';
import { useParams } from 'react-router-dom';

const TournamentById = () => {
  const params = useParams();
  const tournamentId = parseInt(params.id!, 10); // TODO: is there a better way to ensure type?
  return (
    <TournamentByIdContainer id={tournamentId}>
      {tournament => <Tournament tournament={tournament} />}
    </TournamentByIdContainer>
  );
};

export default TournamentById;
