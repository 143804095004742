import React from 'react';
import { useTitle } from '../hooks/useTitle';
import TournamentControls from '../components/TournamentControls';
import { getTitleFromFullName, isQualifiers } from '../lib/TournamentUtils';

import { Person, TournamentResponse } from '../lib/Types';
import { AppState } from '../store';
import { connect } from 'react-redux';
import { isJudge } from '../lib/UserUtils';
import { Dict } from '../reducers';
import QualificationsStatus from '../components/QualificationsStatus';
import PlayoffsStatus from '../components/PlayoffsStatus';

interface StateProps {
  user: Person | undefined;
}

interface OwnProps {
  tournament: TournamentResponse;
  people: Dict<Person>;
}

type RunningTournamentProps = StateProps & OwnProps;

const RunningTournament = ({
  user,
  tournament,
  people,
}: RunningTournamentProps) => {
  const tournamentTitle = getTitleFromFullName(tournament.tournament.name);
  useTitle(`${tournamentTitle} - DrunkenFall`);

  const userIsJudge = isJudge(user);
  return (
    <>
      {userIsJudge && (
        <TournamentControls tournament={tournament} user={user} />
      )}
      <div id="running-tournament-page">
        {isQualifiers(tournament) ? (
          <QualificationsStatus tournament={tournament} people={people} />
        ) : (
          <PlayoffsStatus tournament={tournament} people={people} />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.session.data.user,
  };
};

export default connect(mapStateToProps)(RunningTournament);
