import React, { ReactNode, useEffect } from 'react';
import { TournamentResponse } from '../lib/Types';
import { AppState } from '../store';
import { Maybe } from '../lib/Maybe';
import { getValue } from '../lib/StateUtils';
import { ThunkDispatch } from 'redux-thunk';
import { fetchCurrentTournament } from '../actions';
import { connect } from 'react-redux';

interface StateProps {
  currentTournament: Maybe<TournamentResponse>;
}

interface DispatchProps {
  loadCurrentTournament: () => void;
}

interface OwnProps {
  children: (tournament: TournamentResponse) => ReactNode;
}

type CurrentTournamentContainerProps = StateProps & DispatchProps & OwnProps;

const CurrentTournamentContainer = ({
  currentTournament,
  loadCurrentTournament,
  children,
}: CurrentTournamentContainerProps) => {
  useEffect(() => {
    loadCurrentTournament();
  }, [loadCurrentTournament]);
  return currentTournament
    .map(tournament => <>{children(tournament)}</>)
    .getOrElse(<></>);
};

const mapStateToProps = (state: AppState): StateProps => {
  const currentTournament = getValue(state.currentTournamentId).flatMap(id =>
    getValue(state.tournamentDetails[id]),
  );

  return {
    currentTournament,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  loadCurrentTournament: () => dispatch(fetchCurrentTournament()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CurrentTournamentContainer);
