export enum ArcherType {
  Default = 0,
  Alternate = 1,
}

export enum ArrowType {
  Normal,
  Bomb,
  SuperBomb,
  Laser,
  Bramble,
  Drill,
  Bolt,
  Toy,
  Feather,
  Trigger,
  Prism,
}

export enum KillType {
  Arrow,
  Explosion,
  Brambles,
  JumpedOn,
  Lava,
  Shock,
  SpikeBall,
  FallingObject,
  Squish,
  Curse,
  Miasma,
  Enemy,
  Chalice,
}

export enum UserLevel {
  Player = 10,
  Judge = 30,
  Commentator = 50,
  Producer = 100,
}

export type Color =
  | 'green'
  | 'blue'
  | 'pink'
  | 'orange'
  | 'white'
  | 'yellow'
  | 'cyan'
  | 'purple'
  | 'red';

export type Level =
  | 'sacred'
  | 'twilight'
  | 'backfire'
  | 'flight'
  | 'mirage'
  | 'thornwood'
  | 'frostfang'
  | 'moonstone'
  | 'kingscourt'
  | 'sunken'
  | 'towerforge'
  | 'ascension'
  | 'amaranth'
  | 'dreadwood'
  | 'darkfang'
  | 'cataclysm';

export type Player = {
  id: number;
  match_id: number;
  person_id: number;
  index: number;
  nick: string;
  color: Color;
  preferred_color: Color;
  archer_type: ArcherType;
  shots: number;
  sweeps: number;
  kills: number;
  self: number;
  total_score: number;
  display_names: string[];
};

export type PlayerSummary = {
  id: number;
  person_id: number;
  shots: number;
  sweeps: number;
  kills: number;
  self: number;
  matches: number;
  score: number;
  skill_score: number;
  qualifying_position: number;
};

export type LeaderboardEntry = {
  kills: number;
  matches: number;
  person_id: number;
  self: number;
  shots: number;
  skill_score: number;
  sweeps: number;
  total_score: number;
  tournaments: number;
};

export type PlayerState = {
  id: number;
  player_id: number;
  round_id: number;
  index: number;
  arrows: ArrowType[];
  shield: boolean;
  wings: boolean;
  hat: boolean;
  invisible: boolean;
  speed: boolean;
  alive: boolean;
  lava: boolean;
  killer: number;
  up: number;
  down: number;
};

export type Person = {
  id: number;
  name: string;
  nick: string;
  preferred_color: Color;
  archer_type: ArcherType;
  avatar_url: string;
  userlevel: UserLevel;
  disabled: boolean;
  display_names: string[];
  highest_position?: { position: number; times: number };
};

export type MatchKind = 'qualifying' | 'playoff' | 'final' | 'special';

export type Match = {
  id: number;
  tournament_id: number;
  players: Player[];
  kind: MatchKind;
  index: number;
  length: number;
  pause: string;
  scheduled: string | null;
  started: string | null;
  ended: string | null;
  level: Level;
  ruleset: string;
  name: string;
  round: number;
};

export type Tournament = {
  id: number;
  name: string;
  slug: string;
  opened: string;
  scheduled: string;
  started: string;
  qualifying_end: string;
  ended: string;
  color: Color;
  cover: string;
  length: number;
  final_length: number;
  description: string;
};

export type TournamentResponse = {
  tournament: Tournament;
  matches: Match[];
  player_summaries: PlayerSummary[];
  runnerups: PlayerSummary[];
  player_states: PlayerState[];
};
