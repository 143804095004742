import { Maybe } from '../lib/Maybe';
import { createDefaultPlayerState, getKiller } from '../lib/MatchUtils';
import { getArrowImage } from '../lib/ArrowImages';
import skull from '../img/skull.png';
import React from 'react';
import { Color, Match, Person, TournamentResponse } from '../lib/Types';
import { getHex } from '../lib/ColorUtils';
import { Dict } from '../reducers';
import shieldImage from '../img/powerups/shield.png';
import { Avatar } from './avatar/Avatar';

type NickProps = { nick: string; color: Color };

const Nick = ({ nick, color }: NickProps) => (
  <span style={{ color: getHex(color) }}>{nick}</span>
);

type StatusBarProps = {
  currentMatch: Match;
  tournament: TournamentResponse;
  people: Dict<Person>;
};
const StatusBar = ({ currentMatch, tournament, people }: StatusBarProps) => (
  <div className="status-bar">
    {currentMatch.players.map(player => {
      const playerState = new Maybe(
        tournament.player_states.find(ps => ps.player_id === player.id),
      ).getOrSupply(() => createDefaultPlayerState(player.id));
      return (
        <div
          className={`player ${playerState.alive ? '' : 'dead'}`}
          key={player.id}
        >
          <div className="avatar">
            <Avatar person={people[player.person_id]} />
          </div>
          {playerState.alive ? (
            <div className="arrows">
              {playerState.shield && (
                <div className="shield">
                  <img src={shieldImage} alt="shield" />
                </div>
              )}
              {playerState.arrows.map((arrow, i) => (
                <div className="arrow" key={i}>
                  <img src={getArrowImage(arrow)} alt="" />
                </div>
              ))}
            </div>
          ) : (
            <div className="reason">
              Killed by{' '}
              <Nick
                {...getKiller(
                  playerState.index,
                  playerState.killer,
                  currentMatch.players,
                )}
              />
            </div>
          )}
          <div className="nick">
            <Nick nick={player.nick} color={player.color} />
          </div>
          <div className="points">
            {player.kills}
            <img src={skull} alt="skull" />
          </div>
        </div>
      );
    })}
  </div>
);

export default StatusBar;
