import React from 'react';
import './SubmitButton.css';
import Button from './Button';

const loadingMessages = [
  'preparing arrows',
  'pouring jalovina',
  'GITting GUD',
  'learning finnish',
  'wiring up the potato',
];

// FIXME: I swear to god, these fucking ternaries. BAD TURK!
const getRandomLoadingMessage = () =>
  loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
const SubmitButton = ({
  loading,
  error,
  success,
  children,
  disabled,
  ...rest
}) => {
  return (
    <Button type="submit" disabled={loading || disabled || success} {...rest}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading
        ? `${getRandomLoadingMessage()}...`
        : success
        ? 'Success!'
        : children}
    </Button>
  );
};

export default SubmitButton;
