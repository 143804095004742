import React from 'react';
import PastTournament from './PastTournament';
import RunningTournament from './RunningTournament';
import ScheduledTournament from './ScheduledTournament';
import { isEnded, isStarted } from '../lib/DateUtils';
import { TournamentResponse } from '../lib/Types';
import PeopleContainer from '../containers/PeopleContainer';

type TournamentProps = {
  tournament: TournamentResponse;
};

const getElementToRender = (tournament: TournamentResponse) => {
  if (isEnded(tournament.tournament)) {
    return PastTournament;
  }
  if (isStarted(tournament.tournament)) {
    return RunningTournament;
  }
  return ScheduledTournament;
};

const Tournament = ({ tournament }: TournamentProps) => {
  const ElementToRender = getElementToRender(tournament);
  return (
    <div id="tournament-page">
      <PeopleContainer>
        {people => <ElementToRender tournament={tournament} people={people} />}
      </PeopleContainer>
    </div>
  );
};

export default Tournament;
