import React from 'react';
import {
  getCurrentMatch,
  getLeaderboard,
  getNextMatch,
  getNextScheduledMatch,
} from '../lib/TournamentUtils';
import { field, indexBy } from '../lib/CollectionUtils';
import Countdown from './Countdown';
import { Color, Person, TournamentResponse } from '../lib/Types';
import { Dict } from '../reducers';
import { getHex } from '../lib/ColorUtils';

import './QualificationsStatus.css';
import { Avatar } from './avatar/Avatar';

interface UpcomingPlayersProps {
  players: PersonOfColor[];
}

interface PersonOfColor {
  score: number;
  rank?: number;
  person: Person;
  color: Color;
}

export const UpcomingPlayers = ({ players }: UpcomingPlayersProps) => (
  <div className="players">
    {players.map(player => {
      return (
        <div className="player" key={player.person.id}>
          <Avatar person={player.person} />
          <div className="info">
            <div className="nick" style={{ color: getHex(player.color) }}>
              {player.person.nick}
            </div>
            <div className="points">{player.score} pts </div>
          </div>
          <div className="rank">{player.rank ? `#${player.rank}` : ''}</div>
        </div>
      );
    })}
  </div>
);

type QualificationsStatusProps = {
  tournament: TournamentResponse;
  people: Dict<Person>;
};

const QualificationsStatus = ({
  tournament,
  people,
}: QualificationsStatusProps) => {
  // next match, or the current match if there is no next match
  const nextOrCurrentMatch = getNextMatch(tournament.matches).getOrSupply(() =>
    getCurrentMatch(tournament).getOrThrow(
      () =>
        new Error(
          'RunningTournament: Could not get the current match nor the next match.',
        ),
    ),
  );

  const leaderboard = getLeaderboard(tournament.player_summaries)
    .map(ps => {
      const person = people[ps.person_id];
      return {
        score: ps.skill_score,
        person,
        color: person.preferred_color,
      };
    })
    .map((p, index) => ({ ...p, rank: index + 1 }));

  const ranks = indexBy(field('id'))(
    leaderboard.map((person, index) => ({
      id: person.person.id,
      rank: index + 1,
    })),
  );

  const nextOrCurrentMatchPlayers = nextOrCurrentMatch.players.map(ps => {
    return {
      rank: ranks[ps.person_id] && ranks[ps.person_id].rank,
      score: ps.total_score,
      person: people[ps.person_id],
      color: ps.color,
    };
  });

  const nextScheduledPlayers = getNextScheduledMatch(tournament.matches)
    .map(match => match.players)
    .map(players =>
      players.map(ps => {
        return {
          rank: ranks[ps.person_id] && ranks[ps.person_id].rank,
          score: ps.total_score,
          person: people[ps.person_id],
          color: ps.color,
        };
      }),
    )
    .getOrElse([]);

  const qualifyingPlayers = leaderboard.slice(0, 12);
  const notQualifyingPlayers = leaderboard.slice(12, leaderboard.length);

  return (
    <div id="qualifications-status">
      <div className="next-match">
        <div className="header">
          <div className="info">
            <p>Next Up!</p>
            <p>{nextOrCurrentMatch.name}</p>
          </div>
          <div className="countdown">
            <Countdown to={nextOrCurrentMatch.scheduled} />
          </div>
        </div>
        {nextOrCurrentMatchPlayers.length > 0 && (
          <UpcomingPlayers players={nextOrCurrentMatchPlayers} />
        )}
      </div>
      {nextScheduledPlayers.length > 0 && (
        <div className="upcoming-match">
          <h2>Upcoming match</h2>
          <UpcomingPlayers players={nextScheduledPlayers} />
        </div>
      )}
      {qualifyingPlayers.length > 0 && (
        <div className="leaderboard">
          <h2>Leaderboard</h2>
          <UpcomingPlayers players={qualifyingPlayers} />
          <div className="divider">
            <span role="img" aria-label="down arrow">
              ⏬
            </span>
            NOT GUD
            <span role="img" aria-label="down arrow">
              ⏬
            </span>
          </div>
          <div className="not-qualifying">
            <UpcomingPlayers players={notQualifyingPlayers} />
          </div>
        </div>
      )}
    </div>
  );
};

export default QualificationsStatus;
