import React, { useState } from 'react';

function useInputState(
  initial: string,
): [
  string,
  (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  React.Dispatch<React.SetStateAction<string>>,
] {
  const [state, setState] = useState<string>(initial);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setState(e.target.value);
  return [state, handleChange, setState];
}

export default useInputState;
