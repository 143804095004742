import React, { useEffect } from 'react';
import './Archer.css';
import { fetchPeople, logOut as logOutAction } from '../actions';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { LeaderboardEntry, Person } from '../lib/Types';
import Loading from '../components/Loading';
import { ThunkDispatch } from 'redux-thunk';
import { IconButton } from '../components/Button';
import { faCog, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import Authenticated from '../components/Authenticated';
import { Maybe } from '../lib/Maybe';
import { useQueryParams } from '../hooks/useQueryParams';
import { useParams } from 'react-router-dom';
import { AvatarWithRibbon } from '../components/avatar/AvatarWithRibbon';

type StatsProps = {
  stats: LeaderboardEntry;
};

const Stats = ({ stats }: StatsProps) => {
  return (
    <table className="GRABABRUSHANDPUTONALITTLETABLE">
      <tbody>
        <tr>
          <td>Kills</td>
          <td>{stats.kills}</td>
        </tr>
        <tr>
          <td>Matches</td>
          <td>{stats.matches}</td>
        </tr>
        <tr>
          <td>Self kills</td>
          <td>{stats.self}</td>
        </tr>
        <tr>
          <td>Shots</td>
          <td>{stats.shots}</td>
        </tr>
        <tr>
          <td>Skill Score</td>
          <td>{stats.skill_score}</td>
        </tr>
        <tr>
          <td>Sweeps</td>
          <td>{stats.sweeps}</td>
        </tr>
        <tr>
          <td>Total Score</td>
          <td>{stats.total_score}</td>
        </tr>
        <tr>
          <td>Tournaments played</td>
          <td>{stats.tournaments}</td>
        </tr>
      </tbody>
    </table>
  );
};

interface StateProps {
  id: number;
  person: Person;
  stats: Maybe<LeaderboardEntry>;
  isLoading: boolean;
  error: Error | null;
}

interface DispatchProps {
  loadPeople: () => void;
  logOut: () => void;
}

type ArcherProps = StateProps & DispatchProps;
const Archer = ({
  id,
  person,
  stats,
  isLoading,
  error,
  loadPeople,
  logOut,
}: ArcherProps) => {
  const query = useQueryParams();
  const registered = query.get('register') === 'true';

  useEffect(() => {
    loadPeople();
  }, [loadPeople, id]);

  return (
    <Loading isLoading={isLoading} error={error}>
      <div id="archer-page">
        <Authenticated>
          <div className="button-container mobile-only">
            <IconButton onClick="/settings" icon={faCog} />
            <IconButton onClick={logOut} icon={faDoorOpen} />
          </div>
        </Authenticated>
        <AvatarWithRibbon person={person} ribbonText={person.nick} />
        {registered ? (
          <>
            <h1>Congration</h1>
            <p>You have been assigned a random avatar!</p>
          </>
        ) : (
          stats.map(s => <Stats stats={s} />).getOrElse(<></>)
        )}
      </div>
    </Loading>
  );
};

type InnerArcherProps = {
  id: number;
};

const mapStateToProps = (
  state: AppState,
  ownProps: InnerArcherProps,
): StateProps => {
  const id = ownProps.id;
  const person = state.players.data[id];
  const stats = new Maybe(state.leaderboard.data[id]);

  const dataAvailable = !!person;
  const isLoading =
    state.players.isLoading || state.leaderboard.isLoading || !dataAvailable;
  const error = state.players.error || state.leaderboard.error;
  return {
    id,
    person,
    stats,
    isLoading,
    error,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  loadPeople: () => dispatch(fetchPeople()),
  logOut: () => dispatch(logOutAction()),
});

const InnerArcher = connect(mapStateToProps, mapDispatchToProps)(Archer);

const ArcherContainer = () => {
  const params = useParams();
  const archerId = parseInt(params.id!, 10); // TODO: is there a better way to ensure type?
  return <InnerArcher id={archerId} />;
};

export default ArcherContainer;
