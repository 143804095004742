import React from 'react';
import { Color } from '../lib/Types';
import { ClassNameBuilder } from '../lib/ClassNameUtils';

import styles from './PersonCard.module.css';
import colors from './BackgroundColors.module.css';
import ImageWithFallback from './ImageWithFallback';
import placeholder from '../img/greenSlime.png';

interface PersonCardProps {
  nick: string;
  color: Color;
  avatarUrl: string;
  className?: string;
  highlight?: HighlightStatus;
  children?: React.ReactNode;
}

export type HighlightStatus = 'highlight' | 'normal' | 'dim';

interface AvatarProps {
  url: string;
  title: string;
}

// TODO: this can be replaced with avatar/Avatar (don't forget to move styles)
const Avatar = ({ url, title }: AvatarProps) => {
  return (
    <ImageWithFallback
      className={styles.avatarImage}
      src={url}
      fallback={placeholder}
      width="96"
      height="96"
      title={title}
      alt={title}
    />
  );
};

const PersonCard = ({
  nick,
  avatarUrl,
  color,
  highlight,
  className,
  children,
}: PersonCardProps) => {
  const classes = new ClassNameBuilder(styles.personCard).add(className);
  const infoClasses = new ClassNameBuilder(styles.info);

  if (highlight === 'highlight') {
    infoClasses.add(colors[color]);
  }

  if (highlight === 'dim') {
    classes.add(styles.dim);
  }

  return (
    <div className={classes.build()}>
      <div className={`${styles.avatarContainer} ${colors[color]}`}>
        <Avatar url={avatarUrl} title={nick} />
      </div>
      <div className={infoClasses.build()}>
        <div className={styles.nick}>{nick}</div>
        {children !== undefined && children}
      </div>
    </div>
  );
};

export default PersonCard;
