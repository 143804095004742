import React, { ReactNode, useEffect } from 'react';
import { LeaderboardEntry, Person } from '../lib/Types';
import { AppState } from '../store';
import { Maybe } from '../lib/Maybe';
import { getValue } from '../lib/StateUtils';
import { ThunkDispatch } from 'redux-thunk';
import { fetchPeople } from '../actions';
import { connect } from 'react-redux';
import { Dict } from '../reducers';

interface StateProps {
  people: Maybe<Dict<Person>>;
  leaderboard: Maybe<Dict<LeaderboardEntry>>;
}

interface DispatchProps {
  loadPeople: () => void;
}

interface OwnProps {
  children: (
    people: Dict<Person>,
    leaderboard: Dict<LeaderboardEntry>,
  ) => ReactNode;
}

type PeopleContainerProps = StateProps & DispatchProps & OwnProps;

const PeopleContainer = ({
  people,
  leaderboard,
  loadPeople,
  children,
}: PeopleContainerProps) => {
  useEffect(() => {
    loadPeople();
  }, [loadPeople]);
  return people
    .flatMap(p => leaderboard.map(l => <>{children(p, l)}</>))
    .getOrElse(<></>);
};

const mapStateToProps = (state: AppState): StateProps => {
  const people = getValue(state.players);
  const leaderboard = getValue(state.leaderboard);

  return {
    people,
    leaderboard,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  loadPeople: () => dispatch(fetchPeople()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleContainer);
