import { Link } from 'react-router-dom';

import toTheMoon from '../img/moon.jpg';
import group from '../img/group.jpg';
import test from '../img/test.jpg';

import './NewTournament.css';

const NewTournament = () => (
  <div id="new-tournament-page">
    <h1>New tournament</h1>
    <h2>Choose a kind</h2>
    <Link to="/tournaments/new/drunkenfall">
      <div className="description blue">
        <h3>DrunkenFall</h3>
        <h4>Business as usual</h4>
      </div>
      <img src={toTheMoon} alt="To The Moon" />
    </Link>
    <Link to="/tournaments/new/group">
      <div className="description green">
        <h3>Group</h3>
        <h4>On-the-fly tournaments for a group of people</h4>
      </div>
      <img src={group} alt="To The Unomaly" />
    </Link>
    <Link to="/">
      <div className="description red">
        <h3>Test</h3>
        <h4>Faky fake test tournaments for testy testing</h4>
      </div>
      <img src={test} alt="To The Test" />
    </Link>
  </div>
);

export default NewTournament;
