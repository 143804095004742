import { useCallback, useEffect, useState } from 'react';
import { ArrowType } from '../../lib/Types';
import button from '../Button';
import { getArrowImage } from '../../lib/ArrowImages';

import './ArrowSelector.css';

type ArrowSelectorProps = {
  onArrowTypeChange: (arrowType: ArrowType) => void;
};

// TypeScript enums list their values twice, once with their names and once more with their numerical values
const NUMBER_OF_ARROW_TYPES = Object.keys(ArrowType).length / 2;

export function ArrowSelector({ onArrowTypeChange }: ArrowSelectorProps) {
  const [arrowType, setArrowType] = useState<ArrowType>(ArrowType.Normal);
  useEffect(() => {
    onArrowTypeChange(arrowType);
  }, [onArrowTypeChange, arrowType]);
  const nextArrow = useCallback(() => {
    setArrowType(currentType => (currentType + 1) % NUMBER_OF_ARROW_TYPES);
  }, [setArrowType]);
  return (
    <button className="arrow-selector" onClick={nextArrow}>
      <img src={getArrowImage(arrowType)} alt="" />
    </button>
  );
}
