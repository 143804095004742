import React from 'react';
import { IconButton } from '../components/Button';
import {
  faBalanceScale,
  faEdit,
  faFilm,
  faFlagCheckered,
  faForward,
  faHome,
  faPlay,
  faUserPlus,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import './TournamentControls.css';
import {
  canStart,
  getNextMatch,
  isRunning,
  isTestTournament,
} from '../lib/TournamentUtils';
import { isJudge, isProducer } from '../lib/UserUtils';
import { addTestingPlayers, startTournament } from '../api/api';
import ActionButton from './ActionButton';
import { isEnded, isStarted } from '../lib/DateUtils';

const TournamentControls = ({ tournament: { tournament, matches }, user }) => {
  return (
    <div className="tournament-controls">
      <IconButton icon={faHome} onClick={`/tournaments/${tournament.id}`}>
        Back
      </IconButton>
      <IconButton
        icon={faEdit}
        onClick={`/tournaments/${tournament.id}/edit`}
        color="danger"
      >
        Edit Tournament
      </IconButton>
      {!isEnded(tournament) && (
        <IconButton
          icon={faBalanceScale}
          onClick={`/tournaments/${tournament.id}/control`}
        >
          Judge
        </IconButton>
      )}
      {canStart(tournament) && (
        <ActionButton
          icon={faPlay}
          onClick={() => startTournament(tournament.id)}
          color="positive"
        >
          Start Tournament
        </ActionButton>
      )}
      {isProducer(user) && (
        <IconButton
          icon={faUsers}
          onClick={`/tournaments/${tournament.id}/participants`}
          disabled={isEnded(tournament)}
          color={isStarted(tournament) ? 'warning' : ''}
        >
          Players
        </IconButton>
      )}
      {isProducer(user) && isEnded(tournament) && (
        <IconButton
          icon={faFilm}
          onClick={`/tournaments/${tournament.id}/credits`}
          color="positive"
        >
          Roll Credits
        </IconButton>
      )}

      {isJudge(user) && !isEnded(tournament) && (
        <IconButton
          icon={faFlagCheckered}
          onClick={`/tournaments/${tournament.id}/endqualifying`}
          color="warning"
        >
          End Qualifying
        </IconButton>
      )}
      {isProducer(user) &&
        isTestTournament(tournament) &&
        canStart(tournament) && (
          <IconButton
            icon={faUserPlus}
            color="warning"
            onClick={() => addTestingPlayers(tournament.id)}
          >
            Add Testing Players
          </IconButton>
        )}
      {isProducer(user) &&
        isTestTournament(tournament) &&
        isRunning({ tournament: tournament }) && (
          <IconButton icon={faForward} color="warning">
            {`Autoplay ${getNextMatch(matches).title}`}
          </IconButton>
        )}
    </div>
  );
};

export default TournamentControls;
