import { Person, TournamentResponse } from '../lib/Types';
import { Dict } from '../reducers';
import React, { useState } from 'react';
import { getCurrentMatch } from '../lib/TournamentUtils';
import TournamentControls from './TournamentControls';
import { getLevelTitle } from '../lib/MatchUtils';
import ActionButton from './ActionButton';
import {
  faBaby,
  faPlay,
  faPlus,
  faRadiation,
  faRecycle,
} from '@fortawesome/free-solid-svg-icons';
import { pauseUntil, startMatch } from '../api/api';
import { isoStringToTimeString } from '../lib/DateUtils';
import Countdown from './Countdown';
import Button from './Button';
import { ControlNextUpList } from './NextUpList';
import { AppState } from '../store';
import { connect } from 'react-redux';

interface StateProps {
  user: Person | undefined;
}

interface OwnProps {
  tournament: TournamentResponse;
  people: Dict<Person>;
}

type ControlProps = StateProps & OwnProps;

const Control = ({ tournament, people, user }: ControlProps) => {
  const [isDangerous, setDangerous] = useState(false);

  const nextMatch = getCurrentMatch(tournament).getOrThrow(
    () => new Error('Control: Could not get next match.'),
  );

  const schedulingChoices = [2, 3, 5, 7, 10];

  return (
    <>
      <TournamentControls tournament={tournament} user={user} />
      <div id="control-page">
        <div className="match-title">
          {nextMatch.name} - Round {nextMatch.round} @{' '}
          {getLevelTitle(nextMatch)}
        </div>
        <div className="god-button">
          <ActionButton
            icon={faPlay}
            color="positive"
            onClick={() =>
              startMatch(tournament.tournament.id, nextMatch.index)
            }
          />
        </div>
        {nextMatch.scheduled !== null ? (
          <div className="scheduled-at">
            {`Scheduled at ${isoStringToTimeString(nextMatch.scheduled)}`}
            <div>
              (<Countdown to={nextMatch.scheduled} />)
            </div>
          </div>
        ) : (
          <div className="scheduling-controls">
            <h1>Pause until ..</h1>
            <div className="buttons">
              {schedulingChoices.map(minutes => (
                <Button
                  icon={faPlus}
                  onClick={() => pauseUntil(tournament.tournament.id, minutes)}
                  key={minutes}
                >
                  {minutes} min
                </Button>
              ))}
            </div>
          </div>
        )}
        <div className="next-up">
          <ControlNextUpList
            tournament={tournament}
            nextMatch={nextMatch}
            people={people}
          />
        </div>
        <div className="danger-zone">
          {isDangerous ? (
            <Button
              icon={faBaby}
              color="positive"
              onClick={() => setDangerous(false)}
            >
              Hide Dangerous Commands
            </Button>
          ) : (
            <Button
              icon={faRadiation}
              color="danger"
              onClick={() => setDangerous(true)}
            >
              Show Dangerous Commands
            </Button>
          )}
          {isDangerous ? (
            <div className="actions">
              <Button
                icon={faRecycle}
                onClick={() => {
                  // eslint-disable-next-line no-console
                  console.error('Not implemented');
                }}
              >
                Reset {nextMatch.name}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    user: state.session.data.user,
  };
};

export default connect(mapStateToProps)(Control);
