import React from 'react';
import { LiveTournamentProps } from '../containers/LiveTournamentContainer';

import './QualificationOverview.css';
import { Color, Person } from '../lib/Types';
import { getLeaderboard } from '../lib/TournamentUtils';
import StreamHeader from './StreamHeader';
import PersonCard, { HighlightStatus } from './PersonCard';

type LeaderboardEntryProps = {
  person: Person;
  nick: string;
  color: Color;
  avatarUrl: string;
  points: number;
  rank: number;
};
const LeaderboardEntry = ({
  nick,
  color,
  avatarUrl,
  points,
  rank,
}: LeaderboardEntryProps) => {
  const highlight: HighlightStatus = rank < 13 ? 'normal' : 'dim';
  return (
    <div className="entry">
      <div className="rank">{rank}.</div>
      <PersonCard
        className="card"
        nick={nick}
        color={color}
        avatarUrl={avatarUrl}
        highlight={highlight}
      >
        <>Score: {points} pts</>
      </PersonCard>
    </div>
  );
};

const QualificationOverview = ({ tournament, people }: LiveTournamentProps) => {
  const leaderboard = getLeaderboard(tournament.player_summaries).map(ps => {
    const person = people[ps.person_id];
    return {
      person,
      ps,
    };
  });

  return (
    <div id="qualification-overview-page">
      <StreamHeader>
        <h1>Leaderboard</h1>
      </StreamHeader>
      <div className="leaderboard">
        {leaderboard.map(({ person, ps }, i) => (
          <LeaderboardEntry
            nick={person.nick}
            color={person.preferred_color}
            avatarUrl={person.avatar_url}
            points={ps.skill_score}
            person={person}
            rank={i + 1}
            key={person.id}
          />
        ))}
      </div>
    </div>
  );
};

export default QualificationOverview;
