import { ArrowType } from '../../lib/Types';
import { useCallback, useEffect, useState } from 'react';
import { ArrowSelector } from './ArrowSelector';

import './QuiverBuilder.css';

type QuiverBuilderProps = {
  onQuiverChange: (arrows: ArrowType[]) => void;
};

type ArrowSelectorInnerProps = {
  index: number;
  setArrowAtIndex: (index: number, arrow: ArrowType) => void;
};
function ArrowSelectorInner({
  index,
  setArrowAtIndex,
}: ArrowSelectorInnerProps) {
  const arrowCallback = useCallback(
    (newType: ArrowType) => {
      setArrowAtIndex(index, newType);
    },
    [index, setArrowAtIndex],
  );
  return <ArrowSelector onArrowTypeChange={arrowCallback} />;
}

export function QuiverBuilder({ onQuiverChange }: QuiverBuilderProps) {
  const [arrows, setArrows] = useState<ArrowType[]>([]);
  useEffect(() => {
    onQuiverChange(arrows);
  }, [onQuiverChange, arrows]);
  const arrowCallback = useCallback((index: number, newType: ArrowType) => {
    setArrows(currentArrows => {
      const newState = [...currentArrows];
      newState[index] = newType;
      return newState;
    });
  }, []);
  return (
    <div className="quiver-builder">
      <header>Quiver</header>
      <section>
        <div className="add-remove">
          <button
            onClick={() =>
              setArrows(currentArrows => {
                const newState = [...currentArrows];
                newState.push(0);
                return newState;
              })
            }
          >
            +
          </button>
          {arrows.length > 0 ? (
            <button
              onClick={() =>
                setArrows(currentArrows => [...currentArrows.slice(0, -1)])
              }
            >
              -
            </button>
          ) : null}
        </div>
        {arrows.map((arrow, i) => (
          <ArrowSelectorInner
            key={i}
            index={i}
            setArrowAtIndex={arrowCallback}
          />
        ))}
      </section>
    </div>
  );
}
