import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  faBalanceScale,
  faPlus,
  faUserPlus,
  faBan,
  faFire,
  faFlag,
  faForward,
  faTrophy,
  faCamera,
  faTrashAlt,
  faBullhorn,
  faRobot,
  faSpaghettiMonsterFlying,
} from '@fortawesome/free-solid-svg-icons';

import './Admin.css';
import Button from '../components/Button';
import { getRunningTournament, isTestTournament } from '../lib/TournamentUtils';
import { clearTestTournaments, fetchTournaments } from '../actions';
import Loading from '../components/Loading';
import { Tournament } from '../lib/Types';
import { AppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';

const testTournamentsExist = (tournaments: Tournament[]) =>
  tournaments.some(isTestTournament);

interface GodButtonProps {
  tournamentId: number;
}
const GodButton = ({ tournamentId }: GodButtonProps) => (
  <div className="section">
    <h1>God button</h1>
    <div className="button-list">
      <Button
        icon={faBalanceScale}
        onClick={`/tournaments/${tournamentId}/control`}
      >
        God button
      </Button>
    </div>
  </div>
);

interface StateProps {
  tournaments: Tournament[];
  isLoading: boolean;
  error: Error | null;
}

interface DispatchProps {
  loadTournaments: () => void;
  clearTests: () => void;
}

type AdminProps = StateProps & DispatchProps;

const Admin = ({
  tournaments,
  loadTournaments,
  clearTests,
  isLoading,
  error,
}: AdminProps) => {
  useEffect(() => loadTournaments(), [loadTournaments]);
  const canClearTests = testTournamentsExist(tournaments);
  const maybeRunningTournament = getRunningTournament(tournaments);

  return (
    <div id="admin-page">
      {maybeRunningTournament
        .map(runningTournament => (
          <GodButton tournamentId={runningTournament.id} />
        ))
        .getOrElse(<></>)}
      <div className="section">
        <h1>Users</h1>
        <div className="button-list">
          <Button icon={faUserPlus} color="positive" onClick="/register">
            Register users
          </Button>
          <Button icon={faBan} color="danger" onClick="/admin/disable">
            Disable users
          </Button>
        </div>
      </div>
      <div className="section">
        <h1>Tournament management</h1>
        <div className="button-list">
          <Button icon={faPlus} color="positive" onClick="/tournaments/new">
            New tournament
          </Button>
          <Button
            icon={faTrashAlt}
            color="danger"
            onClick={() => clearTests()}
            disabled={!canClearTests}
          >
            Clear tests
          </Button>
        </div>
      </div>
      <div className="section">
        <h1>Testing</h1>
        <div className="button-list">
          <Button icon={faRobot} onClick="/live/autocaster">
            Autocaster
          </Button>
          <Button icon={faFire} onClick="/live/game">
            Game screen
          </Button>
          <Button icon={faFlag} onClick="/live/qualifications">
            Qualifications game screen
          </Button>
          <Button icon={faForward} onClick="/live/next">
            Next screen
          </Button>
          <Button icon={faBullhorn} onClick="/live/intermission">
            Intermission screen
          </Button>
          <Button icon={faBullhorn} onClick="/live/overview">
            Tournament overview
          </Button>
          <Button icon={faTrophy} onClick="" disabled>
            Leaderboard
          </Button>
          <Button icon={faCamera} onClick="/live/hud">
            HUD
          </Button>
          <Button icon={faSpaghettiMonsterFlying} onClick="/admin/game-utils">
            Game State Manipulation
          </Button>
        </div>
      </div>
      <div className="section">
        <h1>Tournaments</h1>
        <div className="tournament-list">
          <Loading isLoading={isLoading} error={error}>
            {tournaments.map(tournament => (
              <Link to={`/tournaments/${tournament.id}`} key={tournament.id}>
                {tournament.cover && (
                  <img src={tournament.cover} alt={tournament.name} />
                )}
                <div>{tournament.name}</div>
              </Link>
            ))}
          </Loading>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  tournaments: state.tournaments.data,
  isLoading: state.tournaments.isLoading,
  error: state.tournaments.error,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  clearTests: () => dispatch(clearTestTournaments()),
  loadTournaments: () => dispatch(fetchTournaments()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
