import React from 'react';
import { LiveTournamentProps } from '../../containers/LiveTournamentContainer';
import { getWinnerOfTournament } from '../../lib/TournamentUtils';

import './Congration.css';
import { AvatarWithRibbon } from '../avatar/AvatarWithRibbon';

const Congration = ({ tournament, people }: LiveTournamentProps) => {
  const winner = getWinnerOfTournament(tournament)
    .map(player => people[player.person_id])
    .getOrThrow(() => new Error('Could not get the winner of the tournament.'));

  const message = winner.id === 69 ? 'YOU SUCK' : 'A WINNER IS YOU!';

  return (
    <div id="congration-page">
      <h1>Congration!</h1>
      <AvatarWithRibbon person={winner} ribbonText={winner.nick} />
      <h2>{message}</h2>
    </div>
  );
};

export default Congration;
