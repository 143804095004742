import './CreateTournament.css';
import { useParams } from 'react-router-dom';
import { getFakeTournamentName } from '../api/api';
import { nowIso } from '../lib/DateUtils';
import useData from '../hooks/useData';
import {
  TournamentEditForm,
  TournamentType,
} from '../components/TournamentEditForm';

function getType(type: string | undefined): TournamentType {
  if (type === 'drunkenfall') {
    return 'drunkenfall';
  }
  return 'group';
}

const CreateTournament = () => {
  const { type } = useParams();
  const [data, loading, error] = useData(getFakeTournamentName, []);
  return (
    <div id="create-tournament-page">
      {!loading && !error && data !== null ? (
        <TournamentEditForm
          type={getType(type)}
          name={data.name}
          slug={data.numeral}
          date={nowIso()}
          description="enter markdown description here"
          cover="/img/covers/hero.jpg"
        />
      ) : null}
    </div>
  );
};

export default CreateTournament;
