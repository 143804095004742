import { Level } from './Types';

const capitalize = (name: Level) =>
  name.charAt(0).toUpperCase() + name.slice(1);

export const getLevelName = (level: Level): string => {
  switch (level) {
    case 'twilight':
      return 'Twilight Spire';
    case 'frostfang':
      return 'Frostfang Keep';
    case 'kingscourt':
      return "King's Court";
    case 'sunken':
      return 'Sunken City';
    case 'amaranth':
      return 'The Amaranth';
    default:
      return capitalize(level);
  }
};
