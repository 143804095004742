import React, { useEffect, useRef } from 'react';
import { LiveTournamentProps } from '../containers/LiveTournamentContainer';

import './QualificationOverviewLeaderboard.css';
import { Color, Person } from '../lib/Types';
import { getLeaderboard } from '../lib/TournamentUtils';
import PersonCard from './PersonCard';
import { useSearchParams } from 'react-router-dom';

type LeaderboardEntryProps = {
  person: Person;
  nick: string;
  color: Color;
  avatarUrl: string;
  points: number;
  rank: number;
};
const LeaderboardEntry = ({
  nick,
  color,
  avatarUrl,
  points,
  rank,
}: LeaderboardEntryProps) => {
  return (
    <div className="entry">
      <div className="rank">{rank}.</div>
      <PersonCard
        className="card"
        nick={nick}
        color={color}
        avatarUrl={avatarUrl}
        highlight="normal"
      >
        <>Score: {points} pts</>
      </PersonCard>
    </div>
  );
};

const QualificationOverviewLeaderboard = ({
  tournament,
  people,
}: LiveTournamentProps) => {
  const [searchParams] = useSearchParams();
  const scroller = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const offset = parseInt(searchParams.get('offset') ?? '0', 10);
    if (scroller.current !== null) {
      scroller.current.scrollTop = offset * 83;
    }
  }, [searchParams]);
  const leaderboard = getLeaderboard(tournament.player_summaries).map(ps => {
    const person = people[ps.person_id];
    return {
      person,
      ps,
    };
  });

  return (
    <div id="qualification-overview-leaderboard-page" ref={scroller}>
      <div className="leaderboard">
        {leaderboard.map(({ person, ps }, i) => (
          <LeaderboardEntry
            nick={person.nick}
            color={person.preferred_color}
            avatarUrl={person.avatar_url}
            points={ps.skill_score}
            person={person}
            rank={i + 1}
            key={person.id}
          />
        ))}
      </div>
    </div>
  );
};

export default QualificationOverviewLeaderboard;
