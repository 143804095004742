import React from 'react';
import { ArcherType, Color, Person } from '../lib/Types';
import { PlayerForm } from './Register';
import { ThunkDispatch } from 'redux-thunk';
import { updateSettings as updateSettingsAction } from '../actions';
import { connect } from 'react-redux';

interface OwnProps {
  loggedInUser: Person;
}

interface DispatchProps {
  updateSettings: (
    nick: string,
    name: string,
    color: Color,
    alternate: false,
  ) => {};
}
type SettingsProps = OwnProps & DispatchProps;

const Settings = ({ loggedInUser, updateSettings }: SettingsProps) => {
  return (
    <PlayerForm
      nick={loggedInUser.nick}
      name={loggedInUser.name}
      alternate={loggedInUser.archer_type === ArcherType.Alternate}
      archer={loggedInUser.preferred_color}
      onSubmit={updateSettings}
    />
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  updateSettings: (nick, name, color, alternate) =>
    dispatch(updateSettingsAction(nick, name, color, alternate)),
});

export default connect(() => ({}), mapDispatchToProps)(Settings);
