import React from 'react';

import { Person, PlayerSummary, TournamentResponse } from '../lib/Types';
import { Dict } from '../reducers';
import { byName } from '../lib/UserUtils';
import TournamentControls from './TournamentControls';
import { FilterableUserList } from './FilterableUserList';
import { AppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { toggleParticipant as toggleParticipantAction } from '../actions';
import { connect } from 'react-redux';

interface StateProps {
  loggedInUser: Person | undefined;
}

interface DispatchProps {
  toggleParticipant: (tournamentId: number, id: number) => void;
}

type OwnProps = {
  people: Dict<Person>;
  participants: PlayerSummary[];
  tournament: TournamentResponse;
};

type ParticipantsProps = StateProps & DispatchProps & OwnProps;

const ParticipantSelector = ({
  people,
  participants,
  toggleParticipant,
  loggedInUser,
  tournament,
}: ParticipantsProps) => {
  const participating = participants.map(p => people[p.person_id]);
  const participantIds = participating.reduce((p, c) => {
    p[c.id] = true;
    return p;
  }, Object.create(null));
  const sittingOut = Object.values(people)
    .filter(p => !participantIds[p.id] && !p.disabled)
    .sort(byName);
  const tournamentId = tournament.tournament.id;
  return (
    <div id="participants-page">
      <TournamentControls tournament={tournament} user={loggedInUser} />
      <div className="pickers">
        <div>
          <h1>In for the showdown! |o/</h1>
          <h2>({participating.length})</h2>
          <FilterableUserList
            people={participating}
            onUserSelect={user => toggleParticipant(tournamentId, user.id)}
          />
        </div>
        <div>
          <h1>
            Booooooo{' '}
            <span role="img" aria-label="sad face">
              😧
            </span>
          </h1>
          <h2>({sittingOut.length})</h2>
          <FilterableUserList
            people={sittingOut}
            onUserSelect={user => toggleParticipant(tournamentId, user.id)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    loggedInUser: state.session.data.user,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => {
  return {
    toggleParticipant: (tournamentId: number, userId: number) =>
      dispatch(toggleParticipantAction(tournamentId, userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParticipantSelector);
