import React from 'react';

import './Control.css';
import TournamentByIdContainer from '../containers/TournamentByIdContainer';
import PeopleContainer from '../containers/PeopleContainer';
import Control from '../components/Control';
import { useParams } from 'react-router-dom';

const ControlTournament = () => {
  const params = useParams();
  const tournamentId = parseInt(params.id!, 10); // TODO: is there a better way to ensure type?
  return (
    <TournamentByIdContainer id={tournamentId}>
      {tournament => (
        <PeopleContainer>
          {people => <Control people={people} tournament={tournament} />}
        </PeopleContainer>
      )}
    </TournamentByIdContainer>
  );
};

export default ControlTournament;
