import React from 'react';
import { LiveTournamentProps } from '../containers/LiveTournamentContainer';

import './PlayoffOverview.css';
import { Color, Match, Person, Player } from '../lib/Types';
import { Dict } from '../reducers';
import {
  getCurrentMatch,
  getFinalMatch,
  getPlayoffMatches,
} from '../lib/TournamentUtils';
import { descending, field, padArray } from '../lib/CollectionUtils';
import { ClassNameBuilder } from '../lib/ClassNameUtils';
import { isEnded } from '../lib/DateUtils';
import StreamHeader from './StreamHeader';
import PersonCard, { HighlightStatus } from './PersonCard';

const descendingByKills = descending(field('kills'));

const Placeholder = () => {
  return (
    <div className="entry placeholder dim">
      <div className="rank">&nbsp;</div>
      <div className="avatar">?</div>
      <div className="name">[TBD]</div>
    </div>
  );
};

type PlayerProps = {
  person: Person;
  color: Color;
  rank?: number;
  totalScore?: number;
};

const PlayerEntry = ({ person, rank, color }: PlayerProps) => {
  const classNames = new ClassNameBuilder('entry');
  let highlight: HighlightStatus = 'normal';
  if (rank !== undefined) {
    if (rank > 2) {
      highlight = 'dim';
    }
    if (rank === 1) {
      highlight = 'highlight';
    }
  }
  return (
    <div className={classNames.build()}>
      <div className="rank">{rank ? `${rank}.` : ''}</div>
      <PersonCard
        className="card"
        nick={person.nick}
        color={color}
        avatarUrl={person.avatar_url}
        highlight={highlight}
      />
    </div>
  );
};

const getRanks = (players: Player[]): Dict<number> => {
  const accumulator: { [index: string]: number } = {};
  return players.sort(descendingByKills).reduce((p, c, i) => {
    p[c.person_id] = i + 1;
    return p;
  }, accumulator);
};

type MatchProps = {
  match: Match;
  people: Dict<Person>;
  active: boolean;
};
const PlayoffMatch = ({ match, people, active }: MatchProps) => {
  const players = padArray(match.players, 4, null);
  const ranks = isEnded(match) ? getRanks(match.players) : [];
  const classes = new ClassNameBuilder('match');
  if (active) {
    classes.add('highlight');
  }
  return (
    <div className={classes.build()}>
      <div className="name">{match.name}</div>
      <div className="players">
        {players.map((player, i) =>
          player === null ? (
            <Placeholder key={i} />
          ) : (
            <PlayerEntry
              person={people[player.person_id]}
              rank={ranks[player.person_id]}
              key={player.person_id}
              color={player.color || people[player.person_id].preferred_color}
            />
          ),
        )}
      </div>
    </div>
  );
};

const FinalPlaceholder = () => {
  return (
    <div className="entry placeholder dim">
      <div className="avatar">?</div>
      <div className="name">[TBD]</div>
    </div>
  );
};
const FinalPlayerEntry = ({ person, rank, color, totalScore }: PlayerProps) => {
  const classNames = new ClassNameBuilder('entry');
  if (rank !== undefined) {
    if (rank > 2) {
      classNames.add('dim');
    }
    if (rank === 1) {
      classNames.add(person.preferred_color);
    }
  }

  const renderTotalScore = totalScore !== undefined && totalScore !== 0;

  return (
    <div className={classNames.build()}>
      <PersonCard
        className="card"
        nick={person.nick}
        color={color}
        avatarUrl={person.avatar_url}
      >
        {renderTotalScore ? (
          <span className="score">Total Score: {totalScore} pts</span>
        ) : undefined}
      </PersonCard>
    </div>
  );
};

const FinalMatch = ({ match, people, active }: MatchProps) => {
  const players = padArray(match.players, 4, null);
  const ranks = isEnded(match) ? getRanks(match.players) : [];
  const classes = new ClassNameBuilder('match').add('final');
  if (active) {
    classes.add('highlight');
  }
  return (
    <div className={classes.build()}>
      <div className="name">{match.name.toLocaleUpperCase()}</div>
      <div className="players">
        {players.map((player, i) =>
          player === null ? (
            <FinalPlaceholder key={i} />
          ) : (
            <FinalPlayerEntry
              person={people[player.person_id]}
              rank={ranks[player.person_id]}
              key={player.person_id}
              totalScore={player.total_score}
              color={player.color || people[player.person_id].preferred_color}
            />
          ),
        )}
      </div>
    </div>
  );
};

const Contender = ({ person, rank }: PlayerProps) => {
  const classNames = new ClassNameBuilder('entry');
  let highlight: HighlightStatus = 'normal';
  if (rank !== undefined) {
    if (rank > 1) {
      highlight = 'dim';
    }
    if (rank === 1) {
      highlight = 'highlight';
    }
  }
  return (
    <div className={classNames.build()}>
      <div className="rank">{rank ? `${rank}.` : ''}</div>
      <PersonCard
        className="card"
        nick={person.nick}
        color={person.preferred_color}
        avatarUrl={person.avatar_url}
        highlight={highlight}
      />
    </div>
  );
};

const Contenders = ({ people }: { people: (Person | null)[] }) => (
  <div className="contenders">
    <div className="name">
      <span className="up-arrow">&#9650;</span> Contenders for last spot
    </div>
    <div className="players">
      {people.map((person, index) =>
        person === null ? (
          <Placeholder key={index} />
        ) : (
          <Contender
            person={person}
            rank={index + 1}
            key={person.id}
            color={person.preferred_color}
          />
        ),
      )}
    </div>
  </div>
);

const PlayoffOverview = ({ tournament, people }: LiveTournamentProps) => {
  const allPlayoffMatches = getPlayoffMatches(tournament.matches);
  const playoffMatches = allPlayoffMatches.slice(0, 3);
  const lastChanceMatch = allPlayoffMatches[allPlayoffMatches.length - 1];
  const finalMatch = getFinalMatch(tournament).getOrThrow(
    () => new Error('Could not get the final match.'),
  );

  const currentMatchId = getCurrentMatch(tournament)
    .map(match => match.id)
    .getOrElse(0);

  const thirdPlacePlayers = playoffMatches
    .filter(isEnded)
    .map(match => match.players.sort(descendingByKills))
    .map(playersByTotalScore => playersByTotalScore[2])
    .sort(descendingByKills)
    .map(player => people[player.person_id]);

  const contenders = padArray(thirdPlacePlayers, 3, null);

  return (
    <div id="playoff-overview-page">
      <StreamHeader>
        <h1>Brackets</h1>
      </StreamHeader>
      <div className="brackets">
        <div className="playoffs">
          {playoffMatches.map(match => (
            <PlayoffMatch
              match={match}
              people={people}
              key={match.id}
              active={currentMatchId === match.id}
            />
          ))}
        </div>
        <div className="last-chance">
          <PlayoffMatch
            match={lastChanceMatch}
            people={people}
            active={currentMatchId === lastChanceMatch.id}
          />
          <Contenders people={contenders} />
        </div>
        <div className="final">
          <FinalMatch
            match={finalMatch}
            people={people}
            active={currentMatchId === finalMatch.id}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayoffOverview;
