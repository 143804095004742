import React from 'react';
import { TournamentResponse } from '../../lib/Types';
import {
  currentMatchIsOngoing,
  getCurrentMatch,
  isBetweenMatches,
} from '../../lib/TournamentUtils';
import { isEnded, isStarted } from '../../lib/DateUtils';
import { OngoingMatch } from '../../components/hud/OngoingMatch';
import { BetweenMatches } from '../../components/hud/BetweenMatches';
import { PreTournament } from '../../components/hud/PreTournament';

import './Hud.css';
import LiveTournamentContainer, {
  LiveTournamentProps,
} from '../../containers/LiveTournamentContainer';
import Congration from '../../components/hud/Congration';
import ParticipantsContainer from '../../containers/ParticipantsContainer';

function exhaustive(...args: never): never {
  throw new Error('finally, after all these years');
}

type HudState =
  | 'pre-tournament'
  | 'between-matches'
  | 'ongoing-match'
  | 'post-tournament';

const getHudState = (tournament: TournamentResponse): HudState => {
  if (!isStarted(tournament.tournament)) {
    return 'pre-tournament';
  }
  if (isBetweenMatches(tournament)) {
    return 'between-matches';
  }
  if (!isEnded(tournament.tournament) && currentMatchIsOngoing(tournament)) {
    return 'ongoing-match';
  }
  return 'post-tournament';
};

const Contents = (props: LiveTournamentProps) => {
  const hudState = getHudState(props.tournament);
  switch (hudState) {
    case 'pre-tournament':
      return (
        <ParticipantsContainer tournamentId={props.tournament.tournament.id}>
          {participants => (
            <PreTournament participants={participants} {...props} />
          )}
        </ParticipantsContainer>
      );
    case 'between-matches':
      return <BetweenMatches {...props} />;
    case 'ongoing-match':
      return (
        <OngoingMatch
          match={getCurrentMatch(props.tournament).getOrThrow(
            () => new Error(`Could not get the current match`),
          )}
          playerStates={props.tournament.player_states}
          people={props.people}
        />
      );
    case 'post-tournament':
      return <Congration {...props} />;
    default:
      exhaustive(hudState);
  }
  return null;
};

const Hud = () => {
  return (
    <div id="hud">
      <LiveTournamentContainer>{Contents}</LiveTournamentContainer>
    </div>
  );
};

export default Hud;
