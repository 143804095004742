import React from 'react';
import { Match, Person, TournamentResponse } from '../lib/Types';
import { Dict } from '../reducers';
import logo from '../img/oem.svg';

import { getCurrentMatch, getTitleFromFullName } from '../lib/TournamentUtils';
import { getLevelTitle, getMatchLength } from '../lib/MatchUtils';
import StatusBar from '../components/StatusBar';

type PlayoffsStreamProps = {
  tournament: TournamentResponse;
  people: Dict<Person>;
};

function MatchStatus(props: {
  currentMatch: Match;
  tournament: TournamentResponse;
  people: Dict<Person>;
}) {
  const matchLength = getMatchLength(props.currentMatch);
  return (
    <>
      <div className="match-info">
        <div className="title">
          {props.currentMatch.name} - Round {props.currentMatch.round}
        </div>
        <div className="level-and-length">
          {getLevelTitle(props.currentMatch)} - Match to {matchLength} points
        </div>
      </div>
      <StatusBar
        currentMatch={props.currentMatch}
        tournament={props.tournament}
        people={props.people}
      />
    </>
  );
}

const PlayoffsStream = ({ tournament, people }: PlayoffsStreamProps) => {
  const tournamentTitle = getTitleFromFullName(tournament.tournament.name);
  const currentMatch = getCurrentMatch(tournament);
  return (
    <div id="game">
      <div className="branding">
        <div className="logo">
          <img src={logo} alt="Drunkenfall Logo" />
        </div>
        <div className="tournament">
          <h1>Drunkenfall</h1>
          <h2>{tournamentTitle}</h2>
        </div>
      </div>
      <div className="caster-info">
        <div className="caster">Loud Person</div>
        <div className="caster">Crazy Person</div>
      </div>
      {currentMatch.hasValue() ? (
        <MatchStatus
          currentMatch={currentMatch.getOrThrow(
            () => new Error('Could not get the current match'),
          )}
          tournament={tournament}
          people={people}
        />
      ) : null}
    </div>
  );
};

export default PlayoffsStream;
