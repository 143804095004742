import React from 'react';
import './Game.css';
import CurrentTournamentContainer from '../../containers/CurrentTournamentContainer';
import PeopleContainer from '../../containers/PeopleContainer';
import PlayoffsStream from '../../components/PlayoffsStream';

const Game = () => (
  <CurrentTournamentContainer>
    {tournament => (
      <PeopleContainer>
        {people => <PlayoffsStream tournament={tournament} people={people} />}
      </PeopleContainer>
    )}
  </CurrentTournamentContainer>
);

export default Game;
