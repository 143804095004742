import React, { useEffect } from 'react';
import { AppState } from '../store';
import { connect } from 'react-redux';

import './Video.css';
import { ThunkDispatch } from 'redux-thunk';
import {
  fetchCurrentTournament,
  fetchParticipants,
  fetchPeople,
} from '../actions';
import { getValue, isInitialized } from '../lib/StateUtils';
import { isRunning } from '../lib/TournamentUtils';

interface StateProps {
  loading: boolean;
  live: boolean;
}

interface DispatchProps {
  loadCurrentTournament: () => void;
}

type VideoProps = StateProps & DispatchProps;

const YouTubeEmbed = () => (
  <>
    <h2>What is DrunkenFall?</h2>
    <iframe
      title="DrunkenFall 2019 - Teaser Trailer"
      src="https://www.youtube-nocookie.com/embed/8YqjoGHPQ9I"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </>
);

const TwitchEmbed = () => (
  <>
    <h2>Watch DrunkenFall on Twitch!</h2>
    <iframe
      title="DrunkenFall Live on Twitch"
      src="https://player.twitch.tv/?channel=drunkenfallofficial"
      frameBorder="0"
      allowFullScreen
      scrolling="no"
    />
  </>
);

const Placeholder = () => (
  <>
    <h2 className="placeholder">&nbsp;</h2>
    <div className="placeholder content">&nbsp;</div>
  </>
);

// FIXME: ternary land claims yet another life
const Video = ({ live, loading, loadCurrentTournament }: VideoProps) => {
  useEffect(() => {
    loadCurrentTournament();
  }, [loadCurrentTournament]);

  return (
    <div className="video">
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? <Placeholder /> : live ? <TwitchEmbed /> : <YouTubeEmbed />}
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const loading = !isInitialized(state.currentTournamentId);
  const live = getValue(state.currentTournamentId)
    .flatMap(id => getValue(state.tournamentDetails[id]))
    .map(currentTournament => isRunning(currentTournament.tournament))
    .getOrElse(false);

  return {
    loading,
    live,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  loadCurrentTournament: () => dispatch(fetchCurrentTournament()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);
