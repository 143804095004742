import React from 'react';
import './ColorPicker.css';
import { colors } from '../lib/ColorUtils';

const Color = ({ name, hex, onChange }) => (
  <button // TODO: find out if changing this from div to button broke stuff
    className="color"
    style={{ backgroundColor: hex }}
    onClick={() => onChange({ name, hex })}
  />
);

const ColorPicker = ({ onChange }) => {
  return (
    <div className="color-picker">
      {colors.map(c => (
        <Color key={c.name} {...c} onChange={onChange} />
      ))}
    </div>
  );
};

export default ColorPicker;
