import React from 'react';
import { useTitle } from '../hooks/useTitle';

import './Rules.css';
import noTimeLimit from '../img/mod-icons/noTimeLimit.png';
import maxTreasure from '../img/mod-icons/maxTreasure.png';
import maxArrows from '../img/mod-icons/maxArrows.png';
import noLookingGlass from '../img/mod-icons/noLookingGlass.png';
import noSpeedBoots from '../img/mod-icons/noSpeedBoots.png';
import cursedBows from '../img/mod-icons/cursedBows.png';
import doubleJumping from '../img/mod-icons/doubleJumping.png';
import explodingCorpses from '../img/mod-icons/explodingCorpses.png';
import corpsesDropArrows from '../img/mod-icons/corpsesDropArrows.png';
import superDodging from '../img/mod-icons/superDodging.png';
import triggerCorpses from '../img/mod-icons/triggerCorpses.png';
import ignoreTowerItemSet from '../img/mod-icons/ignoreTowerItemSet.png';

const Rules = () => {
  useTitle('DrunkenFall Rules');
  return (
    <div id="rules-page">
      <h1>IRL rules</h1>
      <div className="irl-rules">
        <div>
          <h2>
            Rule #1 - Don't be a dick{' '}
            <span role="img" aria-label="eggplant">
              🍆
            </span>
          </h2>
          <p>
            DrunkenFall is about having fun! Any kind of -phobia against people
            is not welcome, and violence (besides in video games) is absolutely
            forbidden.
          </p>
          <p>
            The organizers are friendly people who want every guest to have a
            great evening - please help us! If you notice anyone being sexist,
            racist, homophobe, mean, or anything un-great - let us know! We'll
            take action accordingly.
          </p>
          <p>
            We hate to have to have this as a rule, so please make us pleasently
            surprised.
          </p>
          <p>
            tl;dr don’t be a dickhead, a sore loser, or rude to anyone. We just
            came out to have a good time.
          </p>
        </div>
        <div>
          <h2>
            Rule #2 - You can leave at any time{' '}
            <span role="img" aria-label="clock face">
              🕚
            </span>
          </h2>
          <p>
            We respect that sometimes other things than video game tournaments
            take priority in your life. If you are in the tournament but need to
            leave for any reason, doing so is absolutely fine and the tournament
            software is written to handle that very situation. But please, do
            follow rule #1 and <b>tell one of the organizers</b> so we can act
            accordingly.
          </p>
        </div>
        <div>
          <h2>
            Rule #3 - Drink responsibly{' '}
            <span role="img" aria-label="liqueur glass">
              🥃
            </span>
          </h2>
          <p>
            We have <i>drunken</i> as a prefix for a reason. If you are skilled
            enough to <b>make it to the playoffs</b>, certain conditions will
            net you a shot of liquor;
          </p>
          <ul>
            <li>Killing yourself in any way</li>
            <li>
              Killing <i>all</i> your opponents in the round; what we call a{' '}
              <i>sweep</i>
            </li>
            <li>Winning the match</li>
          </ul>
          <p>
            The alcohol is provided by us, free of charge. That said, we are
            committed to make DrunkenFall a safe and sane experience for
            everyone involved, and as such we will apply common sense as to who
            gets alcohol when.
          </p>
        </div>
      </div>

      <h1>Tournament flow</h1>
      <div className="tournament-flow">
        <h2>The four phases</h2>
        <p>
          At its core, a DrunkenFall tournament can be seen as having four
          phases;
        </p>
        <ol>
          <li>Warmup</li>
          <li>Qualifying rounds</li>
          <li>Showdown</li>
          <li>Final</li>
        </ol>
        <h3>Warmup</h3>
        <p>
          Friendly and fun matches to warm up the APM and get to know the game a
          bit more. <b>Score is not tracked</b>, <b>shots are not given</b>, and
          all is fun and chill. Anyone can play, regardless if they desire to
          join the tournament or not.
        </p>
        <p>
          The matches during the warmups are to <b>10 in-game points</b>. (not
          that it matters, but hey!)
        </p>
        <h3>Qualifying rounds</h3>
        <p>
          The tournament has now started! Score is tracked,{' '}
          <b>but no shots are given yet</b>. The qualifying rounds will continue
          until the organizers decides it's time to go to <i>showdown</i>, and
          then the 16 highest scoring players will enter said <i>showdown</i>!
        </p>
        <p>
          The tournament algorithm decides who plays when using a sorting
          method;
        </p>
        <ul>
          <li>
            Players who have <b>played less matches</b> are preferred
          </li>
          <li>
            After that, players are <b>sorted by score</b> - higher scores are
            preferred
          </li>
          <li>
            If a player played in the previous match, they are{' '}
            <b>excluded from the upcoming one</b>
          </li>
        </ul>
        <p>
          This results in a tournament where{' '}
          <b>new joiners get to play first</b>, but{' '}
          <b>those that score well will play more</b>, and{' '}
          <b>no one will play two matches in a row</b>.
        </p>
        <p>
          If this seems unclear, please trust us in that we have spent countless
          hours trying to make a tournament algorithm that is fair, sane, and
          fun.
        </p>
        <p>
          The matches during the qualifying rounds are to{' '}
          <b>10 in-game points</b>.
        </p>
        <h3>Showdown</h3>
        <p>
          This is when we <b>start to give you shots</b>! At this point, the{' '}
          <b>16 highest scoring players</b> are in the battle to decide who is
          the ultimate winner!
        </p>
        <p>
          The showdown first consists of four <i>playoff</i> matches. If you win
          your playoff match, you{' '}
          <b>
            advance to the <i>final</i>
          </b>
          .
        </p>
        <p>
          The matches during the playoff rounds are to <b>20 in-game points</b>.
        </p>
        <h3>Final</h3>
        <p>
          The players in the final will duke everything out in one last all-out
          match! In this match, we play on the <b>Cataclysm</b> level, and{' '}
          <b>every arrow and powerup</b> in the game are enabled, and the
          players are now in <b>control of when their corpse explodes</b> (via
          the Trigger Corpses game modifier)
        </p>
        <p>
          The final matches the playoff rounds and runs to{' '}
          <b>20 in-game points</b>.
        </p>
      </div>

      <h1>Game modifications</h1>
      <div className="game-mods">
        <div className="section">
          <h2>Match settings</h2>
          <div className="game-mod">
            <div className="images">
              <img alt="No time limit" src={noTimeLimit} />
            </div>
            <div className="description">
              <h3>No Time Limit</h3>
              <p>DrunkenFall rounds can last indefinitely.</p>
            </div>
          </div>

          <div className="game-mod">
            <div className="images">
              <img alt="Max treasure" src={maxTreasure} />
            </div>
            <div className="description">
              <h3>Max Treasure</h3>
              <p>
                At DrunkenFall we want maximum number of treasure chests each
                round.
              </p>
            </div>
          </div>

          <div className="game-mod">
            <div className="images">
              <img alt="Max arrows" src={maxArrows} />
            </div>
            <div className="description">
              <h3>Max Arrows</h3>
              <p>
                At DrunkenFall players start each round with 6 arrows instead of
                the standard 3.
              </p>
            </div>
          </div>

          <div className="game-mod">
            <div className="images">
              <img alt="No looking glass" src={noLookingGlass} />
              <img alt="No speed boots" src={noSpeedBoots} />
            </div>
            <div className="description">
              <h3>Ignored items</h3>
              <p>
                At DrunkenFall we want to have fun. Looking Glass and Speed
                Boots are no fun and thus we removed them from the tournament,
                making it more possible for arrows for spawn!
              </p>
            </div>
          </div>
        </div>

        <div className="section">
          <h2>Curses</h2>
          <div className="game-mod">
            <div className="images">
              <img alt="Cursed bows" src={cursedBows} />
            </div>
            <div className="description">
              <h3>Cursed Bows</h3>
              <p>
                You have been warned!{' '}
                <b>Shooting with an empty quiver will kill you!</b> You will die
                a a gruesome death when releasing the shoot button if you have
                no arrows. Counts as a self kill and results in a shot of liquor
                handed to you.
              </p>
            </div>
          </div>
        </div>

        <div className="section">
          <h2>Modifiers</h2>
          <div className="game-mod">
            <div className="images">
              <img alt="Double jumping" src={doubleJumping} />
            </div>
            <div className="description">
              <h3>Double jumping</h3>
              <h4>Like an Old School platformer!</h4>
              <p>
                The player has an extra jump that can be used while airborne and
                is restored only after standing on a surface or clinging to a
                ledge.
              </p>
            </div>
          </div>

          <div className="game-mod">
            <div className="images">
              <img alt="Exploding corpses" src={explodingCorpses} />
            </div>
            <div className="description">
              <h3>Exploding Corpses</h3>
              <p>
                Player corpses explode a few moments after dying. Points are
                awarded to players for killing opponents with their exploding
                corpse. Watch out for dying people!
              </p>
            </div>
          </div>

          <div className="game-mod">
            <div className="images">
              <img alt="Corpses drop arrows" src={corpsesDropArrows} />
            </div>
            <div className="description">
              <h3>Corpses Drop Arrows</h3>
              <p>
                Player corpses will drop all arrows in their possession upon
                death. Pick them up!
              </p>
            </div>
          </div>

          <div className="game-mod">
            <div className="images">
              <img alt="Super dodging" src={superDodging} />
            </div>
            <div className="description">
              <h3>Super Dodging</h3>
              <p>
                The player’s dodge range is nearly doubled, speed is the key!
              </p>
            </div>
          </div>
        </div>

        <div className="section">
          <h2>The Final</h2>
          <div className="game-mod">
            <div className="images">
              <img alt="Trigger corpses" src={triggerCorpses} />
            </div>
            <div className="description">
              <h3>Trigger Corpses</h3>
              <p>
                Instead of the automatically exploding corpses, you now become a
                landmine! Hold Shoot button to explode your corpse! Player
                corpses will remain idle and explode only after continuously
                holding the Shoot, Jump, or Dodge button for a few moments.
                Points are awarded to players for killing opponents with their
                exploding corpse.
              </p>
            </div>
          </div>

          <div className="game-mod">
            <div className="images">
              <img alt="Ignore tower item set" src={ignoreTowerItemSet} />
            </div>
            <div className="description">
              <h3>Ignore Tower Item Set</h3>
              <p>In the final we allow all powerups and arrows to spawn.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
