import React from 'react';
import { getCurrentMatch, getLeaderboard } from '../lib/TournamentUtils';
import { getLevelTitle, getMatchLength } from '../lib/MatchUtils';
import StatusBar from './StatusBar';
import { Color, Person } from '../lib/Types';
import { getHex } from '../lib/ColorUtils';
import { LiveTournamentProps } from '../containers/LiveTournamentContainer';
import { Avatar } from './avatar/Avatar';

interface UpcomingPlayersProps {
  players: PersonOfColor[];
}

interface PersonOfColor {
  score: number;
  person: Person;
  color: Color;
  matches: number;
}

const UpcomingPlayers = ({ players }: UpcomingPlayersProps) => (
  <div className="players">
    {players.map((player, index) => {
      return (
        <div className="player" key={player.person.id}>
          <div className="rank">{index + 1}. </div>
          <Avatar person={player.person} />
          <div className="nick" style={{ color: getHex(player.color) }}>
            {player.person.nick}
          </div>
          <div className="points">{player.score} pts </div>
          <div className="matches">{player.matches} m </div>
        </div>
      );
    })}
  </div>
);

const QualificationsStream = ({ tournament, people }: LiveTournamentProps) => {
  const currentMatch = getCurrentMatch(tournament).getOrThrow(
    () => new Error(`Could not get the current match`),
  );
  const matchLength = getMatchLength(currentMatch);

  const leaderboard = getLeaderboard(tournament.player_summaries)
    .map(ps => {
      const person = people[ps.person_id];
      return {
        score: ps.skill_score,
        person,
        color: person.preferred_color,
        matches: ps.matches,
      };
    })
    .slice(0, 13);

  return (
    <div id="qualifications">
      <div className="leaderboard">
        <h1>Leaderboard</h1>
        <UpcomingPlayers players={leaderboard} />
      </div>
      <div className="match-info">
        <div className="title">
          {currentMatch.name} - Round {currentMatch.round}
        </div>
        <div className="level-and-length">
          {getLevelTitle(currentMatch)} - Match to {matchLength} points
        </div>
      </div>
      <StatusBar
        currentMatch={currentMatch}
        tournament={tournament}
        people={people}
      />
    </div>
  );
};

export default QualificationsStream;
