import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import './DisableUsers.css';
import { fetchPeople, toggleUser as toggleUserAction } from '../actions';
import { byName } from '../lib/UserUtils';
import { FilterableUserList } from '../components/FilterableUserList';
import { Dict } from '../reducers';
import { Person } from '../lib/Types';
import { AppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';

interface StateProps {
  people: Dict<Person>;
}

interface DispatchProps {
  loadUsers: () => void;
  toggleUser: (id: number) => void;
}

type DisableUsersProps = StateProps & DispatchProps;

const DisableUsers = ({ people, toggleUser, loadUsers }: DisableUsersProps) => {
  useEffect(() => {
    loadUsers();
  }, [loadUsers]);
  const peopleList = Object.values(people).sort(byName);
  const active = peopleList.filter(p => !p.disabled);
  const disabled = peopleList.filter(p => !!p.disabled);
  return (
    <div id="disable-users-page">
      <div className="pickers">
        <div>
          <h1>Still fightin'! ({active.length})</h1>
          <FilterableUserList
            people={active}
            onUserSelect={user => toggleUser(user.id)}
          />
        </div>
        <div>
          <h1>
            ...their deeds of valor will be remembered. ({disabled.length})
          </h1>
          <FilterableUserList
            people={disabled}
            onUserSelect={user => toggleUser(user.id)}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  people: state.players.data,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  toggleUser: (id: number) => dispatch(toggleUserAction(id)),
  loadUsers: () => dispatch(fetchPeople()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisableUsers);
