import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  user: state.session.data.user,
});

const protectedPage = (minUserLevel, WrappedComponent) =>
  connect(mapStateToProps)(props => {
    const user = props.user;
    const location = useLocation();
    const navigate = useNavigate();

    if (user === undefined || user.userlevel < minUserLevel) {
      navigate('/login', { state: { from: location.pathname } });
      return null;
    }

    return <WrappedComponent {...props} loggedInUser={user} />;
  });

export default protectedPage;
