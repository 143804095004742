import { field, indexBy } from './CollectionUtils';
import { Color } from './Types';

export const colors = [
  { name: 'green', hex: '#4e9110' },
  { name: 'blue', hex: '#4c7cba' },
  { name: 'pink', hex: '#e39bb5' },
  { name: 'orange', hex: '#c75800' },
  { name: 'white', hex: '#fafafa' },
  { name: 'yellow', hex: '#efc822' },
  { name: 'cyan', hex: '#59c2c1' },
  { name: 'purple', hex: '#a66ce5' },
  { name: 'red', hex: '#DB0F00' },
];

const backgroundColors = [
  { name: 'green', hex: '#4c7723' },
  { name: 'blue', hex: '#2b5d9e' },
  { name: 'pink', hex: '#bf6e8b' },
  { name: 'orange', hex: '#8c4813' },
  { name: 'white', hex: '#ababab' },
  { name: 'yellow', hex: '#af9837' },
  { name: 'cyan', hex: '#33807f' },
  { name: 'purple', hex: '#5b1a5f' },
  { name: 'red', hex: '#6d1e17' },
];

type HexBrand = {};

export type HexColor = string & HexBrand;

const colorsByName = indexBy(field('name'))(colors);
const backgroundColorsByName = indexBy(field('name'))(backgroundColors);

export const getHex = (color: Color): HexColor => colorsByName[color].hex;
export const getBackgroundHex = (color: Color): HexColor =>
  backgroundColorsByName[color].hex;
