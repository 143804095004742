import React from 'react';
import LiveTournamentContainer from '../../containers/LiveTournamentContainer';
import QualificationOverviewLeaderboard from '../../components/QualificationOverviewLeaderboard';

const Leaderboard = () => (
  <LiveTournamentContainer>
    {props => <QualificationOverviewLeaderboard {...props} />}
  </LiveTournamentContainer>
);

export default Leaderboard;
