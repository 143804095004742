import React from 'react';
import ErrorBoundary from './ErrorBoundary';

const withErrorBoundary = WrappedComponent => props =>
  (
    <ErrorBoundary>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );

export default withErrorBoundary;
