export class Maybe<T> {
  value: T | null;

  constructor(value: T | null | undefined) {
    if (value === null || value === undefined) {
      this.value = null;
    } else {
      this.value = value;
    }
  }

  map<R>(func: (value: T) => R): Maybe<R> {
    return new Maybe<R>(this.value === null ? null : func(this.value));
  }

  flatMap<R>(func: (value: T) => Maybe<R>): Maybe<R> {
    if (this.value === null) {
      return new Maybe<R>(null);
    }
    return func(this.value);
  }

  getOrElse(defaultValue: T): T {
    return this.value === null ? defaultValue : this.value;
  }

  getOrSupply(defaultValueSupplier: () => T): T {
    return this.value === null ? defaultValueSupplier() : this.value;
  }

  getOrThrow(errorSupplier: () => Error): T {
    if (this.value !== null) {
      return this.value;
    }
    throw errorSupplier();
  }

  hasValue(): boolean {
    return this.value !== null;
  }

  get(): T | null {
    return this.value;
  }
}
