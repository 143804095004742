import React, { ReactNode } from 'react';
import PeopleContainer from './PeopleContainer';
import CurrentTournamentContainer from './CurrentTournamentContainer';
import { LeaderboardEntry, Person, TournamentResponse } from '../lib/Types';
import { Dict } from '../reducers';

export type LiveTournamentProps = {
  tournament: TournamentResponse;
  people: Dict<Person>;
  leaderboard: Dict<LeaderboardEntry>;
};

type LiveTournamentContainerProps = {
  children: (props: LiveTournamentProps) => ReactNode;
};

const LiveTournamentContainer = ({
  children,
}: LiveTournamentContainerProps) => (
  <CurrentTournamentContainer>
    {tournament => (
      <PeopleContainer>
        {(people, leaderboard) => children({ tournament, people, leaderboard })}
      </PeopleContainer>
    )}
  </CurrentTournamentContainer>
);

export default LiveTournamentContainer;
