// const baseUrl = 'http://localhost';
// const baseApiUrl = `${baseUrl}:3000/api`;
import { sanitizeTournamentDetailObject } from '../lib/TournamentUtils';
import { sanitizeMatchObject } from '../lib/MatchUtils';
import {
  Color,
  LeaderboardEntry,
  Person,
  PlayerSummary,
  Tournament,
  TournamentResponse,
} from '../lib/Types';
import { Maybe } from '../lib/Maybe';

const baseApiUrl = `/api`;

const rejectIfNotOk = (path: string) => {
  return (r: Response): Response | Promise<any> => {
    return r.ok
      ? r
      : new Promise((_, reject) =>
          reject(
            new Error(`Call to ${path} failed. ${r.status} (${r.statusText})`),
          ),
        );
  };
};

const get = (path: string, options = {}): Promise<any> =>
  fetch(`${baseApiUrl}${path}`, {
    ...options,
    credentials: 'include',
  })
    .then(rejectIfNotOk(path))
    .then(r => r.json());

const postRaw = (path: string, body: any, options = {}) =>
  fetch(`${baseApiUrl}${path}`, {
    ...options,
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(body),
  }).then(rejectIfNotOk(path));

export const post = (path: string, body?: any, options?: any) =>
  postRaw(path, body, options).then(r => r.json());

const putRaw = (path: string, body: any, options = {}) =>
  fetch(`${baseApiUrl}${path}`, {
    ...options,
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(body),
  }).then(rejectIfNotOk(path));

export const put = (path: string, body?: any, options?: any) =>
  putRaw(path, body, options).then(r => r.json());

const del = (path: string, options = {}) =>
  fetch(`${baseApiUrl}${path}`, {
    ...options,
    method: 'DELETE',
    credentials: 'include',
  })
    .then(rejectIfNotOk(path))
    .then(r => r.json());

export const getAllTournaments = (): Promise<Tournament[]> => {
  return get('/tournaments/').then(r =>
    r.tournaments.map(sanitizeTournamentDetailObject),
  );
};

/**
 * Returns tournaments in the current league.
 * A tournament is in the current league if it has a cover image.
 * TODO: Make the model aware of the leagues.
 */
export async function getCurrentLeagueTournaments(): Promise<Tournament[]> {
  const tournaments = await getAllTournaments();
  return tournaments.filter(t => t.cover);
}

export async function getLatestLeagueTournament(): Promise<Maybe<Tournament>> {
  const tournaments = await getCurrentLeagueTournaments();
  return new Maybe(tournaments[0]);
}

export async function getTournament(id: number): Promise<TournamentResponse> {
  const tournament = await get(`/tournaments/${id}/`);
  return {
    ...tournament,
    matches: tournament.matches.map(sanitizeMatchObject),
    tournament: sanitizeTournamentDetailObject(tournament.tournament),
  };
}

export async function getLatestTournament(): Promise<TournamentResponse> {
  const tournament = await get('/tournaments/:latest');
  return {
    ...tournament,
    matches: tournament.matches.map(sanitizeMatchObject),
    tournament: sanitizeTournamentDetailObject(tournament.tournament),
  };
}

export async function registerPlayer(
  nick: string,
  name: string,
  color: Color,
  isAlternate: boolean,
): Promise<unknown> {
  const data = { nick, name, color, archer_type: isAlternate ? 1 : 0 };
  return await post('/user/register/', data);
}

export async function updateSettings(
  nick: string,
  name: string,
  color: Color,
  isAlternate: boolean,
): Promise<Person> {
  const data = { nick, name, color, archer_type: isAlternate ? 1 : 0 };
  const userSettingsResponse = await post('/user/settings/', data);
  return await userSettingsResponse.person;
}

export function getAllPeopleAndLeaderboard(): Promise<{
  people: Person[];
  leaderboard: LeaderboardEntry[];
}> {
  return get('/people/?all=true');
}

export const getFakeTournamentName = (): Promise<{
  name: string;
  numeral: string;
}> => get('/fake/name');

type TournamentCreateInput = {
  name: string;
  color: Color;
  slug: string;
  scheduled: string; // FIXME
  description: string;
};

type TournamentUpdateInput = TournamentCreateInput & { id: number };

type DrunkenfallCreateInput = TournamentCreateInput & { cover: string };
type DrunkenfallUpdateInput = TournamentUpdateInput & { cover: string };

export function createDrunkenfall({
  name,
  color,
  cover,
  slug,
  scheduled,
  description,
}: DrunkenfallCreateInput): Promise<{ id: number }> {
  const data = { name, color, cover, slug, scheduled, description };
  return post('/tournaments/', data);
}

export function createGroupTournament({
  name,
  color,
  slug,
  scheduled,
  description,
}: TournamentCreateInput): Promise<{ id: number }> {
  const data = { name, color, slug, scheduled, description };
  return post('/tournaments/', data);
}

export function updateDrunkenfall({
  id,
  name,
  color,
  cover,
  slug,
  scheduled,
  description,
}: DrunkenfallUpdateInput): Promise<{ id: number }> {
  const data = { id, name, color, cover, slug, scheduled, description };
  return put(`/tournaments/${id}/`, data);
}

export function updateGroupTournament({
  id,
  name,
  color,
  slug,
  scheduled,
  description,
}: TournamentUpdateInput): Promise<{ id: number }> {
  const data = { id, name, color, slug, scheduled, description };
  return put(`/tournaments/${id}/`, data);
}

function isDrunkenfallUpdateInput(
  drunkenfall: DrunkenfallCreateInput & { id?: number },
): drunkenfall is DrunkenfallUpdateInput {
  return drunkenfall.id !== undefined;
}

export function upsertDrunkenfall(
  drunkenfall: DrunkenfallCreateInput & { id?: number },
) {
  if (isDrunkenfallUpdateInput(drunkenfall)) {
    return updateDrunkenfall(drunkenfall);
  }
  return createDrunkenfall(drunkenfall);
}

function isTournamentUpdateInput(
  tournament: TournamentCreateInput & { id?: number },
): tournament is TournamentUpdateInput {
  return tournament.id !== undefined;
}

export function upsertGroupTournament(
  tournament: TournamentCreateInput & { id?: number },
) {
  if (isTournamentUpdateInput(tournament)) {
    return updateGroupTournament(tournament);
  }
  return createGroupTournament(tournament);
}

interface LoggedOutResponse {
  authenticated: boolean;
}

function getUser(): Promise<Person | LoggedOutResponse> {
  return get('/user/');
}

function isLoggedOutResponse(
  arg: Person | LoggedOutResponse,
): arg is LoggedOutResponse {
  if ('authenticated' in arg) {
    return arg.authenticated !== undefined;
  }
  return false;
}

export async function getLoggedInUser(): Promise<Person | undefined> {
  const userResponse = await getUser();
  if (isLoggedOutResponse(userResponse)) {
    return undefined;
  }
  return userResponse;
}

export function deleteTestTournaments(): Promise<Tournament[]> {
  return del(`/tournaments/`).then(r => r.tournaments);
}

export function startTournament(id: number): Promise<unknown> {
  return get(`/tournaments/${id}/start/`);
}

export function toggleUser(id: number): Promise<unknown> {
  return get(`/user/disable/${id}`);
}

export const toggleParticipant = (
  tournamentId: number,
  playerId: number,
): Promise<void> => {
  return get(`/tournaments/${tournamentId}/toggle/${playerId}`);
};

export const getParticipants = (
  tournamentId: number,
): Promise<PlayerSummary[]> => {
  return get(`/tournaments/${tournamentId}/players/`).then(
    response => response.player_summaries,
  );
};

export const startMatch = (
  tournamentId: number,
  matchIndex: number,
): Promise<void> => {
  return post(`/tournaments/${tournamentId}/play/?index=${matchIndex}`);
};

export const addTestingPlayers = (tournamentId: number): Promise<void> => {
  return get(`/tournaments/${tournamentId}/usurp/`);
};

export const pauseUntil = (
  tournamentId: number,
  minutes: number,
): Promise<void> => {
  return get(`/tournaments/${tournamentId}/time/${minutes}`);
};

export const logOut = (): Promise<void> => {
  return get('/user/logout/');
};

export const endQualifying = (
  tournamentId: number,
  time: string,
): Promise<void> => {
  return post(`/tournaments/${tournamentId}/endqualifying/`, { time });
};
