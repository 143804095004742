import { FormEventHandler, useState } from 'react';

type ReturnType = [FormEventHandler, boolean, boolean, boolean, string];

// TODO: this is one of many ways of data loading
const useSubmitAndRedirect = <T>(
  submitter: () => Promise<T>,
  createRedirectUrl: (response: T) => string,
): ReturnType => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [redirect, setRedirect] = useState('');
  const handleSubmit: FormEventHandler = event => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    submitter()
      .then(createRedirectUrl)
      .then(redirectUrl => {
        setSuccess(true);
        setTimeout(() => setRedirect(redirectUrl), 1000);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(true);
      })
      .finally(() => setLoading(false));
  };
  return [handleSubmit, loading, error, success, redirect];
};

export default useSubmitAndRedirect;
