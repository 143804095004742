import { Color, Person } from '../lib/Types';
import React from 'react';
import { getHex } from '../lib/ColorUtils';

interface UpcomingPlayersProps {
  title: string;
  players: PersonOfColor[];
  showNames: boolean;
}

interface PersonOfColor {
  person: Person;
  color: Color;
}

export const UpcomingPlayers = ({
  title,
  players,
  showNames,
}: UpcomingPlayersProps) => (
  <div className="next-scheduled">
    <h1>{title}</h1>
    <div className="players">
      {players.map(player => {
        return (
          <div className="player" key={player.person.id}>
            <div className="nick" style={{ color: getHex(player.color) }}>
              {player.person.nick}
            </div>
            {showNames && <div className="name">{player.person.name}</div>}
          </div>
        );
      })}
    </div>
  </div>
);
