import { DataState } from '../reducers';
import { Maybe } from './Maybe';

export const getValue = <T>(state: DataState<T>): Maybe<T> => {
  return state && state.initialized && !state.error
    ? new Maybe<T>(state.data)
    : new Maybe<T>(null);
};

export const isInitialized = <T>(state: DataState<T>): boolean => {
  if (!state) {
    return true;
  }
  return state.initialized;
};

export const getError = <T>(state: DataState<T>): Maybe<Error> => {
  if (!state) {
    return new Maybe<Error>(null);
  }
  return new Maybe(state.error);
};
