import boltArrows from '../img/arrows/boltArrows.png';
import bombArrows from '../img/arrows/bombArrows.png';
import brambleArrows from '../img/arrows/brambleArrows.png';
import drillArrows from '../img/arrows/drillArrows.png';
import featherArrows from '../img/arrows/featherArrows.png';
import laserArrows from '../img/arrows/laserArrows.png';
import prismArrows from '../img/arrows/prismArrows.png';
import singleArrow from '../img/arrows/singleArrow.png';
import superBombArrows from '../img/arrows/superBombArrows.png';
import triggerArrows from '../img/arrows/triggerArrows.png';
import { ArrowType } from './Types';

export const getArrowImage = (arrowType: ArrowType) => {
  switch (arrowType) {
    case ArrowType.Bolt:
      return boltArrows;
    case ArrowType.Bomb:
      return bombArrows;
    case ArrowType.Bramble:
      return brambleArrows;
    case ArrowType.Drill:
      return drillArrows;
    case ArrowType.Feather:
      return featherArrows;
    case ArrowType.Laser:
      return laserArrows;
    case ArrowType.Normal:
      return singleArrow;
    case ArrowType.Prism:
      return prismArrows;
    case ArrowType.SuperBomb:
      return superBombArrows;
    case ArrowType.Toy:
      return singleArrow;
    case ArrowType.Trigger:
      return triggerArrows;
    default:
      return singleArrow;
  }
};
