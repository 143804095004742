import { PlayerSelector } from './PlayerSelector';
import { KillType } from '../../lib/Types';
import Button from '../Button';
import { faSkull } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import './KillPlayerButton.css';
import { KillTypeSelector } from './KillTypeSelector';
import {
  createKillMessage,
  postGameMessage,
} from '../../lib/game-utils/messages';

export function KillPlayerButton() {
  const [player, setPlayer] = useState<number>(0);
  const [killer, setKiller] = useState<number>(0);
  const [killType, setKillType] = useState<KillType>(0);
  return (
    <div className="kill-player-button">
      <section>
        <PlayerSelector onPlayerChange={setPlayer} title="Victim" />
        <PlayerSelector onPlayerChange={setKiller} title="Killer" />
        <KillTypeSelector onKillTypeChange={setKillType} />
      </section>
      <Button
        icon={faSkull}
        onClick={() =>
          postGameMessage(createKillMessage(killType, killer, player))
        }
      >
        KILL
      </Button>
    </div>
  );
}
