import React from 'react';
import './Ribbon.css';
import { Color } from '../lib/Types';

type RibbonProps = {
  color?: Color;
  children: React.ReactNode;
};

export function Ribbon({ children, color }: RibbonProps) {
  return (
    <div className={`ribbon ${color ?? ''}`}>
      <div className="content">{children}</div>
    </div>
  );
}
