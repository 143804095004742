import './EditTournament.css';
import { useParams } from 'react-router-dom';
import { getTournament } from '../api/api';
import useData from '../hooks/useData';
import {
  TournamentEditForm,
  TournamentType,
} from '../components/TournamentEditForm';
import { isoToDateInput } from '../lib/DateUtils';

function getType(tournamentName: string): TournamentType {
  if (tournamentName.toLowerCase().startsWith('drunkenfall')) {
    return 'drunkenfall';
  }
  return 'group';
}

export function EditTournament() {
  const params = useParams();
  const id = parseInt(params.id!, 10);
  const [data, loading, error] = useData(() => getTournament(id), [id]);
  return (
    <div id="edit-tournament-page">
      {!loading && !error && data !== null ? (
        <TournamentEditForm
          id={data.tournament.id}
          type={getType(data.tournament.name)}
          name={data.tournament.name}
          slug={data.tournament.slug}
          date={isoToDateInput(data.tournament.scheduled)}
          description={data.tournament.description}
          cover={data.tournament.cover}
        />
      ) : null}
    </div>
  );
}
