import React from 'react';
import useInputState from '../hooks/useInputState';
import { nowTime, todayAtTime } from '../lib/DateUtils';
import SubmitButton from '../components/SubmitButton';
import { endQualifying } from '../api/api';
import useSubmitAndRedirect from '../hooks/useSubmitAndRedirect';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import './EndQualifying.css';
import { Navigate, useParams } from 'react-router-dom';

const EndQualifying = () => {
  const params = useParams();
  const tournamentId = parseInt(params.id!, 10); // TODO: is there a better way to ensure type?
  const [time, handleTimeChange] = useInputState(nowTime());
  const [handleSubmit, loading, error, success, redirect] =
    useSubmitAndRedirect(
      () => endQualifying(tournamentId, todayAtTime(time)),
      () => `/tournaments/${tournamentId}`,
    );
  const isValid = time.trim().length > 0;
  return (
    <div id="end-qualifying-page">
      {redirect && <Navigate to={redirect} />}
      <h1>End Qualifying</h1>
      <form onSubmit={handleSubmit}>
        <input type="time" onChange={handleTimeChange} value={time} />
        <SubmitButton
          icon={faSave}
          disabled={!isValid}
          {...{ loading, error, success }}
        >
          Go go
        </SubmitButton>
      </form>
    </div>
  );
};

export default EndQualifying;
