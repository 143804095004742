import { Person } from './Types';

export const PRODUCER = 100;
export const COMMENTATOR = 50;
export const JUDGE = 30;
export const PLAYER = 10;

const isProducer = (user: Person | undefined): boolean =>
  user !== undefined && user.userlevel >= PRODUCER;
const isCommentator = (user: Person | undefined): boolean =>
  user !== undefined && user.userlevel >= COMMENTATOR;
const isJudge = (user: Person | undefined): boolean =>
  user !== undefined && user.userlevel >= JUDGE;
const isPlayer = (user: Person | undefined): boolean =>
  user !== undefined && user.userlevel >= PLAYER;

const byName = (p1: Person, p2: Person) => p1.name.localeCompare(p2.name);

export { isProducer, isCommentator, isJudge, isPlayer, byName };
