import React from 'react';

import './Next.css';
import LiveTournamentContainer, {
  LiveTournamentProps,
} from '../../containers/LiveTournamentContainer';
import { getCurrentMatch } from '../../lib/TournamentUtils';
import { Match } from '../../lib/Types';
import PersonCard from '../../components/PersonCard';
import { LargeStatsCard, Stat, StatsCard } from '../../components/StatsCards';
import StreamHeader from '../../components/StreamHeader';
import { Maybe } from '../../lib/Maybe';
import { getOrdinal } from '../../lib/NumberUtils';

type ContentsProps = LiveTournamentProps & { currentMatch: Match };
const Contents = ({
  tournament,
  leaderboard,
  people,
  currentMatch,
}: ContentsProps) => {
  return (
    <div id="next-page">
      <StreamHeader>
        <h1>Up Next</h1>
        <h2>{currentMatch.name}</h2>
      </StreamHeader>
      <div className="players">
        {currentMatch.players.map((player, i) => {
          const person = people[player.person_id];
          const playerSummary = new Maybe(
            tournament.player_summaries.find(ps => ps.person_id === person.id),
          ).getOrThrow(
            () =>
              new Error(
                `Could not get the player summary for person ${person.id}`,
              ),
          );
          const avatarUrl = person.avatar_url;
          const color = player.color;
          const leaderboardEntry = new Maybe(leaderboard[player.person_id]);
          const numberOfTournaments = leaderboardEntry
            .map(l => l.tournaments)
            .getOrElse(0);
          const numberOfMatches = leaderboardEntry
            .map(l => l.matches)
            .getOrElse(0);

          return (
            <div className={`player ${color}`} key={player.person_id}>
              <h3>Player {i + 1}</h3>
              <PersonCard
                nick={player.nick}
                color={color}
                avatarUrl={avatarUrl}
                className="card"
              >
                Total Score: {playerSummary.score} pts
              </PersonCard>
              <div className="stats">
                <LargeStatsCard
                  title="Tournament Standing"
                  className="overview"
                  color={color}
                >
                  <div className="position">
                    {playerSummary.qualifying_position}
                  </div>
                  <Stat name="Points" className="points">
                    {playerSummary.skill_score}
                  </Stat>
                </LargeStatsCard>
                <StatsCard
                  title="This tournament"
                  className="this-tournament"
                  color={color}
                >
                  <Stat name="Kills">{playerSummary.kills}</Stat>
                  <Stat name="Sweeps">{playerSummary.sweeps}</Stat>
                  <Stat name="Self kills">{playerSummary.self}</Stat>
                </StatsCard>
                <StatsCard title="Lifetime" className="lifetime">
                  <Stat name="Tournaments">{numberOfTournaments}</Stat>
                  <Stat name="Matches">{numberOfMatches}</Stat>
                  <Stat
                    name="Best position in tournament"
                    className="best-position"
                  >
                    {person.highest_position ? (
                      <>
                        {getOrdinal(person.highest_position.position)}
                        {person.highest_position.times > 1 && (
                          <span className="times">
                            &times; {person.highest_position.times}
                          </span>
                        )}
                      </>
                    ) : (
                      <span className="newcomer">Newcomer!</span>
                    )}
                  </Stat>
                </StatsCard>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Next = () => {
  return (
    <LiveTournamentContainer>
      {props =>
        getCurrentMatch(props.tournament)
          .map(currentMatch => (
            <Contents currentMatch={currentMatch} {...props} />
          ))
          .getOrElse(<></>)
      }
    </LiveTournamentContainer>
  );
};

export default Next;
