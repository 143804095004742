import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClassNameBuilder } from '../lib/ClassNameUtils';

// TODO: WTF is this? refactor the shit out of this.
const ButtonContainer = ({ onClick, disabled, children, color, ...other }) => {
  const classNameList = new ClassNameBuilder('button').add(color);
  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {disabled ? (
        <div className={classNameList.add('disabled').build()} {...other}>
          {children}
        </div>
      ) : // eslint-disable-next-line no-nested-ternary
      typeof onClick === 'string' ? (
        other.type === 'external' ? (
          <a href={onClick} className={classNameList.build()} {...other}>
            {children}
          </a>
        ) : (
          <Link to={onClick} className={classNameList.build()} {...other}>
            {children}
          </Link>
        )
      ) : other.type === 'submit' ? (
        <button type="submit" className={classNameList.build()} {...other}>
          {children}
        </button>
      ) : (
        <button
          type={other.type || 'button'}
          onClick={onClick}
          className={classNameList.build()}
          {...other}
        >
          {children}
        </button>
      )}
    </>
  );
};

const Button = ({ icon, onClick, ...other }) => {
  return (
    <ButtonContainer
      onClick={onClick}
      color={other.color}
      disabled={other.disabled}
      {...other}
    >
      <div className="icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <p>{other.children}</p>
    </ButtonContainer>
  );
};

const IconButton = ({
  icon,
  onClick,
  children = null,
  color = '',
  disabled = false,
  loading = false,
  ...other
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      color={color}
      disabled={disabled}
      {...other}
    >
      <div className={`icon ${loading ? 'loading' : ''}`}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={`overlay ${loading ? 'loading' : ''}`}>{children}</div>
    </ButtonContainer>
  );
};

export default Button;
export { IconButton };
