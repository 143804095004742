export const indexBy = (indexer: (a: any) => any) => (collection: any[]) =>
  collection.reduce((p, c) => {
    p[indexer(c)] = c;
    return p;
  }, {});

export function field<T>(name: keyof T): (obj: T) => T[keyof T] {
  return (obj: T): T[keyof T] => obj[name];
}

export function descending<T>(getter: (obj: T) => number) {
  return (a: T, b: T) => getter(b) - getter(a);
}

export function ascending<T>(getter: (obj: T) => number) {
  return (a: T, b: T) => getter(a) - getter(b);
}

export const padArray = <T, U>(arr: T[], len: number, fill: U): (T | U)[] => {
  return arr.concat(Array(len).fill(fill)).slice(0, len);
};
