import React from 'react';
import { isoStringToHumanString } from '../../lib/DateUtils';
import { UserCloud } from '../UserPicker';
import { Ribbon } from '../Ribbon';
import { Person, PlayerSummary, TournamentResponse } from '../../lib/Types';
import { Dict } from '../../reducers';
import Countdown from '../Countdown';

import './PreTournament.css';

interface PreTournamentProps {
  tournament: TournamentResponse;
  participants: PlayerSummary[];
  people: Dict<Person>;
}

export const PreTournament = ({
  tournament,
  participants,
  people,
}: PreTournamentProps) => {
  const users = participants.map(p => people[p.person_id]).reverse();
  return (
    <div id="pre-tournament">
      <h1>{tournament.tournament.name}</h1>
      <h2>{isoStringToHumanString(tournament.tournament.scheduled)}</h2>
      <UserCloud users={users} />
      <Ribbon>
        <Countdown to={tournament.tournament.scheduled} />
      </Ribbon>
    </div>
  );
};
