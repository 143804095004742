import {
  faFire,
  faMoon,
  faPlay,
  faStop,
  faStopwatch,
} from '@fortawesome/free-solid-svg-icons';
import {
  createArrowsCollectedMessage,
  createArrowShotMessage,
  createDarkOrbStateMessage,
  createLavaOrbActiveMessage,
  createLavaOrbInactiveMessage,
  createMatchEndMessage,
  createMatchStartMessage,
  createRoundEndMessage,
  createRoundStartMessage,
  createShieldStateMessage,
  createSlowOrbStateMessage,
  createWingsStateMessage,
  postGameMessage,
} from '../lib/game-utils/messages';
import Button from '../components/Button';

import './GameUtils.css';
import { ArrowMessageButton } from '../components/game-utils/ArrowMessageButton';
import { KillPlayerButton } from '../components/game-utils/KillPlayerButton';
import { PlayerSelectingButton } from '../components/game-utils/PlayerSelectingButton';

export function GameUtils() {
  return (
    <div id="game-utils">
      <section>
        <h1>Match / Round Actions</h1>
        <Button
          icon={faPlay}
          onClick={() => postGameMessage(createMatchStartMessage())}
        >
          Start Match
        </Button>
        <Button
          icon={faPlay}
          onClick={() => postGameMessage(createRoundStartMessage())}
        >
          Start Round
        </Button>
        <Button
          icon={faStop}
          onClick={() => postGameMessage(createRoundEndMessage())}
        >
          End Round
        </Button>
        <Button
          icon={faStop}
          onClick={() => postGameMessage(createMatchEndMessage())}
        >
          End Match
        </Button>
      </section>
      <section>
        <h1>Arrow Actions</h1>
        <ArrowMessageButton
          label="Shoot Arrow"
          action={(arrows, player) =>
            postGameMessage(createArrowShotMessage(arrows, player))
          }
        />
        <ArrowMessageButton
          label="Collect Arrow"
          action={(arrows, player) =>
            postGameMessage(createArrowsCollectedMessage(arrows, player))
          }
        />
      </section>
      <section>
        <h1>Kill Player</h1>
        <KillPlayerButton />
      </section>
      <section>
        <h1>Shield and Wings</h1>
        <PlayerSelectingButton
          label="Give Shield"
          action={player =>
            postGameMessage(createShieldStateMessage(player, true))
          }
        />
        <PlayerSelectingButton
          label="Remove Shield"
          action={player =>
            postGameMessage(createShieldStateMessage(player, false))
          }
        />
        <PlayerSelectingButton
          label="Give Wings"
          action={player =>
            postGameMessage(createWingsStateMessage(player, true))
          }
        />
        <PlayerSelectingButton
          label="Remove Wings"
          action={player =>
            postGameMessage(createWingsStateMessage(player, false))
          }
        />
      </section>
      <section>
        <h1>Orbs</h1>
        <Button
          icon={faMoon}
          onClick={() => postGameMessage(createDarkOrbStateMessage(true))}
        >
          Activate Dark Orb
        </Button>
        <Button
          icon={faMoon}
          onClick={() => postGameMessage(createDarkOrbStateMessage(false))}
        >
          Deactivate Dark Orb
        </Button>
        <Button
          icon={faStopwatch}
          onClick={() => postGameMessage(createSlowOrbStateMessage(true))}
        >
          Activate Slow Orb
        </Button>
        <Button
          icon={faStopwatch}
          onClick={() => postGameMessage(createSlowOrbStateMessage(false))}
        >
          Deactivate Slow Orb
        </Button>
        <PlayerSelectingButton
          label="Activate Lava"
          action={player => createLavaOrbActiveMessage(player)}
          icon={faFire}
        />
        <Button
          icon={faFire}
          onClick={() => postGameMessage(createLavaOrbInactiveMessage())}
        >
          Deactivate Lava
        </Button>
      </section>
    </div>
  );
}
