import blueAlt from '../img/archers/blue-alt.png';
import blue from '../img/archers/blue-selected.png';
import cyanAlt from '../img/archers/cyan-alt.png';
import cyan from '../img/archers/cyan-selected.png';
import greenAlt from '../img/archers/green-alt.png';
import green from '../img/archers/green-selected.png';
import orangeAlt from '../img/archers/orange-alt.png';
import orange from '../img/archers/orange-selected.png';
import pinkAlt from '../img/archers/pink-alt.png';
import pink from '../img/archers/pink-selected.png';
import purpleAlt from '../img/archers/purple-alt.png';
import purple from '../img/archers/purple-selected.png';
import redAlt from '../img/archers/red-alt.png';
import red from '../img/archers/red-selected.png';
import whiteAlt from '../img/archers/white-alt.png';
import white from '../img/archers/white-selected.png';
import yellowAlt from '../img/archers/yellow-alt.png';
import yellow from '../img/archers/yellow-selected.png';
import { field, indexBy } from './CollectionUtils';

export const archers = [
  { image: green, name: 'green', alternate: greenAlt },
  { image: blue, name: 'blue', alternate: blueAlt },
  { image: pink, name: 'pink', alternate: pinkAlt },
  { image: orange, name: 'orange', alternate: orangeAlt },
  { image: white, name: 'white', alternate: whiteAlt },
  { image: yellow, name: 'yellow', alternate: yellowAlt },
  { image: cyan, name: 'cyan', alternate: cyanAlt },
  { image: purple, name: 'purple', alternate: purpleAlt },
  { image: red, name: 'red', alternate: redAlt },
];

const archersByColor = indexBy(field('name'))(archers);

export const getArcherImage = ({ color, alternate = false }) =>
  archersByColor[color][alternate ? 'alternate' : 'image'];
