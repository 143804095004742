import React from 'react';
import './PastTournament.css';
import {
  getLosersOfTournament,
  getWinnerOfTournament,
  pointComparator,
  toLocaleDate,
} from '../lib/TournamentUtils';
import {
  Match,
  Person,
  Player,
  PlayerSummary,
  TournamentResponse,
} from '../lib/Types';
import { Dict } from '../reducers';
import { AvatarWithRibbon } from '../components/avatar/AvatarWithRibbon';

type WinnerStatsProps = {
  stats: PlayerSummary | Player;
};

const WinnerStats = ({ stats }: WinnerStatsProps) => (
  <table className="stats">
    <tbody>
      <tr>
        <td>Kills</td>
        <td>{stats.kills}</td>
      </tr>
      <tr>
        <td>Self Kills</td>
        <td>{stats.self}</td>
      </tr>
      <tr>
        <td>Shots</td>
        <td>{stats.shots}</td>
      </tr>
      <tr>
        <td>Sweeps</td>
        <td>{stats.sweeps}</td>
      </tr>
    </tbody>
  </table>
);

type ScrubsProps = {
  playerSummaries: PlayerSummary[];
  people: Dict<Person>;
};

const Scrubs = ({ playerSummaries, people }: ScrubsProps) => (
  <table className="players">
    <thead>
      <tr>
        <td className="rank">Rank</td>
        <td className="nick">Nick</td>
        <td>Kills</td>
        <td>Self</td>
        <td>Shots</td>
        <td>Sweeps</td>
      </tr>
    </thead>
    <tbody>
      {playerSummaries.map((ps, index) => (
        <tr key={ps.id}>
          <td className="rank">#{index + 2}</td>
          <td className="nick">{people[ps.person_id].nick}</td>
          <td>{ps.kills}</td>
          <td>{ps.self}</td>
          <td>{ps.shots}</td>
          <td>{ps.sweeps}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

type MatchPlayerProps = {
  player: Player;
  person: Person;
};
const MatchPlayer = ({ player, person }: MatchPlayerProps) => {
  return (
    <tr className={`player ${player.color}`}>
      <td className="name">
        {player.display_names ? player.display_names.join(' ') : person.nick}
      </td>
      <td>{player.kills}</td>
      <td>{player.self}</td>
      <td>{player.shots}</td>
      <td>{player.sweeps}</td>
    </tr>
  );
};

type MatchProps = {
  match: Match;
  people: Dict<Person>;
};

const MatchSummary = ({ match, people }: MatchProps) => (
  <>
    <h3>{match.name}</h3>
    <table className="match-players">
      <thead>
        <tr>
          <td className="nick">Nick</td>
          <td>
            <span role="img" aria-label="Kills">
              ☠️
            </span>
          </td>
          <td>
            <span role="img" aria-label="Self Kills">
              🤯🔫
            </span>
          </td>
          <td>
            <span role="img" aria-label="Shots">
              🍺
            </span>
          </td>
          <td>
            <span role="img" aria-label="Sweeps">
              🧹
            </span>
          </td>
        </tr>
      </thead>
      <tbody>
        {match.players.sort(pointComparator).map(player => (
          <MatchPlayer
            key={player.id}
            player={player}
            person={people[player.person_id]}
          />
        ))}
      </tbody>
    </table>
  </>
);

interface OwnProps {
  tournament: TournamentResponse;
  people: Dict<Person>;
}

type PastTournamentProps = OwnProps;

const PastTournament = ({ tournament, people }: PastTournamentProps) => {
  const winner = getWinnerOfTournament(tournament).getOrThrow(
    () => new Error('Could not determine the winner'),
  );
  const p = people[winner.person_id];
  const losers = getLosersOfTournament(tournament, winner.person_id);
  return (
    <div id="past-tournament-page">
      <div className="section hero">
        <div className="image">
          <img
            src={tournament.tournament.cover}
            alt={tournament.tournament.name}
          />
        </div>
        <div className="text">
          <h1>{tournament.tournament.name}</h1>
          <p>{toLocaleDate(tournament.tournament.started)}</p>
        </div>
      </div>
      <div className="section winner">
        <h2>The Winner</h2>
        <AvatarWithRibbon className="avatar" person={p} ribbonText={p.nick} />
        <WinnerStats stats={winner} />
      </div>
      <div className="section scrubs">
        <h2>The scrubs</h2>
        <Scrubs playerSummaries={losers} people={people} />
      </div>
      {tournament.matches.reverse().map(match => (
        <div className="section" key={match.id}>
          <MatchSummary match={match} people={people} />
        </div>
      ))}
    </div>
  );
};

export default PastTournament;
