import { useState } from 'react';
import { Color } from '../lib/Types';
import useInputState from '../hooks/useInputState';
import { upsertDrunkenfall, upsertGroupTournament } from '../api/api';
import useSubmitAndRedirect from '../hooks/useSubmitAndRedirect';
import { Navigate } from 'react-router-dom';
import ColorPicker from './ColorPicker';
import Error from './Error';
import SubmitButton from './SubmitButton';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';

const empty = (thing: string) => !thing || !thing.trim();

const validate = (
  name: string,
  slug: string,
  cover: string,
  scheduled: string,
  type: string,
) => {
  return !(
    empty(name) ||
    empty(slug) ||
    empty(scheduled) ||
    (empty(cover) && type === 'drunkenfall')
  );
};

const iso = (dateStr: string) => new Date(dateStr).toISOString();

export type TournamentType = 'drunkenfall' | 'group';

type TournamentEditFormProps = {
  name: string;
  slug: string;
  cover: string;
  date: string; // ISO
  description: string;
  type: TournamentType;
  id?: number;
};

// cover: '/img/covers/hero.jpg'

export const TournamentEditForm = (props: TournamentEditFormProps) => {
  const [color, setColor] = useState<{ name: Color; hex: `#${string}` }>({
    name: 'green',
    hex: '#4e9110',
  });
  const [name, handleNameChange] = useInputState(props.name);
  const [slug, handleSlugChange] = useInputState(props.slug);
  const [cover, handleCoverChange] = useInputState(props.cover);
  const [date, handleDateChange] = useInputState(props.date);
  const [description, handleDescriptionChange] = useInputState(
    props.description,
  );

  const submitter =
    props.type === 'drunkenfall' ? upsertDrunkenfall : upsertGroupTournament;

  const isUpdate = props.id !== undefined;

  const [handleSubmit, loading, error, success, redirect] =
    useSubmitAndRedirect(
      () =>
        submitter({
          id: props.id,
          color: color.name,
          slug,
          name,
          cover,
          scheduled: iso(date),
          description,
        }),
      response => `/tournaments/${response.id}`,
    );

  const isValid = validate(name, slug, cover, date, props.type);
  const headerStyle = { color: color.hex };
  return (
    <>
      {redirect && <Navigate to={redirect} />}
      <h1 style={headerStyle}>{name}</h1>
      <ColorPicker onChange={setColor} />
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={handleNameChange}
            data-lpignore="true"
          />
        </div>
        <div className="field">
          <label htmlFor="slug">Slug</label>
          <input
            type="text"
            name="slug"
            id="slug"
            value={slug}
            onChange={handleSlugChange}
            data-lpignore="true"
          />
        </div>
        {props.type === 'drunkenfall' && (
          <div className="field">
            <label htmlFor="cover">Cover</label>
            <input
              type="text"
              name="cover"
              id="cover"
              value={cover}
              onChange={handleCoverChange}
              data-lpignore="true"
            />
          </div>
        )}
        <div className="field">
          <label htmlFor="scheduled">Scheduled to start at</label>
          <input
            type="datetime-local"
            name="scheduled"
            id="scheduled"
            value={date}
            onChange={handleDateChange}
          />
        </div>
        <div className="field">
          <label htmlFor="description">Description</label>
          <textarea
            rows={20}
            onChange={handleDescriptionChange}
            name="description"
            id="description"
            value={description}
          />
        </div>
        <Error error={error}>Something went terribly wrong.. :(</Error>
        <SubmitButton
          icon={isUpdate ? faEdit : faPlus}
          disabled={!isValid}
          color="positive"
          {...{ loading, error, success }}
        >
          {isUpdate ? 'Save Changes' : 'Create'}
        </SubmitButton>
      </form>
    </>
  );
};
