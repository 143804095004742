export class ClassNameBuilder {
  private classList: string[];

  constructor(className: string | undefined) {
    this.classList = [];
    this.add(className);
  }

  add(anotherClassName: string | undefined): ClassNameBuilder {
    if (this.isNotEmpty(anotherClassName)) {
      this.classList.push(anotherClassName);
    }
    return this;
  }

  build(): string {
    return this.classList.join(' ');
  }

  private isNotEmpty(what: string | undefined): what is string {
    if (what === undefined) {
      return false;
    }
    return what.trim().length > 0;
  }
}
