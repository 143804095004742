import React from 'react';
import CurrentTournamentContainer from '../containers/CurrentTournamentContainer';
import PeopleContainer from '../containers/PeopleContainer';
import QualificationsStatus from '../components/QualificationsStatus';

const StatusDong = () => (
  <CurrentTournamentContainer>
    {tournament => (
      <PeopleContainer>
        {people => (
          <QualificationsStatus tournament={tournament} people={people} />
        )}
      </PeopleContainer>
    )}
  </CurrentTournamentContainer>
);

export default StatusDong;
