import { isEnded, isStarted } from './DateUtils';
import { Color, Match, Player, PlayerState } from './Types';
import { nullifyGoZeroDate } from './TournamentUtils';

export const canStart = (match: Match) => !isStarted(match);

export const getLevelTitle = (match: Match) => {
  if (match.level === 'twilight') {
    return 'Twilight Spire';
  } else if (match.level === 'kingscourt') {
    return "King's Court";
  } else if (match.level === 'frostfang') {
    return 'Frostfang Keep';
  } else if (match.level === 'sunken') {
    return 'Sunken City';
  } else if (match.level === 'amaranth') {
    return 'The Amaranth';
  }
  return match.level.charAt(0).toUpperCase() + match.level.slice(1);
};

export const isRunning = (match: Match): boolean =>
  isStarted(match) && !isEnded(match);

export const sanitizeMatchObject = (match: any): Match => ({
  ...match,
  started: nullifyGoZeroDate(match.started),
  ended: nullifyGoZeroDate(match.ended),
  scheduled: nullifyGoZeroDate(match.scheduled),
});

export const getMatchLength = (match: Match): number => {
  if (match.length === 0) {
    return match.kind === 'final' ? 20 : 10;
  }
  return match.length;
};

export const createDefaultPlayerState = (playerId: number): PlayerState => ({
  id: 0,
  player_id: playerId,
  round_id: 0,
  index: 0,
  arrows: [],
  shield: false,
  wings: false,
  hat: false,
  invisible: false,
  speed: false,
  alive: true,
  lava: false,
  killer: -2,
  up: 0,
  down: 0,
});

export const getKiller = (
  selfIndex: number,
  killerIndex: number,
  players: Player[],
): { nick: string; color: Color } => {
  if (killerIndex === -1) {
    return {
      nick: 'the level, lol',
      color: 'white',
    };
  }
  if (selfIndex === killerIndex) {
    return {
      nick: "suicide :'(",
      color: 'white',
    };
  }
  return players[killerIndex];
};
