import React from 'react';

import './Qualifications.css';
import QualificationsStream from '../../components/QualificationsStream';
import LiveTournamentContainer from '../../containers/LiveTournamentContainer';

const Qualifications = () => (
  <LiveTournamentContainer>{QualificationsStream}</LiveTournamentContainer>
);

export default Qualifications;
