import React from 'react';
import { useTitle } from '../hooks/useTitle';

import './About.css';

const About = () => {
  useTitle('About DrunkenFall');
  return (
    <div id="about-page">
      <h1>The History of DrunkenFall</h1>
      <div className="texts">
        <p>
          Christmas Day 2013, a group of friends wanted to play some games
          together, to unwind after spending Christmas with their relatives.
          They fell head over heels in love with Towerfall, but because of the
          intensity of the game they forgot to drink!
        </p>
        <p>
          What to do? Drink every time you get killed, of course! They kept
          score with a simple pen and paper, every time someone died they took a
          shot and a point was added to the paper. In the end, the paper was
          unreadable, the friends were shitfaced, and they posted to their
          social media accounts "I think we created a thing - we should put this
          on Youtube" (Surprisingly non-misspelled - thank you autocorrect!)
        </p>
        <p>
          They decided to invite more friends, and friends of friends, and
          friends of friends of friends. In April of 2014 it happened -{' '}
          <i>Drunken Towerfall 2 - The Quickening</i>. About 25 people were
          gathered in the small apartment, and the first official tournament
          began.
        </p>
        <p>
          It was an epic success, all of the guests were thrilled (and drunk),
          and they decided to continue. In 2014, every other month a new
          tournament took place, every time with more players and spectators.
          The record was 50 people in a now bigger apartment.
        </p>
        <p>This was the beginning of something big.</p>
        <p>
          They started modding the game; making it easier to kill each other,
          customizable nicknames, and score tracking. In December 2015 this was
          introduced to the players, and it made everything a lot easier to keep
          track of. No more pen and paper for these guys!
        </p>
        <p>
          The success continued, every other month they threw a new tournament.
          One of the spectators one night had worked with the streaming site
          Twitch, and offered to help them set something up.
        </p>
        <p>
          And in November 2016 they went live for the first time on Twitch, with
          casters/commentators! Much professional, such gaming.
        </p>
        <p>
          In fall of 2018 they decided an apartment is too small for
          DrunkenFall. The members behind the events spend a couple of hours a
          week working on coding, modding, designing, and they realized this
          could be <i>even</i> bigger. So in January 2019, for the first time,
          they’re busting out of the apartment and will take this to a bar
          specialized in e-sport. This is just the beginning of the epic tale of
          DrunkenFall.
        </p>
      </div>
    </div>
  );
};

export default About;
