import React from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import './NavigationBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGamepad,
  faList,
  faQuestion,
  faBeer,
  faCog,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Logo } from '../img/oem.svg';
import Authenticated from './Authenticated';
import { connect } from 'react-redux';
import { logOut as logOutAction } from '../actions';
import { useLocation } from 'react-router-dom';
import { Avatar } from './avatar/Avatar';

const SignInLink = () => (
  <RouterLink to="/login" className="log-in">
    Sign In
  </RouterLink>
);

const mapStateToProps = state => ({
  user: state.session.data.user,
});
const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logOutAction()),
});
const User = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ user, logOut }) => {
  return (
    <>
      <NavLink to="/settings" className="settings desktop-only">
        <FontAwesomeIcon icon={faCog} />
      </NavLink>
      <NavLink to={`/archers/${user.id}`} className="profile">
        <Avatar person={user} />
        <p className={`desktop-only ${user.preferred_color}`}>{user.nick}</p>
      </NavLink>
      <button onClick={logOut} className="log-out desktop-only">
        <FontAwesomeIcon icon={faSignOutAlt} />
      </button>
    </>
  );
});

const NavigationBar = () => {
  const location = useLocation();
  const hide = location.pathname.startsWith('/live/');

  if (hide) return null;

  return (
    <div id="navigation-bar">
      <div className="logo">
        <RouterLink to="/">
          <Logo className="oem" />
        </RouterLink>
      </div>
      <div className="main-routes">
        <NavLink to="/tournaments">
          <FontAwesomeIcon icon={faGamepad} />
          Tournament
        </NavLink>
        <NavLink to="/rules">
          <FontAwesomeIcon icon={faList} />
          Rules
        </NavLink>
        <NavLink to="/about">
          <FontAwesomeIcon icon={faQuestion} />
          About
        </NavLink>
        <Authenticated userLevel="100">
          <NavLink to="/admin">
            <FontAwesomeIcon icon={faBeer} />
            Superpowers
          </NavLink>
        </Authenticated>
      </div>
      <div className="user">
        <Authenticated ifNot={<SignInLink />}>
          <User />
        </Authenticated>
      </div>
    </div>
  );
};

export default NavigationBar;
