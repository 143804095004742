import { useEffect, useState } from 'react';
import './PlayerSelector.css';

type PlayerSelectorProps = {
  title?: string;
  onPlayerChange: (player: number) => void;
};
export function PlayerSelector({ onPlayerChange, title }: PlayerSelectorProps) {
  const [player, setPlayer] = useState<number>(0);
  useEffect(() => {
    onPlayerChange(player);
  }, [onPlayerChange, player]);
  return (
    <div className="player-selector">
      <header>{title ?? 'Player'}</header>
      <section>
        {[0, 1, 2, 3].map(i => (
          <button
            onClick={() => setPlayer(i)}
            className={player === i ? 'active' : ''}
            key={i}
          >
            {i}
          </button>
        ))}
      </section>
    </div>
  );
}
