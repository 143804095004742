import React from 'react';
import LiveTournamentContainer from '../../containers/LiveTournamentContainer';
import { isQualifiers } from '../../lib/TournamentUtils';
import QualificationOverview from '../../components/QualificationOverview';
import PlayoffOverview from '../../components/PlayoffOverview';

const Overview = () => (
  <LiveTournamentContainer>
    {props =>
      isQualifiers(props.tournament) ? (
        <QualificationOverview {...props} />
      ) : (
        <PlayoffOverview {...props} />
      )
    }
  </LiveTournamentContainer>
);

export default Overview;
