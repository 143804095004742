import React from 'react';
import { Match, Person, TournamentResponse } from '../../lib/Types';

import './Intermission.css';
import {
  getNextMatch,
  getNextScheduledMatch,
  getTitleFromFullName,
  isQualifiers,
} from '../../lib/TournamentUtils';
import NextUpList from '../../components/NextUpList';
import { getLevelName } from '../../lib/LevelUtils';
import Countdown from '../../components/Countdown';
import { UpcomingPlayers } from '../../components/UpcomingPlayers';
import { Dict } from '../../reducers';

import logo from '../../img/oem.svg';
import CurrentTournamentContainer from '../../containers/CurrentTournamentContainer';
import PeopleContainer from '../../containers/PeopleContainer';

type NextProps = {
  tournament: TournamentResponse;
  people: Dict<Person>;
  nextMatch: Match;
};

const IntermissionContent = ({ tournament, people, nextMatch }: NextProps) => {
  const tournamentTitle = getTitleFromFullName(tournament.tournament.name);

  const nextScheduledPlayers = getNextScheduledMatch(tournament.matches)
    .map(match => match.players)
    .map(players =>
      players.map(ps => {
        return {
          person: people[ps.person_id],
          color: ps.color,
        };
      }),
    )
    .getOrElse([]);

  const runnerups = isQualifiers(tournament)
    ? tournament.runnerups.map(ps => {
        const person = people[ps.person_id];
        return {
          person,
          color: person.preferred_color,
        };
      })
    : null;

  return (
    <>
      <div id="overview-page">
        <div className="next-up">
          <h1>Next up!</h1>
          <div className="players">
            <NextUpList nextMatch={nextMatch} />
          </div>
          <div className="match-info">
            <div className="name">{nextMatch.name}</div>
            <div>Starts in</div>
            <div className="level">{getLevelName(nextMatch.level)}</div>
            <div className="eta">
              <Countdown to={nextMatch.scheduled} />
            </div>
          </div>
        </div>
        <div className="branding">
          <div className="logo">
            <img src={logo} alt="Drunkenfall Logo" />
          </div>
          <div className="tournament">
            <div className="title">DrunkenFall</div>
            <div className="subtitle">{tournamentTitle}</div>
          </div>
        </div>
        <div className="upcoming">
          {nextScheduledPlayers.length > 0 && (
            <UpcomingPlayers
              title="Next Scheduled"
              players={nextScheduledPlayers}
              showNames={false}
            />
          )}
          {runnerups && (
            <UpcomingPlayers
              title="In queue"
              players={runnerups}
              showNames={false}
            />
          )}
        </div>
      </div>
    </>
  );
};

const Intermission = () => (
  <CurrentTournamentContainer>
    {tournament => {
      const nextMatch = getNextMatch(tournament.matches);
      return (
        <PeopleContainer>
          {people =>
            nextMatch
              .map(match => (
                <IntermissionContent
                  tournament={tournament}
                  people={people}
                  nextMatch={match}
                />
              ))
              .getOrElse(<></>)
          }
        </PeopleContainer>
      );
    }}
  </CurrentTournamentContainer>
);

export default Intermission;
