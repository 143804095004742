import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from '../hooks/useTitle';
import { toMonthDayAndYear } from '../dateFormatter';

import './Home.css';
import logo from '../img/oem.svg';
import { Maybe } from '../lib/Maybe';
import { Tournament } from '../lib/Types';
import { connect } from 'react-redux';
import { AppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { loadLatestLeagueTournament as loadLatestLeagueTournamentAction } from '../actions';
import Video from '../components/Video';
import { isRunning } from '../lib/TournamentUtils';

interface StateProps {
  latestTournament: Maybe<Tournament>;
  loading: boolean;
  error: Error | null;
}

interface DispatchProps {
  loadLatestLeagueTournament: () => void;
}

type LatestTournamentProps = StateProps & DispatchProps;
const LatestTournament = ({
  latestTournament,
  loading,
  error,
  loadLatestLeagueTournament,
}: LatestTournamentProps) => {
  useEffect(() => {
    loadLatestLeagueTournament();
  }, [loadLatestLeagueTournament]);
  if (loading) {
    return <div>Loading..</div>;
  }
  if (error) {
    return null;
  }
  return latestTournament
    .map(tournament => {
      const title = isRunning(tournament)
        ? 'Live Now!'
        : 'Our previous tournaments';
      return (
        <>
          <h2>{title}</h2>
          <Link
            to={`/tournaments/${tournament.id}`}
            className="previous-tournament"
          >
            <img src={tournament.cover} alt={`Poster for ${tournament.name}`} />
            <div className="info">
              <div className="header">{tournament.name}</div>
              <div className="date">
                {toMonthDayAndYear(tournament.scheduled)}
              </div>
            </div>
          </Link>
          <div className="cta">
            <Link to="/tournaments" className="button">
              View all
            </Link>
          </div>
        </>
      );
    })
    .get();
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    latestTournament: state.latestLeagueTournament.data,
    loading: state.latestLeagueTournament.isLoading,
    error: state.latestLeagueTournament.error,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  loadLatestLeagueTournament: () =>
    dispatch(loadLatestLeagueTournamentAction()),
});

const LatestTournamentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LatestTournament);

const Home = () => {
  useTitle('DrunkenFall');
  return (
    <div id="home-page">
      <div className="hero">
        <div className="logo">
          <img src={logo} alt="DrunkenFall" />
        </div>
        <div className="text">
          <p className="company">One-Eye Productions</p>
          <p className="drunkenfall">DrunkenFall</p>
          <p className="tagline">Serious about not being serious</p>
          <p className="description">A Free-For-All TowerFall tournament</p>
        </div>
        <div className="cta">
          <Link to="/register" className="button">
            Join Now
          </Link>
        </div>
      </div>
      <div className="about">
        <Video />
        <div className="previous-tournaments">
          <LatestTournamentContainer />
        </div>
        <div className="rules">
          <h2>SEX!</h2>
          <h3>It's dangerous to go alone.</h3>

          <p>
            <b>Sorry, we needed your attention.</b> Here at DrunkenFall we have
            a special kind of ruleset you might not be familiar with if you’ve
            only played TowerFall vanilla.
          </p>

          <p>
            Please take a moment to familiarize yourself with the different
            modifiers and the implications for fuckups! It might save you during
            the tournament.
          </p>
          <div className="cta">
            <Link to="/rules" className="button">
              View Rules
            </Link>
          </div>
        </div>
        <div className="about-us">
          <h2>About us</h2>
          <p>
            DrunkenFall started as a way to play multiplayer and drink. Just a
            group of friends who want to play games and have a good time. It has
            over the last five years developed to recurring event with weeks of
            preparing, planning, and coding. The baseline is still there though
            - we're just a group of friends who want to play games and have a
            good time, join us!
          </p>

          <p>
            Our events are streamed on{' '}
            <a href="https://twitch.tv/drunkenfallofficial">
              our Twitch channel
            </a>
            , and you are most welcome to come join the fun, even if you are not
            physically present at the tournament!
          </p>

          <div className="cta">
            <Link to="/about" className="button">
              View more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
