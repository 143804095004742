import React, { ReactNode, useEffect } from 'react';
import { PlayerSummary } from '../lib/Types';
import { AppState } from '../store';
import { Maybe } from '../lib/Maybe';
import { getValue } from '../lib/StateUtils';
import { ThunkDispatch } from 'redux-thunk';
import { fetchParticipants } from '../actions';
import { connect } from 'react-redux';

interface StateProps {
  participants: Maybe<PlayerSummary[]>;
}

interface DispatchProps {
  loadParticipants: (tournamentId: number) => void;
}

interface OwnProps {
  tournamentId: number;
  children: (participants: PlayerSummary[]) => ReactNode;
}

type PeopleContainerProps = StateProps & DispatchProps & OwnProps;

const ParticipantsContainer = ({
  participants,
  loadParticipants,
  tournamentId,
  children,
}: PeopleContainerProps) => {
  useEffect(() => {
    loadParticipants(tournamentId);
  }, [loadParticipants, tournamentId]);
  return participants.map(p => <>{children(p)}</>).getOrElse(<></>);
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const tournamentId = ownProps.tournamentId;

  const participants = getValue(state.participants).map(
    participantsList => participantsList[tournamentId],
  );

  return {
    participants,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  loadParticipants: (tournamentId: number) =>
    dispatch(fetchParticipants(tournamentId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ParticipantsContainer);
