import { useEffect } from 'react';

export function useTimer(handler: () => void, timeout?: number) {
  useEffect(() => {
    const id = setInterval(() => {
      handler();
    }, timeout);

    return () => {
      clearInterval(id);
    };
  }, [handler, timeout]);
}
