import { useState } from 'react';

// TODO: this is one of many ways of data loading
function useServerAction(actor: () => Promise<unknown>) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    actor()
      .then(response => {
        setSuccess(true);
        setTimeout(() => setSuccess(false), 3000);
        return response;
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(true);
        setTimeout(() => setError(false), 3000);
      })
      .finally(() => setLoading(false));
  };
  return [handleClick, loading, error, success];
}

export default useServerAction;
