import React from 'react';
import { getStatsOfPlayer } from '../lib/TournamentUtils';
import { getHex } from '../lib/ColorUtils';
import { getArcherImage } from '../lib/ArcherImages';
import {
  ArcherType,
  Match,
  Person,
  Player,
  PlayerSummary,
  TournamentResponse,
} from '../lib/Types';
import { Maybe } from '../lib/Maybe';
import { Dict } from '../reducers';
import ImageWithFallback from './ImageWithFallback';
import placeholder from '../img/greenSlime.png';

interface NextUpPlayerAndPersonProps {
  player: Player;
  person: Person;
  playerSummary: PlayerSummary;
}

const NextUpPlayerAndPerson = ({
  player,
  person,
  playerSummary,
}: NextUpPlayerAndPersonProps) => {
  const hex = getHex(player.color);
  return (
    <div className="player">
      <div className="avatar">
        <ImageWithFallback
          fallback={placeholder}
          src={person.avatar_url}
          alt={`${player.color} archer`}
          style={{ borderColor: hex }}
          width="64"
          height="64"
        />
      </div>
      <div className="id">
        <div className="nick" style={{ color: hex }}>
          {player.nick}
        </div>
        <div className="name">{person.name}</div>
      </div>
      <div className="points">{playerSummary.score} pts</div>
      <div className="matches">{playerSummary.matches} m</div>
      <div className="matches">{playerSummary.shots} s</div>
    </div>
  );
};

interface ControlNextUpListProps {
  tournament: TournamentResponse;
  people: Dict<Person>;
  nextMatch: Match;
}

export const ControlNextUpList = ({
  tournament,
  people,
  nextMatch,
}: ControlNextUpListProps) => {
  return (
    <>
      {nextMatch.players.map(player => {
        const playerSummary = getStatsOfPlayer(
          tournament,
          player.person_id,
        ).getOrThrow(
          () =>
            new Error(
              `Player with the person_id ${player.person_id} does not exist in this tournament`,
            ),
        );
        const person = new Maybe(people[player.person_id]).getOrThrow(
          () =>
            new Error(
              `Person with the person_id ${player.person_id} does not exist;`,
            ),
        );
        return (
          <NextUpPlayerAndPerson
            player={player}
            person={person}
            playerSummary={playerSummary}
            key={player.id}
          />
        );
      })}
    </>
  );
};

interface NextUpPlayerProps {
  player: Player;
}

const NextUpPlayer = ({ player }: NextUpPlayerProps) => {
  const hex = getHex(player.color);
  const archerImage = getArcherImage({
    color: player.color,
    alternate: player.archer_type === ArcherType.Alternate,
  });
  return (
    <div className="player">
      <div className="avatar">
        <img
          src={archerImage}
          alt={`${player.color} archer`}
          style={{ borderColor: hex }}
        />
      </div>
      <div className="nick" style={{ color: hex }}>
        {player.nick}
      </div>
      <div className="points">{player.total_score} pts</div>
    </div>
  );
};

interface NextUpListProps {
  nextMatch: Match;
}

const NextUpList = ({ nextMatch }: NextUpListProps) => (
  <>
    {nextMatch.players.map(player => (
      <NextUpPlayer player={player} key={player.id} />
    ))}
  </>
);

export default NextUpList;
