import { useCallback, useEffect, useState } from 'react';
import { KillType } from '../../lib/Types';
import button from '../Button';

import './KillTypeSelector.css';

type KillTypeSelectorProps = {
  onKillTypeChange: (killType: KillType) => void;
};

// TypeScript enums list their values twice, once with their names and once more with their numerical values
const NUMBER_OF_KILL_TYPES = Object.keys(KillType).length / 2;

export function KillTypeSelector({ onKillTypeChange }: KillTypeSelectorProps) {
  const [killType, setKillType] = useState<KillType>(KillType.Arrow);
  useEffect(() => {
    onKillTypeChange(killType);
  }, [onKillTypeChange, killType]);
  const nextKill = useCallback(() => {
    setKillType(currentType => (currentType + 1) % NUMBER_OF_KILL_TYPES);
  }, [setKillType]);
  return (
    <button className="kill-type-selector" onClick={nextKill}>
      Reason: {KillType[killType]}
    </button>
  );
}
