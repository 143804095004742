import React from 'react';
import './Login.css';
import { Navigate, useLocation } from 'react-router-dom';
import Button from '../components/Button';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

const Login = ({ user }) => {
  const { state } = useLocation();
  const { from } = state || { from: '/' };

  return (
    <div id="login-page">
      {user !== undefined ? (
        <Navigate to={from} />
      ) : (
        <form>
          <Button
            onClick="/api/auth/discord/"
            type="external"
            icon={faArrowRight}
          >
            Login with Discord
          </Button>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.session.data.user,
});
export default connect(mapStateToProps)(Login);
