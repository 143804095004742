const ordinals = ['th', 'st', 'nd', 'rd'];

function getOrdinalNumber(number: number) {
  const ordinal = ordinals[number % 10] || 'th';
  return `${number}${ordinal}`;
}

const MonthAndDay = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
});

const MonthDayAndYear = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

function formatPart(part: Intl.DateTimeFormatPart) {
  if (part.type === 'day') {
    return getOrdinalNumber(parseInt(part.value, 10));
  }
  return part.value;
}

function toMonthAndDay(dateString: string) {
  return MonthAndDay.formatToParts(new Date(dateString))
    .map(formatPart)
    .join('');
}

function toMonthDayAndYear(dateString: string) {
  return MonthDayAndYear.formatToParts(new Date(dateString))
    .map(formatPart)
    .join('');
}

export { toMonthAndDay, toMonthDayAndYear };
