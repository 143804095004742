import React, { ReactNode, useEffect } from 'react';
import { TournamentResponse } from '../lib/Types';
import { AppState } from '../store';
import { Maybe } from '../lib/Maybe';
import { getValue } from '../lib/StateUtils';
import { ThunkDispatch } from 'redux-thunk';
import { fetchTournament } from '../actions';
import { connect } from 'react-redux';

interface StateProps {
  tournament: Maybe<TournamentResponse>;
}

interface DispatchProps {
  loadTournament: (id: number) => void;
}

interface OwnProps {
  id: number;
  children: (tournament: TournamentResponse) => ReactNode;
}

type TournamentByIdContainerProps = StateProps & DispatchProps & OwnProps;

const TournamentByIdContainer = ({
  id,
  tournament,
  loadTournament,
  children,
}: TournamentByIdContainerProps) => {
  useEffect(() => {
    loadTournament(id);
  }, [loadTournament, id]);
  return tournament.map(t => <>{children(t)}</>).getOrElse(<></>);
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const tournament = getValue(state.tournamentDetails[ownProps.id]);
  return {
    tournament,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, any>,
): DispatchProps => ({
  loadTournament: (id: number) => dispatch(fetchTournament(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentByIdContainer);
