import ImageWithFallback from '../ImageWithFallback';
import placeholder from '../../img/greenSlime.png';
import React from 'react';
import { Person } from '../../lib/Types';

type AvatarProps = {
  className?: string;
  person: Pick<Person, 'avatar_url' | 'nick'>;
  size?: number;
};

export function Avatar({ className, person, size = 96 }: AvatarProps) {
  return (
    <ImageWithFallback
      className={`person ${className ?? ''}`}
      src={person.avatar_url}
      fallback={placeholder}
      width={size}
      height={size}
      title={person.nick}
      alt={person.nick}
    />
  );
}
