import useServerAction from '../hooks/useServerAction';
import {
  faCheck,
  faSkullCrossbones,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { IconButton } from './Button';
import React from 'react';

function getIcon(loading, error, success) {
  if (loading) {
    return faSpinner;
  }
  if (error) {
    return faSkullCrossbones;
  }
  if (success) {
    return faCheck;
  }
  return undefined;
}

function getMessage(loading, error, success) {
  if (loading) {
    return 'Loading...';
  }
  if (error) {
    return 'Oh no!';
  }
  if (success) {
    return 'Done!';
  }
  return undefined;
}

function getColor(loading, error) {
  if (error) {
    return 'danger';
  }
  if (loading) {
    return '';
  }
  return undefined;
}

const ActionButton = ({ onClick, timer = 0, ...rest }) => {
  const [clickHandler, loading, error, success] = useServerAction(onClick);

  const icon = getIcon(loading, error, success) ?? rest.icon;
  const children = getMessage(loading, error, success) ?? rest.children;
  const color = getColor(loading, error) ?? rest.color;

  return (
    <IconButton
      {...rest}
      color={color}
      icon={icon}
      onClick={clickHandler}
      loading={loading}
    >
      {children}
    </IconButton>
  );
};

export default ActionButton;
