import { Color, Match, Person, Player, PlayerState } from '../../lib/Types';
import React from 'react';
import { Maybe } from '../../lib/Maybe';
import { getArrowImage } from '../../lib/ArrowImages';
import { Dict } from '../../reducers';
import shieldImage from '../../img/powerups/shield.png';

import './OngoingMatch.css';
import { getHex } from '../../lib/ColorUtils';
import { getKiller, getMatchLength } from '../../lib/MatchUtils';
import { Avatar } from '../avatar/Avatar';

// FIXME: I think I have seen this somewhere else as well
type NickProps = { nick: string; color: Color };

const Nick = ({ nick, color }: NickProps) => (
  <span style={{ color: getHex(color) }}>{nick}</span>
);

interface OngoingMatchProps {
  match: Match;
  playerStates: PlayerState[];
  people: Dict<Person>;
}

interface PointsProps {
  matchLength: number;
  points: number;
  up: number;
  down: number;
}
const Points = ({ matchLength, points, up, down }: PointsProps) => {
  return (
    <>
      {[...Array(matchLength).keys()].map(i => {
        const currentPoint = i + 1;
        let className = 'point';
        if (currentPoint > points) {
          className = '';
        }
        const overkill = Math.max(0, points - matchLength);
        return (
          <div key={i} className={className}>
            {currentPoint === matchLength && overkill > 0 ? (
              <>
                {currentPoint}
                <div className="overkill">+{overkill}</div>
              </>
            ) : (
              currentPoint
            )}
          </div>
        );
      })}
    </>
  );
};

export const OngoingMatch = ({
  match,
  people,
  playerStates,
}: OngoingMatchProps) => {
  return (
    <div id="ongoing-match">
      {match.players.map(player => {
        const maybe = new Maybe(
          playerStates.find(ps => ps.player_id === player.id),
        );
        if (!maybe.hasValue()) {
          return null;
        }
        const playerState = maybe.getOrThrow(
          () =>
            new Error(`Could not get player state for player_id ${player.id}`),
        );
        const matchLength = getMatchLength(match);
        return (
          <div
            className={`player ${playerState.alive ? '' : 'dead'}`}
            key={player.person_id}
          >
            <div className="avatar">
              <Avatar person={people[player.person_id]} />
            </div>
            <div className="status">
              {playerState.alive ? (
                <div className="arrows">
                  {playerState.shield && (
                    <div className="shield">
                      <img src={shieldImage} alt="shield" />
                    </div>
                  )}
                  {playerState.arrows.map((arrow, i) => (
                    <div className="arrow" key={i}>
                      <img src={getArrowImage(arrow)} alt="arrow" />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="reason">
                  Killed by&nbsp;
                  <Nick
                    {...getKiller(
                      playerState.index,
                      playerState.killer,
                      match.players,
                    )}
                  />
                </div>
              )}
              <div className={`points ${matchLength === 20 ? 'final' : ''}`}>
                <Points
                  matchLength={matchLength}
                  points={player.kills}
                  up={playerState.up}
                  down={playerState.down}
                />
              </div>
            </div>
            <div className="summary">
              <div className="nick" style={{ color: getHex(player.color) }}>
                {player.display_names.map(name => (
                  <div key={name}>{name}</div>
                ))}
              </div>
              <div className="scores">
                <div className="kills">
                  <span role="img" aria-label="points">
                    💪
                  </span>{' '}
                  {player.kills}
                </div>
                <div className="shots">
                  <span role="img" aria-label="shots">
                    🥃
                  </span>{' '}
                  {player.shots}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
