import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from '../hooks/useTitle';

import { toMonthAndDay } from '../dateFormatter';
import './Tournaments.css';
import { fetchTournaments } from '../actions';
import { connect } from 'react-redux';
import { onlyLeagueTournaments } from '../lib/TournamentUtils';
import Loading from '../components/Loading';

const Tournament = ({ id, image, title, date }) => (
  <div className="tournament">
    <Link to={`/tournaments/${id}`}>
      <img src={image} alt={`Poster for ${title}`} />
      <div className="info">
        <h2>{title}</h2>
        <h3>{toMonthAndDay(date)}</h3>
      </div>
    </Link>
  </div>
);

const TournamentList = ({ tournaments }) =>
  tournaments.map(t => (
    <Tournament
      key={t.id}
      id={t.id}
      title={t.title}
      date={t.scheduled}
      image={t.cover}
    />
  ));

const Tournaments = ({ tournaments, isLoading, error, loadTournaments }) => {
  useTitle('Tournaments - DrunkenFall');

  useEffect(() => loadTournaments(), [loadTournaments]);

  return (
    <div id="tournaments-page">
      <h1>DrunkenFall 2018</h1>
      <Loading isLoading={isLoading} error={error}>
        <TournamentList tournaments={onlyLeagueTournaments(tournaments)} />
      </Loading>
    </div>
  );
};

const mapStateToProps = state => ({
  tournaments: state.tournaments.data,
  isLoading: state.tournaments.isLoading,
  error: state.tournaments.error,
});
const mapDispatchToProps = dispatch => ({
  loadTournaments: () => dispatch(fetchTournaments()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tournaments);
