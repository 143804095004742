import React from 'react';
import { useCountdown } from '../hooks/useCountdown';
import { toTimeString } from '../lib/DateUtils';

interface CountdownProps {
  to: string | null;
}

const Countdown = ({ to }: CountdownProps) => {
  const timeLeft = useCountdown(to);
  const eta = timeLeft === 0 ? 'Soon™' : toTimeString(timeLeft);
  return <span>{eta}</span>;
};

export default Countdown;
