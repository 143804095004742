import React from 'react';

type ErrorBoundaryState = {
  error: Error | null;
};

type Props = { children: React.ReactNode };

class ErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error('Caught error:', error, errorInfo);
  }

  render() {
    if (this.state.error === null) {
      return this.props.children;
    }
    return (
      <>
        <h1>Åh nej!</h1>
        <p>Our software pooped itself because we're bad at computers.</p>
        <p>Here's some more info in case it's helpful to someone:</p>
        <pre>{this.state.error.toString()}</pre>
      </>
    );
  }
}

export default ErrorBoundary;
