import {
  getAllTournaments,
  deleteTestTournaments,
  getTournament,
  toggleUser as toggleUserApi,
  getParticipants,
  toggleParticipant as toggleParticipantApi,
  getLatestTournament,
  getLoggedInUser,
  updateSettings as updateSettingsApi,
  logOut as logOutApi,
  getLatestLeagueTournament,
  getAllPeopleAndLeaderboard,
} from './api/api';

import {
  Color,
  LeaderboardEntry,
  Match,
  Person,
  PlayerState,
  PlayerSummary,
  Tournament,
  TournamentResponse,
} from './lib/Types';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from './store';
import { Maybe } from './lib/Maybe';

export const LOAD_TOURNAMENTS_REQUEST = 'LOAD_TOURNAMENTS_REQUEST';
export const LOAD_TOURNAMENTS_SUCCESS = 'LOAD_TOURNAMENTS_SUCCESS';
export const LOAD_TOURNAMENTS_ERROR = 'LOAD_TOURNAMENTS_ERROR';

interface LoadTournamentsRequestAction {
  type: typeof LOAD_TOURNAMENTS_REQUEST;
}
interface LoadTournamentsSuccessAction {
  type: typeof LOAD_TOURNAMENTS_SUCCESS;
  tournaments: Tournament[];
}
interface LoadTournamentsErrorAction {
  type: typeof LOAD_TOURNAMENTS_ERROR;
  error: Error;
}

export function loadTournamentsRequest(): LoadTournamentsRequestAction {
  return { type: LOAD_TOURNAMENTS_REQUEST };
}

export function loadTournamentsSuccess(
  tournaments: Tournament[],
): LoadTournamentsSuccessAction {
  return { type: LOAD_TOURNAMENTS_SUCCESS, tournaments };
}

export function loadTournamentsError(error: Error): LoadTournamentsErrorAction {
  return { type: LOAD_TOURNAMENTS_ERROR, error };
}

export const LOAD_TOURNAMENT_REQUEST = 'LOAD_TOURNAMENT_REQUEST';
export const LOAD_TOURNAMENT_SUCCESS = 'LOAD_TOURNAMENT_SUCCESS';
export const LOAD_TOURNAMENT_ERROR = 'LOAD_TOURNAMENT_ERROR';

interface LoadTournamentRequestAction {
  type: typeof LOAD_TOURNAMENT_REQUEST;
  id: number;
}

interface LoadTournamentSuccessAction {
  type: typeof LOAD_TOURNAMENT_SUCCESS;
  tournament: TournamentResponse;
}

interface LoadTournamentErrorAction {
  type: typeof LOAD_TOURNAMENT_ERROR;
  id: number;
  error: Error;
}

export function loadTournamentRequest(id: number): LoadTournamentRequestAction {
  return { type: LOAD_TOURNAMENT_REQUEST, id };
}

export function loadTournamentSuccess(
  tournament: TournamentResponse,
): LoadTournamentSuccessAction {
  return { type: LOAD_TOURNAMENT_SUCCESS, tournament };
}

export function loadTournamentError(
  id: number,
  error: Error,
): LoadTournamentErrorAction {
  return { type: LOAD_TOURNAMENT_ERROR, id, error };
}

export const LOAD_CURRENT_TOURNAMENT_REQUEST =
  'LOAD_CURRENT_TOURNAMENT_REQUEST';
export const LOAD_CURRENT_TOURNAMENT_SUCCESS =
  'LOAD_CURRENT_TOURNAMENT_SUCCESS';
export const LOAD_CURRENT_TOURNAMENT_ERROR = 'LOAD_CURRENT_TOURNAMENT_ERROR';

interface LoadCurrentTournamentRequestAction {
  type: typeof LOAD_CURRENT_TOURNAMENT_REQUEST;
}

interface LoadCurrentTournamentSuccessAction {
  type: typeof LOAD_CURRENT_TOURNAMENT_SUCCESS;
  tournament: TournamentResponse;
}
interface LoadCurrentTournamentErrorAction {
  type: typeof LOAD_CURRENT_TOURNAMENT_ERROR;
  error: Error;
}

export function loadCurrentTournamentRequest(): LoadCurrentTournamentRequestAction {
  return { type: LOAD_CURRENT_TOURNAMENT_REQUEST };
}

export function loadCurrentTournamentSuccess(
  tournament: TournamentResponse,
): LoadCurrentTournamentSuccessAction {
  return { type: LOAD_CURRENT_TOURNAMENT_SUCCESS, tournament };
}

export function loadCurrentTournamentError(
  error: Error,
): LoadCurrentTournamentErrorAction {
  return { type: LOAD_CURRENT_TOURNAMENT_ERROR, error };
}

export const LOAD_LATEST_LEAGUE_TOURNAMENT_REQUEST =
  'LOAD_LATEST_LEAGUE_TOURNAMENT_REQUEST';
export const LOAD_LATEST_LEAGUE_TOURNAMENT_SUCCESS =
  'LOAD_LATEST_LEAGUE_TOURNAMENT_SUCCESS';
export const LOAD_LATEST_LEAGUE_TOURNAMENT_ERROR =
  'LOAD_LATEST_LEAGUE_TOURNAMENT_ERROR';

interface LoadLatestLeagueTournamentRequestAction {
  type: typeof LOAD_LATEST_LEAGUE_TOURNAMENT_REQUEST;
}

interface LoadLatestLeagueTournamentSuccessAction {
  type: typeof LOAD_LATEST_LEAGUE_TOURNAMENT_SUCCESS;
  tournament: Maybe<Tournament>;
}
interface LoadLatestLeagueTournamentErrorAction {
  type: typeof LOAD_LATEST_LEAGUE_TOURNAMENT_ERROR;
  error: Error;
}

export function loadLatestLeagueTournamentRequest(): LoadLatestLeagueTournamentRequestAction {
  return { type: LOAD_LATEST_LEAGUE_TOURNAMENT_REQUEST };
}

export function loadLatestLeagueTournamentSuccess(
  tournament: Maybe<Tournament>,
): LoadLatestLeagueTournamentSuccessAction {
  return { type: LOAD_LATEST_LEAGUE_TOURNAMENT_SUCCESS, tournament };
}

export function loadLatestLeagueTournamentError(
  error: Error,
): LoadLatestLeagueTournamentErrorAction {
  return { type: LOAD_LATEST_LEAGUE_TOURNAMENT_ERROR, error };
}

export const CLEAR_TEST_TOURNAMENTS_REQUEST = 'CLEAR_TEST_TOURNAMENTS_REQUEST';
export const CLEAR_TEST_TOURNAMENTS_SUCCESS = 'CLEAR_TEST_TOURNAMENTS_SUCCESS';
export const CLEAR_TEST_TOURNAMENTS_ERROR = 'CLEAR_TEST_TOURNAMENTS_ERROR';

interface ClearTestTournamentsRequestAction {
  type: typeof CLEAR_TEST_TOURNAMENTS_REQUEST;
}

interface ClearTestTournamentsSuccessAction {
  type: typeof CLEAR_TEST_TOURNAMENTS_SUCCESS;
}

interface ClearTestTournamentsErrorAction {
  type: typeof CLEAR_TEST_TOURNAMENTS_ERROR;
  error: Error;
}

export function clearTestTournamentsRequest(): ClearTestTournamentsRequestAction {
  return { type: CLEAR_TEST_TOURNAMENTS_REQUEST };
}

export function clearTestTournamentsSuccess(): ClearTestTournamentsSuccessAction {
  return { type: CLEAR_TEST_TOURNAMENTS_SUCCESS };
}

export function clearTestTournamentsError(
  error: Error,
): ClearTestTournamentsErrorAction {
  return { type: CLEAR_TEST_TOURNAMENTS_ERROR, error };
}

export const LOAD_PEOPLE_REQUEST = 'LOAD_PEOPLE_REQUEST';
export const LOAD_PEOPLE_SUCCESS = 'LOAD_PEOPLE_SUCCESS';
export const LOAD_PEOPLE_ERROR = 'LOAD_PEOPLE_ERROR';

interface LoadPeopleRequestAction {
  type: typeof LOAD_PEOPLE_REQUEST;
}

interface LoadPeopleSuccessAction {
  type: typeof LOAD_PEOPLE_SUCCESS;
  people: Person[];
}

interface LoadPeopleErrorAction {
  type: typeof LOAD_PEOPLE_ERROR;
  error: Error;
}

export function loadPeopleRequest(): LoadPeopleRequestAction {
  return { type: LOAD_PEOPLE_REQUEST };
}

export function loadPeopleSuccess(people: Person[]): LoadPeopleSuccessAction {
  return { type: LOAD_PEOPLE_SUCCESS, people };
}

export function loadPeopleError(error: Error): LoadPeopleErrorAction {
  return { type: LOAD_PEOPLE_ERROR, error };
}

export const LOAD_LEADERBOARD_REQUEST = 'LOAD_LEADERBOARD_REQUEST';
export const LOAD_LEADERBOARD_SUCCESS = 'LOAD_LEADERBOARD_SUCCESS';
export const LOAD_LEADERBOARD_ERROR = 'LOAD_LEADERBOARD_ERROR';

interface LoadLeaderboardRequestAction {
  type: typeof LOAD_LEADERBOARD_REQUEST;
}

interface LoadLeaderboardSuccessAction {
  type: typeof LOAD_LEADERBOARD_SUCCESS;
  leaderboard: LeaderboardEntry[];
}

interface LoadLeaderboardErrorAction {
  type: typeof LOAD_LEADERBOARD_ERROR;
  error: Error;
}

export function loadLeaderboardRequest(): LoadLeaderboardRequestAction {
  return { type: LOAD_LEADERBOARD_REQUEST };
}

export function loadLeaderboardSuccess(
  leaderboard: LeaderboardEntry[],
): LoadLeaderboardSuccessAction {
  return { type: LOAD_LEADERBOARD_SUCCESS, leaderboard };
}

export function loadLeaderboardError(error: Error): LoadLeaderboardErrorAction {
  return { type: LOAD_LEADERBOARD_ERROR, error };
}

export const TOGGLE_USER_REQUEST = 'TOGGLE_USER_REQUEST';
export const TOGGLE_USER_SUCCESS = 'TOGGLE_USER_SUCCESS';
export const TOGGLE_USER_ERROR = 'TOGGLE_USER_ERROR';

interface ToggleUserRequestAction {
  type: typeof TOGGLE_USER_REQUEST;
  id: number;
}
interface ToggleUserSuccessAction {
  type: typeof TOGGLE_USER_SUCCESS;
  id: number;
}
interface ToggleUserErrorAction {
  type: typeof TOGGLE_USER_ERROR;
  error: Error;
}

export function toggleUserRequest(id: number): ToggleUserRequestAction {
  return { type: TOGGLE_USER_REQUEST, id };
}

export function toggleUserSuccess(id: number): ToggleUserSuccessAction {
  return { type: TOGGLE_USER_SUCCESS, id };
}

export function toggleUserError(error: Error): ToggleUserErrorAction {
  return { type: TOGGLE_USER_ERROR, error };
}

export const LOAD_PARTICIPANTS_REQUEST = 'LOAD_PARTICIPANTS_REQUEST';
export const LOAD_PARTICIPANTS_SUCCESS = 'LOAD_PARTICIPANTS_SUCCESS';
export const LOAD_PARTICIPANTS_ERROR = 'LOAD_PARTICIPANTS_ERROR';

interface LoadParticipantsRequestAction {
  type: typeof LOAD_PARTICIPANTS_REQUEST;
  tournamentId: number;
}
interface LoadParticipantsSuccessAction {
  type: typeof LOAD_PARTICIPANTS_SUCCESS;
  tournamentId: number;
  participants: PlayerSummary[];
}
interface LoadParticipantsErrorAction {
  type: typeof LOAD_PARTICIPANTS_ERROR;
  tournamentId: number;
  error: Error;
}

export function loadParticipantsRequest(
  tournamentId: number,
): LoadParticipantsRequestAction {
  return { type: LOAD_PARTICIPANTS_REQUEST, tournamentId };
}

export function loadParticipantsSuccess(
  tournamentId: number,
  participants: PlayerSummary[],
): LoadParticipantsSuccessAction {
  return { type: LOAD_PARTICIPANTS_SUCCESS, tournamentId, participants };
}

export function loadParticipantsError(
  tournamentId: number,
  error: Error,
): LoadParticipantsErrorAction {
  return { type: LOAD_PARTICIPANTS_ERROR, tournamentId, error };
}

export const TOGGLE_PARTICIPANT_REQUEST = 'TOGGLE_PARTICIPANT_REQUEST';
export const TOGGLE_PARTICIPANT_SUCCESS = 'TOGGLE_PARTICIPANT_SUCCESS';
export const TOGGLE_PARTICIPANT_ERROR = 'TOGGLE_PARTICIPANT_ERROR';

interface ToggleParticipantRequestAction {
  type: typeof TOGGLE_PARTICIPANT_REQUEST;
  tournamentId: number;
  playerId: number;
}
interface ToggleParticipantSuccessAction {
  type: typeof TOGGLE_PARTICIPANT_SUCCESS;
  tournamentId: number;
  playerId: number;
}
interface ToggleParticipantErrorAction {
  type: typeof TOGGLE_PARTICIPANT_ERROR;
  error: Error;
}

export function toggleParticipantRequest(
  tournamentId: number,
  playerId: number,
): ToggleParticipantRequestAction {
  return { type: TOGGLE_PARTICIPANT_REQUEST, tournamentId, playerId };
}

export function toggleParticipantSuccess(
  tournamentId: number,
  playerId: number,
): ToggleParticipantSuccessAction {
  return { type: TOGGLE_PARTICIPANT_SUCCESS, tournamentId, playerId };
}

export function toggleParticipantError(
  error: Error,
): ToggleParticipantErrorAction {
  return { type: TOGGLE_PARTICIPANT_ERROR, error };
}

export const WS_CONNECT = 'WS_CONNECT';
export const WS_CONNECTING = 'WS_CONNECTING';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECT = 'WS_DISCONNECT';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';

interface WsConnectAction {
  type: typeof WS_CONNECT;
  host: string;
}
interface WsConnectingAction {
  type: typeof WS_CONNECTING;
  host: string;
}
interface WsConnectedAction {
  type: typeof WS_CONNECTED;
  host: string;
}
interface WsDisconnectAction {
  type: typeof WS_DISCONNECT;
}
interface WsDisconnectedAction {
  type: typeof WS_DISCONNECTED;
}

export function wsConnect(host: string): WsConnectAction {
  return { type: WS_CONNECT, host };
}

export function wsConnecting(host: string): WsConnectingAction {
  return { type: WS_CONNECTING, host };
}

export function wsConnected(host: string): WsConnectedAction {
  return { type: WS_CONNECTED, host };
}

export function wsDisconnect(): WsDisconnectAction {
  return { type: WS_DISCONNECT };
}

export function wsDisconnected(): WsDisconnectedAction {
  return { type: WS_DISCONNECTED };
}

export const PLAYER_SUMMARIES_UPDATED = 'PLAYER_SUMMARIES_UPDATED';

interface PlayerSummariesUpdatedAction {
  type: typeof PLAYER_SUMMARIES_UPDATED;
  tournamentId: number;
  playerSummaries: PlayerSummary[];
}

export function playerSummariesUpdated(
  tournamentId: number,
  playerSummaries: PlayerSummary[],
): PlayerSummariesUpdatedAction {
  return { type: PLAYER_SUMMARIES_UPDATED, tournamentId, playerSummaries };
}

export const TOURNAMENT_UPDATED = 'TOURNAMENT_UPDATED';

interface TournamentUpdatedAction {
  type: typeof TOURNAMENT_UPDATED;
  tournament: Tournament;
}

export function tournamentUpdated(
  tournament: Tournament,
): TournamentUpdatedAction {
  return { type: TOURNAMENT_UPDATED, tournament };
}

export const MATCH_UPDATED = 'MATCH_UPDATED';

interface MatchUpdatedAction {
  type: typeof MATCH_UPDATED;
  tournamentId: number;
  match: Match;
  playerStates: PlayerState[];
}

export function matchUpdated(
  tournamentId: number,
  match: Match,
  playerStates: PlayerState[],
): MatchUpdatedAction {
  return {
    type: MATCH_UPDATED,
    tournamentId,
    match,
    playerStates,
  };
}

export const MATCHES_UPDATED = 'MATCHES_UPDATED';

interface MatchesUpdatedAction {
  type: typeof MATCHES_UPDATED;
  tournamentId: number;
  matches: Match[];
}

export function matchesUpdated(
  tournamentId: number,
  matches: Match[],
): MatchesUpdatedAction {
  return {
    type: MATCHES_UPDATED,
    tournamentId,
    matches,
  };
}

export const MATCH_ENDED = 'MATCH_ENDED';

interface MatchEndedAction {
  type: typeof MATCH_ENDED;
  tournamentId: number;
  tournamentResponse: TournamentResponse;
}

export function matchEnded(
  tournamentId: number,
  tournamentResponse: TournamentResponse,
): MatchEndedAction {
  return {
    type: MATCH_ENDED,
    tournamentId,
    tournamentResponse,
  };
}

export const GET_LOGGED_IN_USER_REQUEST = 'GET_LOGGED_IN_USER_REQUEST';
export const GET_LOGGED_IN_USER_SUCCESS = 'GET_LOGGED_IN_USER_SUCCESS';
export const GET_LOGGED_IN_USER_ERROR = 'GET_LOGGED_IN_USER_ERROR';

interface GetLoggedInUserRequestAction {
  type: typeof GET_LOGGED_IN_USER_REQUEST;
}
interface GetLoggedInUserSuccessAction {
  type: typeof GET_LOGGED_IN_USER_SUCCESS;
  user: Person | undefined;
}
interface GetLoggedInUserErrorAction {
  type: typeof GET_LOGGED_IN_USER_ERROR;
  error: Error;
}

export function getLoggedInUserRequest(): GetLoggedInUserRequestAction {
  return { type: GET_LOGGED_IN_USER_REQUEST };
}

export function getLoggedInUserSuccess(
  user: Person,
): GetLoggedInUserSuccessAction {
  return { type: GET_LOGGED_IN_USER_SUCCESS, user };
}

export function getLoggedInUserError(error: Error): GetLoggedInUserErrorAction {
  return { type: GET_LOGGED_IN_USER_ERROR, error };
}

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_ERROR = 'LOG_OUT_ERROR';

interface LogOutRequestAction {
  type: typeof LOG_OUT_REQUEST;
}
interface LogOutSuccessAction {
  type: typeof LOG_OUT_SUCCESS;
}
interface LogOutErrorAction {
  type: typeof LOG_OUT_ERROR;
  error: Error;
}

export function logOutRequest(): LogOutRequestAction {
  return { type: LOG_OUT_REQUEST };
}

export function logOutSuccess(): LogOutSuccessAction {
  return { type: LOG_OUT_SUCCESS };
}

export function logOutError(error: Error): LogOutErrorAction {
  return { type: LOG_OUT_ERROR, error };
}

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_ERROR = 'UPDATE_SETTINGS_ERROR';

interface UpdateSettingsRequestAction {
  type: typeof UPDATE_SETTINGS_REQUEST;
  nick: string;
  name: string;
  color: Color;
  alternate: false;
}

interface UpdateSettingsSuccessAction {
  type: typeof UPDATE_SETTINGS_SUCCESS;
  person: Person;
}
interface UpdateSettingsErrorAction {
  type: typeof UPDATE_SETTINGS_ERROR;
  error: Error;
}

export function updateSettingsRequest(
  nick: string,
  name: string,
  color: Color,
  alternate: false,
): UpdateSettingsRequestAction {
  return { type: UPDATE_SETTINGS_REQUEST, nick, name, color, alternate };
}

export function updateSettingsSuccess(
  person: Person,
): UpdateSettingsSuccessAction {
  return { type: UPDATE_SETTINGS_SUCCESS, person };
}

export function updateSettingsError(error: Error): UpdateSettingsErrorAction {
  return { type: UPDATE_SETTINGS_ERROR, error };
}

type HyperThonk = ThunkAction<void, AppState, null, Action<string>>;

export const fetchTournaments = (): HyperThonk => dispatch => {
  dispatch(loadTournamentsRequest());
  getAllTournaments().then(
    tournaments => dispatch(loadTournamentsSuccess(tournaments)),
    error => dispatch(loadTournamentsError(error)),
  );
};

export const fetchTournament =
  (id: number): HyperThonk =>
  dispatch => {
    dispatch(loadTournamentRequest(id));
    getTournament(id).then(
      tournament => dispatch(loadTournamentSuccess(tournament)),
      error => dispatch(loadTournamentError(id, error)),
    );
  };

export const fetchCurrentTournament = (): HyperThonk => dispatch => {
  dispatch(loadCurrentTournamentRequest());
  getLatestTournament().then(
    tournament => dispatch(loadCurrentTournamentSuccess(tournament)),
    error => dispatch(loadCurrentTournamentError(error)),
  );
};

export const fetchPeople = (): HyperThonk => dispatch => {
  dispatch(loadPeopleRequest());
  dispatch(loadLeaderboardRequest());
  getAllPeopleAndLeaderboard().then(
    ({ people, leaderboard }) => {
      dispatch(loadPeopleSuccess(people));
      dispatch(loadLeaderboardSuccess(leaderboard));
    },
    error => {
      dispatch(loadPeopleError(error));
      dispatch(loadLeaderboardError(error));
    },
  );
};

export const toggleUser =
  (id: number): HyperThonk =>
  dispatch => {
    dispatch(toggleUserRequest(id));
    toggleUserApi(id).then(
      () => {
        dispatch(fetchPeople());
        dispatch(toggleUserSuccess(id));
      },
      error => dispatch(toggleUserError(error)),
    );
  };

export const clearTestTournaments = (): HyperThonk => dispatch => {
  dispatch(clearTestTournamentsRequest());
  deleteTestTournaments().then(
    r => {
      dispatch(clearTestTournamentsSuccess());
      dispatch(loadTournamentsSuccess(r));
    },
    error => dispatch(clearTestTournamentsError(error)),
  );
};

export const fetchParticipants =
  (tournamentId: number): HyperThonk =>
  dispatch => {
    dispatch(loadParticipantsRequest(tournamentId));
    getParticipants(tournamentId).then(
      participants =>
        dispatch(loadParticipantsSuccess(tournamentId, participants)),
      error => dispatch(loadParticipantsError(tournamentId, error)),
    );
  };

export const toggleParticipant =
  (tournamentId: number, playerId: number): HyperThonk =>
  dispatch => {
    dispatch(toggleParticipantRequest(tournamentId, playerId));
    toggleParticipantApi(tournamentId, playerId).then(
      () => {
        dispatch(fetchParticipants(tournamentId));
        dispatch(toggleParticipantSuccess(tournamentId, playerId));
      },
      error => dispatch(toggleParticipantError(error)),
    );
  };

export const updateSettings =
  (nick: string, name: string, color: Color, alternate: false): HyperThonk =>
  dispatch => {
    dispatch(updateSettingsRequest(nick, name, color, alternate));
    return updateSettingsApi(nick, name, color, alternate).then(
      person => {
        dispatch(updateSettingsSuccess(person));
        return person;
      },
      error => dispatch(updateSettingsError(error)),
    );
  };

export const logIn = (): HyperThonk => dispatch => {
  dispatch(getLoggedInUserRequest());
  getLoggedInUser().then(
    p => {
      if (p !== undefined) {
        dispatch(getLoggedInUserSuccess(p));
      }
    },
    error => dispatch(getLoggedInUserError(error)),
  );
};

export const logOut = (): HyperThonk => dispatch => {
  dispatch(logOutRequest());
  logOutApi().then(
    () => {
      return dispatch(logOutSuccess());
    },
    error => dispatch(logOutError(error)),
  );
};

export const loadLatestLeagueTournament = (): HyperThonk => dispatch => {
  dispatch(loadLatestLeagueTournamentRequest());
  getLatestLeagueTournament().then(
    result => dispatch(loadLatestLeagueTournamentSuccess(result)),
    error => dispatch(loadLatestLeagueTournamentError(error)),
  );
};

export type ActionTypes =
  | LoadTournamentsRequestAction
  | LoadTournamentsSuccessAction
  | LoadTournamentsErrorAction
  | LoadTournamentRequestAction
  | LoadTournamentSuccessAction
  | LoadTournamentErrorAction
  | LoadCurrentTournamentRequestAction
  | LoadCurrentTournamentSuccessAction
  | LoadCurrentTournamentErrorAction
  | LoadLatestLeagueTournamentRequestAction
  | LoadLatestLeagueTournamentSuccessAction
  | LoadLatestLeagueTournamentErrorAction
  | ClearTestTournamentsRequestAction
  | ClearTestTournamentsSuccessAction
  | ClearTestTournamentsErrorAction
  | LoadPeopleRequestAction
  | LoadPeopleSuccessAction
  | LoadPeopleErrorAction
  | LoadLeaderboardRequestAction
  | LoadLeaderboardSuccessAction
  | LoadLeaderboardErrorAction
  | ToggleUserRequestAction
  | ToggleUserSuccessAction
  | ToggleUserErrorAction
  | LoadParticipantsRequestAction
  | LoadParticipantsSuccessAction
  | LoadParticipantsErrorAction
  | ToggleParticipantRequestAction
  | ToggleParticipantSuccessAction
  | ToggleParticipantErrorAction
  | WsConnectAction
  | WsConnectingAction
  | WsConnectedAction
  | WsDisconnectAction
  | WsDisconnectedAction
  | PlayerSummariesUpdatedAction
  | TournamentUpdatedAction
  | MatchUpdatedAction
  | MatchesUpdatedAction
  | MatchEndedAction
  | GetLoggedInUserRequestAction
  | GetLoggedInUserSuccessAction
  | GetLoggedInUserErrorAction
  | LogOutRequestAction
  | LogOutSuccessAction
  | LogOutErrorAction
  | UpdateSettingsRequestAction
  | UpdateSettingsSuccessAction
  | UpdateSettingsErrorAction;
