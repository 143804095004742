import { Person } from '../lib/Types';
import React from 'react';
import useInputState from '../hooks/useInputState';

import './FilterableUserList.css';
import { Avatar } from './avatar/Avatar';

interface FilterableUserListProps {
  people: Person[];
  onUserSelect: (p: Person) => void;
}

export const FilterableUserList = ({
  people,
  onUserSelect,
}: FilterableUserListProps) => {
  const [filter, handleFilterChange, setFilter] = useInputState('');
  const trimmed = filter.trim().toLowerCase();
  const filtered =
    trimmed.length > 0
      ? people.filter(
          p =>
            p.nick.toLowerCase().includes(trimmed) ||
            p.name.toLowerCase().includes(trimmed),
        )
      : people;
  return (
    <div className="filterable-user-list">
      <div className="input">
        <input
          type="text"
          value={filter}
          placeholder="Filter.."
          onChange={handleFilterChange}
        />
        <button onClick={() => setFilter('')}>
          <span role="img" aria-label="cross">
            ❌
          </span>
        </button>
      </div>
      {filtered.map(person => (
        <button
          key={person.id}
          className="user"
          onClick={() => onUserSelect(person)}
        >
          <div className="avatar">
            <Avatar person={person} />
          </div>
          <div className="identifier">
            <div className="nick">{person.nick}</div>
            <div className="name">{person.name}</div>
          </div>
        </button>
      ))}
    </div>
  );
};
