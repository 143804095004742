import React, { useState } from 'react';

import './ArcherSelector.css';
import { archers } from '../lib/ArcherImages';
import { ClassNameBuilder } from '../lib/ClassNameUtils';

const Archer = ({ image, name, alternate, selected, onSelect }) => {
  const classNameList = new ClassNameBuilder('archer');
  if (selected) {
    classNameList.add('selected');
  }
  return (
    <button
      className={classNameList.build()}
      onClick={event => {
        event.preventDefault();
        return onSelect(name, alternate);
      }}
    >
      <img
        src={image}
        alt={`${name} archer${alternate ? ' (alternate)' : ''}`}
      />
    </button>
  );
};

const ArcherSelector = ({
  initialArcher = '',
  initialAlternate = false,
  onChange,
}) => {
  const [selected, setSelected] = useState({
    name: initialArcher,
    alternate: initialAlternate,
  });
  const onSelect = (name, alternate) => {
    setSelected({ name, alternate });
    if (onChange) {
      onChange({ name, alternate });
    }
  };

  // TODO: fix the eslint shit down below
  return (
    <div className="archer-selector">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>Preferred archer</label>
      {archers.map(archer => (
        <div key={`${archer.name}`}>
          <Archer
            image={archer.image}
            name={archer.name}
            alternate={false}
            onSelect={onSelect}
            selected={
              selected.name === archer.name && selected.alternate === false
            }
          />
          <Archer
            image={archer.alternate}
            name={archer.name}
            alternate
            onSelect={onSelect}
            selected={
              selected.name === archer.name && selected.alternate === true
            }
          />
        </div>
      ))}
    </div>
  );
};

export default ArcherSelector;
