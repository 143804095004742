import React from 'react';
import { useCurrentTime } from '../../hooks/useCurrentTime';
import { getNextMatch, getTitleFromFullName } from '../../lib/TournamentUtils';
import { getLevelName } from '../../lib/LevelUtils';
import Countdown from '../Countdown';

import './BetweenMatches.css';
import logo from '../../img/oem.svg';
import { dateToTimeStringWithTimezone } from '../../lib/DateUtils';
import { LiveTournamentProps } from '../../containers/LiveTournamentContainer';
import { AvatarWithRibbon } from '../avatar/AvatarWithRibbon';

export const BetweenMatches = ({ tournament, people }: LiveTournamentProps) => {
  const now = useCurrentTime();
  const nextMatch = getNextMatch(tournament.matches).getOrThrow(
    () => new Error('Could not get the next match.'),
  );
  return (
    <div id="between-matches">
      <div className="header">
        <div className="branding">
          <div className="logo">
            <img src={logo} alt="DrunkenFall" />
          </div>
          <div className="tournament">
            <h1>DrunkenFall</h1>
            <h2>{getTitleFromFullName(tournament.tournament.name)}</h2>
          </div>
        </div>
        <div className="current-time">{dateToTimeStringWithTimezone(now)}</div>
      </div>
      <div className="match-info">
        <h1>{nextMatch.name}</h1>
        <h2>{getLevelName(nextMatch.level)}</h2>
        <h3>
          <Countdown to={nextMatch.scheduled} />
        </h3>
      </div>
      <div className="players">
        {nextMatch.players.map(player => (
          <AvatarWithRibbon
            key={player.person_id}
            person={people[player.person_id]}
            ribbonText={people[player.person_id].nick}
          />
        ))}
      </div>
    </div>
  );
};
