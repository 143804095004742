import React from 'react';
import CurrentTournamentContainer from '../containers/CurrentTournamentContainer';
import PeopleContainer from '../containers/PeopleContainer';
import PlayoffsStatus from '../components/PlayoffsStatus';

const TouchpadCat = () => (
  <CurrentTournamentContainer>
    {tournament => (
      <PeopleContainer>
        {people => <PlayoffsStatus tournament={tournament} people={people} />}
      </PeopleContainer>
    )}
  </CurrentTournamentContainer>
);

export default TouchpadCat;
