import React from 'react';
import { ClassNameBuilder } from '../lib/ClassNameUtils';
import styles from './StatsCards.module.css';
import colors from './BorderColors.module.css';
import { Color } from '../lib/Types';

type StatProps = {
  name: string;
  className?: string;
  children: React.ReactNode;
};

export const Stat = ({ name, className, children }: StatProps) => {
  const classes = new ClassNameBuilder(styles.stat).add(className).build();
  return (
    <div className={classes}>
      <div className={styles.name}>{name}</div>
      {children}
    </div>
  );
};

interface StatsCardProps {
  title: string;
  className: string;
  color?: Color;
  children: React.ReactNode;
}

export const StatsCard = ({
  title,
  color,
  className,
  children,
}: StatsCardProps) => {
  const classes = new ClassNameBuilder(styles.statsCard).add(className);
  if (color) {
    classes.add(colors[color]);
  }
  return (
    <div className={classes.build()}>
      <div className={styles.title}>{title}</div>
      {children}
    </div>
  );
};

export const LargeStatsCard = ({
  title,
  color,
  className,
  children,
}: StatsCardProps) => {
  const classes = new ClassNameBuilder(styles.largeStatsCard).add(className);
  if (color) {
    classes.add(colors[color]);
  }
  return (
    <div className={classes.build()}>
      <div className={styles.title}>{title}</div>
      {children}
    </div>
  );
};
