import {
  matchEnded,
  matchesUpdated,
  matchUpdated,
  playerSummariesUpdated,
  tournamentUpdated,
  WS_CONNECT,
  WS_DISCONNECT,
  wsConnected,
  wsDisconnected,
} from './actions';
import { sanitizeTournamentDetailObject } from './lib/TournamentUtils';
import { sanitizeMatchObject } from './lib/MatchUtils';
import ReconnectingWebSocket from 'reconnecting-websocket';

const webSocketMiddleware = () => {
  let socket = null;

  const onOpen = store => event => {
    // eslint-disable-next-line no-console
    console.log('websocket onOpen');
    store.dispatch(wsConnected(event.target.url));
  };

  const onClose = store => event => {
    // eslint-disable-next-line no-console
    console.log('websocket onClose', { event });
    store.dispatch(wsDisconnected());
  };

  const onError = store => event => {
    // eslint-disable-next-line no-console
    console.log('websocket onerror', { event });
  };

  const onMessage = store => event => {
    const payload = JSON.parse(event.data);

    switch (payload.type) {
      case 'player_summaries':
        store.dispatch(
          playerSummariesUpdated(
            payload.data.tournament_id,
            payload.data.player_summaries,
          ),
        );
        break;
      case 'tournament':
        store.dispatch(
          tournamentUpdated(sanitizeTournamentDetailObject(payload.data)),
        );
        break;
      case 'match':
        store.dispatch(
          matchUpdated(
            payload.data.tournament,
            sanitizeMatchObject(payload.data.match),
            payload.data.player_states,
          ),
        );
        break;
      case 'matches':
        store.dispatch(
          matchesUpdated(
            payload.data.tournament_id,
            payload.data.matches.map(match => sanitizeMatchObject(match)),
          ),
        );
        break;
      case 'match_end':
        store.dispatch(
          matchEnded(payload.data.tournament_id, {
            tournament: sanitizeTournamentDetailObject(payload.data.tournament),
            matches: payload.data.matches.map(match =>
              sanitizeMatchObject(match),
            ),
            player_states: payload.data.player_states,
            player_summaries: payload.data.player_summaries,
            runnerups: payload.data.runnerups,
          }),
        );
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('WebSocket message', payload);
    }
  };

  return store => next => action => {
    switch (action.type) {
      case WS_CONNECT:
        if (socket !== null) {
          socket.close();
        }

        socket = new ReconnectingWebSocket(action.host);

        socket.addEventListener('message', onMessage(store));
        socket.addEventListener('close', onClose(store));
        socket.addEventListener('open', onOpen(store));
        socket.addEventListener('error', onError(store));

        return next(action);
      case WS_DISCONNECT:
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        return next(action);
      default:
        return next(action);
    }
  };
};

export default webSocketMiddleware();
