import { useEffect, useState } from 'react';

export const useCountdown = (endTime: string | null) => {
  const now = Date.now();
  const endTimeMs = endTime !== null ? Date.parse(endTime) : now;
  const [ms, setMs] = useState(endTimeMs - now);
  useEffect(() => {
    const id = setInterval(() => {
      setMs(endTimeMs - Date.now());
    }, 100);

    return () => {
      clearInterval(id);
    };
  }, [endTimeMs, setMs]);
  return Math.max(ms, 0);
};
