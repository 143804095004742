import React from 'react';

import './Autocaster.css';
import LiveTournamentContainer, {
  LiveTournamentProps,
} from '../../containers/LiveTournamentContainer';
import StatusBar from '../../components/StatusBar';
import { getCurrentMatch, getNextMatch } from '../../lib/TournamentUtils';
import PersonCard from '../../components/PersonCard';

const AutocasterStream = ({ tournament, people }: LiveTournamentProps) => {
  const currentMatch = getCurrentMatch(tournament).getOrThrow(
    () => new Error(`Could not get the current match`),
  );
  const nextMatch = getNextMatch(tournament.matches);

  return (
    <div id="autocaster">
      <div className="info-area">
        {nextMatch
          .map(match => (
            <div className="next-match">
              <h1>Next up!</h1>
              {match.players.map(player => (
                <PersonCard
                  className="player"
                  nick={player.nick}
                  color={player.color}
                  avatarUrl={people[player.person_id].avatar_url}
                  key={player.person_id}
                />
              ))}
            </div>
          ))
          .getOrElse(<></>)}
        <div className="cta">
          Visit
          <div className="drunkenfall">DRUNKENFALL.COM</div>
          For complete leaderboard
        </div>
      </div>
      <StatusBar
        currentMatch={currentMatch}
        tournament={tournament}
        people={people}
      />
    </div>
  );
};

const Autocaster = () => (
  <LiveTournamentContainer>{AutocasterStream}</LiveTournamentContainer>
);

export default Autocaster;
